import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { Box, Typography } from '@mui/material';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

import { Invoice } from 'openapi';

import { Layout } from 'components/Layout/Layout';
import { PdfViewerWithWatermark } from 'components/PdfViewerWithWatermark/PdfViewerWithWatermark';
import { VerificationInvoiceForm } from 'components/forms/VerificationInvoiceForm';

import { useInvoiceController } from 'api/controllers/InvoiceController';

import { ROUTE_HOME } from 'utils/routes';
import { PDF_JS_WORKER_URL } from 'utils/constants/constants';
import { base64toBlob } from 'utils/helpers/base64toBlob';

import {
  zoomButtonsWrapper,
  mediumVerificationFieldWidth,
  verificationContentWrapper,
  nonMachineReadableLabel,
  processedInvoiceLabel
} from 'styles/pages/InvoiceVerificationStyle';

import { useTranslations } from 'context/TranslationContext';

export const InvoiceVerification = React.memo((): JSX.Element => {
  const { id, companyId, stage } = useParams();
  const location = useLocation();
  const { state } = location;

  const { getInvoiceFile, getAllInvoices, getAllInvoicesByCompanyIdAndStage } =
    useInvoiceController();

  const { translate } = useTranslations();

  const currentState = localStorage.getItem('state');

  const [allInvoicesList, setAllInvoicesList] = useState<Invoice[]>([]);
  const [currentInvoice, setCurrentInvoice] = useState<Invoice>({});

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const rotatePluginInstance = rotatePlugin();

  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;

  const [pdfFileUrl, setPdfFileUrl] = useState<string>('');

  let readableLabel = translate('labels.nonMachineReadable');
  let readableClassName = nonMachineReadableLabel;

  if (currentInvoice.isMachineReadable) {
    readableLabel = translate('labels.processedInvoice');
    readableClassName = processedInvoiceLabel;
  }

  const getInvoiceUrlInfo = useCallback(
    async (data: number) => {
      const invoiceId = data;

      const invoiceFileUrl = await getInvoiceFile(invoiceId);
      const pdfUrl = invoiceFileUrl
        ? URL.createObjectURL(base64toBlob(invoiceFileUrl))
        : '';
      setPdfFileUrl(pdfUrl);
    },
    [getInvoiceFile]
  );

  const getAllInvoicesList = useCallback(async () => {
    let allInvoices;

    if (
      currentState === ROUTE_HOME ||
      (state && state.invoice === ROUTE_HOME)
    ) {
      allInvoices = await getAllInvoices(stage);
    } else {
      allInvoices = await getAllInvoicesByCompanyIdAndStage(
        Number(companyId),
        stage as string
      );
    }

    setAllInvoicesList(allInvoices);
  }, [
    currentState,
    state,
    getAllInvoices,
    stage,
    getAllInvoicesByCompanyIdAndStage,
    companyId
  ]);

  // Effects

  useEffect(() => {
    if (allInvoicesList.length <= 0) {
      getAllInvoicesList();
    } else {
      const invoice = allInvoicesList.find(
        (inv: Invoice) => Number(inv.id) === Number(id)
      );

      if (invoice) {
        setCurrentInvoice(invoice);
        getInvoiceUrlInfo(Number(invoice.id));
      }
    }
  }, [allInvoicesList, getAllInvoicesList, getInvoiceUrlInfo, id]);

  return (
    <Layout styles={{ paddingTop: '16px' }}>
      {currentInvoice && (
        <Box sx={verificationContentWrapper}>
          {pdfFileUrl && (
            <Worker workerUrl={PDF_JS_WORKER_URL || ''}>
              <Box sx={mediumVerificationFieldWidth}>
                <Typography sx={readableClassName}>{readableLabel}</Typography>
                <PdfViewerWithWatermark
                  fileUrl={pdfFileUrl}
                  plugins={[zoomPluginInstance, rotatePluginInstance]}
                  watermarkText={currentInvoice.stage as string}
                />
                <Box sx={zoomButtonsWrapper}>
                  <RotateBackwardButton />
                  <ZoomOutButton />
                  <ZoomPopover />
                  <ZoomInButton />
                  <RotateForwardButton />
                </Box>
              </Box>
            </Worker>
          )}

          <Box sx={mediumVerificationFieldWidth}>
            <VerificationInvoiceForm currentInvoice={currentInvoice} />
          </Box>
        </Box>
      )}
    </Layout>
  );
});
