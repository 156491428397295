import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Paper, Typography } from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import { Prompt } from 'openapi';

import { usePromptController } from 'api/controllers/PromptController';

import { Layout } from 'components/Layout/Layout';

import { useTranslations } from 'context/TranslationContext';

import { ROUTE_ADD_PROMPT, ROUTE_PROMPT_SETTINGS_ID } from 'utils/routes';
import { idStringReplace } from 'utils/helpers/idStringReplace';

import { cardWrapper } from 'styles/components/CompanyWrapperStyle';

export const Prompts: React.FC = (): JSX.Element => {
  const navigate = useNavigate();

  const { translate } = useTranslations();
  const { getPrompts } = usePromptController();

  const [prompts, setPrompts] = useState<any>([]);

  useEffect(() => {
    const fetchPrompt = async () => {
      const promptsValues = await getPrompts();
      setPrompts(promptsValues);
    };

    fetchPrompt();
  }, []);

  return (
    <Layout>
      <Box
        sx={{
          ...cardWrapper,
          flexDirection: 'row'
        }}
      >
        {prompts &&
          prompts.map((prompt: Prompt) => (
            <Paper
              elevation={4}
              className="paper"
              key={prompt.id}
              onClick={() => {
                navigate(
                  idStringReplace(
                    ROUTE_PROMPT_SETTINGS_ID,
                    prompt.id!.toString()
                  )
                );
              }}
              sx={{ width: '260px' }}
            >
              <Box className="textWrapper">
                <Typography variant="body1">
                  {prompt.promptName || `Template ${prompt.id}`}
                </Typography>
              </Box>
              <Button
                variant="text"
                className="button"
                sx={{ color: '#3ad551' }}
              >
                <ModeEditOutlineIcon />
              </Button>
            </Paper>
          ))}
        <Paper
          elevation={4}
          className="createNewCard"
          onClick={() => navigate(ROUTE_ADD_PROMPT)}
        >
          <AddIcon className="icon" />
          <Typography variant="body1">{translate('buttons.add')}</Typography>
        </Paper>
      </Box>
    </Layout>
  );
};
