import { GridColDef } from '@mui/x-data-grid';

import { getColumnValue } from 'hooks/useDataGridHelpers';

import { YesNo } from 'utils/enums/YesNo';

export const TOAST_ERROR_MESSAGE = 'Unexpected error occurred.';

export const ACCESS_TOKEN = 'access_token';
export const BEARER_TOKEN = 'Bearer';

export const EN_LANGUAGE = 'en';

export const SPACE_BETWEEN = 'space-between';
export const END = 'end';

export const ID = 'id';

export const MIN_ALLOWED_CHARACTERS_PASSWORD = '4';
export const MIN_ALLOWED_CHARACTERS_NAME = '1';
export const MIN_ALLOWED_VALUE = '1';
export const MAX_ALLOWED_CHARACTERS = '100';

export const COLUMNS_DEFAULT_OPTIONS: Partial<GridColDef> = {
  flex: 1,
  align: 'left',
  valueGetter: getColumnValue
};
export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [2, 5, 10, 25, 100];
export const YES_NO_OPTIONS = [
  { id: true, label: YesNo.Yes },
  { id: false, label: YesNo.No }
];
export const PERCENTAGE_OPTIONS = [
  {
    id: 0,
    label: '0%'
  },
  {
    id: 0.09,
    label: '9%'
  },
  {
    id: 0.2,
    label: '20%'
  }
];
export const DEFAULT_PAGINATION_MODEL = {
  pageSize: 10,
  page: 0
};

export const ID_PARAMETER = ':id';
export const COMPANY_PARAMETER = ':companyId';
export const STAGE_PARAMETER = ':stage';
export const BANK_STATEMENT_PARAMETER = ':bankStatementId';

export const SHORT_COMPANY_NAME = 'shortNameCompany';
export const EXPORTABLE = 'exportable';
export const COUNTER_PARTY_NAME = 'counterPartyName';
export const INVOICE_NUMBER = 'invoiceNumber';
export const INVOICE_DATE = 'invoiceDate';
export const INVOICE_AMOUNT = 'invoiceAmount';
export const CURRENCY = 'currency';
export const DUE_DATE = 'dueDate';
export const VAT_AMOUNT = 'vatAmount';
export const EXPENSE_TYPE = 'expenseTypeId';
export const STAGE = 'stage';
export const BOOKING_STATUS = 'bookingStatus';
export const IS_BOOKED = 'isBooked';
export const PMT_STATUS = 'pmtStatus';
export const IS_PAYED = 'isPaid';
export const DOCUMENT_TYPE = 'documentType';

export const BANK_STATEMENT_BANK_NAME = 'bankName';
export const BANK_STATEMENT_NUMBER_FIELD = 'bankStatementNumber';
export const BANK_STATEMENT_PERIOD = 'period';
export const BANK_STATEMENT_COMPANY = 'company';

export const TRANSACTION_NUMBER_FIELD = 'bankStatementNumber';
export const TRANSACTION_COMPANY_NAME = 'companyName';
export const TRANSACTION_VALUE_DATE = 'valueDate';
export const TRANSACTION_REASON = 'reason';
export const TRANSACTION_REASON_TYPE = 'reasonType';
export const TRANSACTION_COUNTER_PARTY_NAME = 'counterPartyName';
export const TRANSACTION_CREDIT = 'credit';
export const TRANSACTION_DEBIT = 'debit';
export const TRANSACTION_IBAN = 'iban';
export const TRANSACTION_INVOICE_NUMBER = 'invoiceNumber';
export const TRANSACTION_VREDIT_NOTICE = 'creditNotice';
export const TRANSACTION_COMPANY_REGISTRATION_NUMBER =
  'companyRegistrationNumber';
export const TRANSACTION_ACCOUNT_NUMBER = 'accountNumber';

export const MONTH_YEAR = 'Month/Year';
export const ORIGIN_BANK = 'Origin bank';
export const BANK_STATEMENT_NUMBER = 'Bank statement number';
export const ACTIONS = 'Actions';

export const DRAFT_INVOICE_STYLE = 'draftInvoiceStyle';
export const CHECK = '__check__';

export const NOT_SET = 'NOT_SET';

export const NULL = null;
export const ZERO = 0;
export const ONE = 1;

export const BUTTON = 'button';

export const INVOICES = 'invoices';
export const INVOICE = 'invoice';
export const BANK_STATEMENTS = 'bank-statements';
export const SETTINGS = 'settings';
export const PROMPT_SETTINGS = 'prompt-settings';
export const COMPANIES_SETTINGS = 'companies-settings';
export const COMPANIES = 'companies';
export const ALL = 'all';

export const CHART_OF_ACCOUNTS = 'chart-of-accounts';
export const COUNTER_PARTIES = 'counter-parties';
export const DETAILS = 'details';

export const DEFAULT_COMPANY_REGISTRATION_NUMBER = '999999999999999';

export const PDF_CONTENT_TYPE = 'application/pdf';
export const PDF_JS_WORKER_URL = process.env.REACT_APP_PDFJS_WORKER_SRC;

export const EUROBANK_CODE = 'BPBI';

export const LANGUAGE_TO_LOCALE: Record<string, string> = {
  en: 'enUS',
  bg: 'bgBG'
};
