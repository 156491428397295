export const verificationFormWrapper = {
  '.MuiAccordion-root': {
    marginBottom: '16px !important'
  }
};

export const accordionHeader = {
  borderBottom: '1px dotted #ccc',
  fontWeight: 'bold',
  '.MuiAccordionSummary-content': {
    justifyContent: 'space-between'
  }
};

export const accordionBackgound = {
  backgroundColor: '#f9f9f9'
};

export const activeAccordionBackgound = {
  backgroundColor: '#eeeeee'
};

export const CheckCircleIconStyle = { marginLeft: 1, color: 'primary.main' };

export const verificationsButtonsWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  height: '40px',
  marginTop: 2
};

export const modalButtonsWrapper = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 2
};

export const verificationContentWrapper = {
  display: 'flex',
  justifyContent: 'center'
};

export const zoomButtonsWrapper = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '4px'
};

export const verificationFormFieldsWrapper = {
  display: 'flex',
  gap: 2
};

export const verificationFormFields = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2
};

export const verificationFormItem = {
  flex: 1
};

export const verificationFormItemFields = {
  borderBottom: '1px solid #ccc',
  paddingBottom: 2,
  '&:last-of-type': {
    borderBottom: 0,
    paddingBottom: 0
  }
};

export const verificationFieldsWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 3,
  width: '100%'
};

export const largeVerificationFieldWidth = {
  minWidth: '224px',
  width: '66%'
};

export const mediumVerificationFieldWidth = {
  width: '50%'
};

export const smallVerificationFieldWidth = {
  minWidth: '100px',
  width: '100%'
};

export const extraSmallVerificationFieldWidth = {
  minWidth: '100px',
  width: '31%'
};

export const processedInvoiceLabel = {
  color: 'rgb(7, 141, 238)',
  fontWeight: 600
};

export const nonMachineReadableLabel = {
  color: '#F44336',
  fontWeight: 600
};

export const actionButtonsWrapper = {
  display: 'flex',
  gap: 2,
  justifyContent: 'end',
  width: '100%'
};

export const verifyButtons = {
  background: '#87cefa',
  color: '#ffffff',
  boxShadow: '0px 2px 1px #00000026',
  '&:hover': {
    background: '#45aced'
  },
  '&:disabled': {
    background: '#d3d3d3'
  }
};

export const warningFieldStyle = {
  '.MuiFormLabel-root': {
    color: '#ff9800 !important'
  },
  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #ff9800'
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: '1px solid #ff9800 !important'
  }
};

export const errorFieldStyle = {
  '.MuiFormLabel-root': {
    color: '#ff0000'
  },
  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #ff0000'
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: '1px solid #ff0000'
  }
};
