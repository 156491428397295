import React, { useMemo } from 'react';

import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import settingsIcon from 'assets/images/ic_dashboard.svg';

import { usePermissions } from 'context/PermissionsContext';
import SidebarIcon from 'components/Sidebar/SidebarIcon/SidebarIcon';
import { PromptsTab } from 'components/Sidebar/SidebarTabs/PromptsTab';
import { CompaniesTab } from 'components/Sidebar/SidebarTabs/CompaniesTab';
import { createSettingsTabConditionalsManager } from 'components/Sidebar/SidebarHelper';
import { useTranslations } from 'context/TranslationContext';

import {
  icon,
  selectedIcon,
  subTabWrapper
} from 'styles/components/SidebarStyle';

import { TabType } from 'utils/enums/SidebarTabType';

interface SettingsTabProps {
  openTabs: TabType[];
  isSidebarExpanded: boolean;
  selectedTab: TabType | null;
  selectedNestedTab: string | null;
  handleTabClick: (tab: TabType) => void;
  handleNestedTabClick: (navigationRoute: string) => void;
}

export const SettingsTab: React.FC<SettingsTabProps> = ({
  isSidebarExpanded,
  selectedTab,
  openTabs,
  selectedNestedTab,
  handleTabClick,
  handleNestedTabClick
}) => {
  const permissions = usePermissions();
  const tabType = TabType.Settings;
  const { translate } = useTranslations();

  const layoutConditionalsManager = useMemo(
    () =>
      createSettingsTabConditionalsManager(
        isSidebarExpanded,
        selectedTab,
        openTabs
      ),
    [isSidebarExpanded, selectedTab, openTabs]
  );

  return (
    <div>
      {permissions.PROMPTS.read && permissions.COMPANIES.read && (
        <ListItemButton
          onClick={() => handleTabClick(TabType.Settings)}
          sx={layoutConditionalsManager.tabStyle}
        >
          <ListItemIcon sx={icon}>
            <SidebarIcon
              iconSource={settingsIcon}
              shouldBeSelected={
                openTabs.includes(tabType) ||
                selectedTab === tabType ||
                selectedTab === TabType.Prompts ||
                selectedTab === TabType.Companies
              }
            />
          </ListItemIcon>

          <ListItemText>
            <Typography
              sx={layoutConditionalsManager.textStyle}
              variant={
                layoutConditionalsManager.textTypography as 'body1' | 'body2'
              }
            >
              {translate('pages.settings')}
            </Typography>
          </ListItemText>

          {layoutConditionalsManager.expandIconState ? (
            <ExpandLess sx={selectedIcon} />
          ) : (
            <ExpandMore sx={icon} />
          )}
        </ListItemButton>
      )}

      {permissions.PROMPTS.read && permissions.COMPANIES.read && (
        <Collapse in={layoutConditionalsManager.isDropdownOpen} timeout="auto">
          <List sx={subTabWrapper} component="div" disablePadding>
            <PromptsTab
              openTabs={openTabs}
              isSidebarExpanded={isSidebarExpanded}
              selectedTab={selectedTab}
              handleTabClick={handleTabClick}
              handleNestedTabClick={handleNestedTabClick}
            />

            <CompaniesTab
              openTabs={openTabs}
              isSidebarExpanded={isSidebarExpanded}
              selectedTab={selectedTab}
              selectedNestedTab={selectedNestedTab}
              handleTabClick={handleTabClick}
              handleNestedTabClick={handleNestedTabClick}
            />
          </List>
        </Collapse>
      )}
    </div>
  );
};

export default SettingsTab;
