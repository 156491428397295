/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvoiceStages {
    UPLOADED = 'UPLOADED',
    PARSED = 'PARSED',
    EXTRACTED = 'EXTRACTED',
    DRAFT = 'DRAFT',
    VALIDATED = 'VALIDATED',
    FINALIZED = 'FINALIZED',
    EXPORTED = 'EXPORTED',
}
