import { CounterParty, CounterPartyCreateUpdate } from 'openapi';

export const mapCounterPartyValues = (
  counterParty?: CounterParty
): CounterPartyCreateUpdate => {
  if (!counterParty) {
    return {
      companyName: '',
      iban: '',
      companyRegistrationNumber: '',
      withoutCompanyRegistrationNumber: false,
      vatNumber: '',
      expenseTypeId: '',
      reverseCharge: undefined,
      vatCredit: undefined,
      type: undefined,
      balanceSheetAccountId: '',
      profitAndLossAccountId: '',
      vatPercentage: ''
    } as unknown as CounterPartyCreateUpdate;
  }

  const values = {
    ...counterParty,
    companyRegistrationNumber: counterParty.withoutCompanyRegistrationNumber
      ? ''
      : counterParty.companyRegistrationNumber,
    iban: counterParty.iban || '',
    vatNumber: counterParty.vatNumber || '',
    balanceSheetAccountId: counterParty.balanceSheetAccount?.id,
    profitAndLossAccountId: counterParty.profitAndLossAccount?.id,
    expenseTypeId: counterParty.expenseType?.id
  };

  delete values.balanceSheetAccount;
  delete values.profitAndLossAccount;
  delete values.expenseType;
  delete values.id;

  return values as CounterPartyCreateUpdate;
};
