import { useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { Field, Form, Formik, FieldArray, ArrayHelpers } from 'formik';

import { ApiError, Company, CompanyIban, Prompt } from 'openapi';

import { useCompanyController } from 'api/controllers/CompanyController';

import { useCompanies } from 'context/CompanyContext';
import { useTranslations } from 'context/TranslationContext';

import { initialFormValues } from 'utils/constants/initialValuesCreateCompany';
import { ROUTE_COMPANIES_SETTINGS } from 'utils/routes';
import { ZERO } from 'utils/constants/constants';

import {
  arrayFieldFormWrapper,
  arrayFieldWrapper,
  fullFieldWidth,
  submitButton
} from 'styles/components/Common';

interface CreateUpdateCompanyFormProps {
  companyDetails?: Company;
  prompts: Prompt[];
  onDelete: () => void;
}

export const CreateUpdateCompanyForm = ({
  companyDetails,
  prompts,
  onDelete
}: CreateUpdateCompanyFormProps): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { editCompany, addCompany } = useCompanyController();
  const { translate } = useTranslations();
  const { updateCompaniesList } = useCompanies();
  const [gptPromptIdValue, setGptPromptIdValue] = useState<number>(ZERO);

  const initialValues = useMemo(() => {
    if (id && companyDetails) {
      return {
        ...initialFormValues,
        name: companyDetails.name || '',
        alias: companyDetails.alias || '',
        companyRegistrationNumber:
          companyDetails.companyRegistrationNumber || '',
        companyIbanList: companyDetails.companyIbanList || [{ iban: '' }],
        vatNumber: companyDetails.vatNumber || '',
        gptPromptId: companyDetails.gptPromptId
      };
    }
    return {
      ...initialFormValues,
      companyIbanList: [{ iban: '' }]
    };
  }, [id, companyDetails]);

  const onSubmit = async (values: Company, currentCompany: Company) => {
    // Removing all the empty iban properties
    const filteredArray = values.companyIbanList?.filter(
      (item) => item.iban !== ''
    );

    const requestBody = {
      ...values,
      ...(id && { id: currentCompany.id }),
      companyIbanList: filteredArray
    };

    const response = id
      ? await editCompany(currentCompany.id as number, requestBody)
      : await addCompany(requestBody);

    if (!(response instanceof ApiError)) {
      updateCompaniesList(response);
      navigate(ROUTE_COMPANIES_SETTINGS);
    }
  };

  useEffect(() => {
    if (id && companyDetails?.gptPromptId) {
      setGptPromptIdValue(companyDetails.gptPromptId);
    }
  }, [id, companyDetails]);

  return (
    <Formik
      onSubmit={(values) =>
        onSubmit(values as Company, companyDetails as Company)
      }
      initialValues={initialValues}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        return (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px'
            }}
          >
            <Field
              as={TextField}
              label={translate('labels.fullCompanyName')}
              name="name"
              placeholder={translate('labels.fullCompanyName')}
            />
            <Field
              as={TextField}
              label={translate('labels.companyRegistrationNumber')}
              name="companyRegistrationNumber"
              placeholder={translate('labels.companyRegistrationNumber')}
            />
            <Field
              as={TextField}
              label={translate('labels.vatNumber')}
              name="vatNumber"
              placeholder={translate('vatNumber')}
            />
            <Field
              as={TextField}
              label={translate('labels.shortCompanyName')}
              name="alias"
              placeholder={translate('labels.shortCompanyName')}
            />
            <FieldArray
              name="companyIbanList"
              render={(arrayHelpers: ArrayHelpers) => {
                const handleAddRemoveIban = (index?: number) => () => {
                  index || index === 0
                    ? arrayHelpers.remove(index)
                    : arrayHelpers.push({ iban: '' });
                };

                return (
                  <Box sx={arrayFieldWrapper}>
                    {values.companyIbanList && values.companyIbanList.length > 0
                      ? (values.companyIbanList as CompanyIban[]).map(
                          (_: CompanyIban, index: number) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Box key={index} sx={arrayFieldFormWrapper}>
                              <Field
                                as={TextField}
                                label={translate('labels.iban')}
                                name={`companyIbanList.${index}.iban`}
                                placeholder={translate('labels.iban')}
                                sx={fullFieldWidth}
                              />
                              {index !== values.companyIbanList.length - 1 ? (
                                <RemoveCircleOutlineIcon
                                  color="primary"
                                  sx={{ cursor: 'pointer' }}
                                  onClick={handleAddRemoveIban(index)}
                                />
                              ) : (
                                <AddCircleOutlineIcon
                                  color="primary"
                                  sx={{ cursor: 'pointer' }}
                                  onClick={handleAddRemoveIban()}
                                />
                              )}
                            </Box>
                          )
                        )
                      : null}
                  </Box>
                );
              }}
            />
            {id && (
              <FormControl>
                <InputLabel>{translate('labels.gptPrompt')}</InputLabel>
                <Select
                  labelId="gptPromptId"
                  id="gptPromptId"
                  name="gptPromptId"
                  value={gptPromptIdValue || ''}
                  label={translate('labels.gptPrompt')}
                  onChange={(e) => {
                    setGptPromptIdValue(e.target.value as number);
                    setFieldValue('gptPromptId', e.target.value as number);
                  }}
                >
                  {prompts.length > ZERO &&
                    prompts.map((prompt: Prompt) => (
                      <MenuItem key={prompt.id} value={prompt.id as number}>
                        {prompt.promptName || prompt.id}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Button
                variant="contained"
                type="submit"
                sx={{ ...submitButton, width: '45%' }}
              >
                {id ? translate('buttons.edit') : translate('buttons.add')}
              </Button>
              {id && (
                <Button
                  variant="contained"
                  color="error"
                  sx={{ ...submitButton, width: '45%' }}
                  onClick={onDelete}
                >
                  {translate('buttons.delete')}
                </Button>
              )}
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
