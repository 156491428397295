import { useCallback } from 'react';
import {
  AccountCreate,
  AccountPatch,
  AccountService,
  AccountType
} from 'openapi';

import { showSuccessToastMessage } from 'components/shared/Toast/Toast';

import { useTranslations } from 'context/TranslationContext';
import interceptRequest from 'utils/helpers/interceptRequest';

export const useAccountController = () => {
  const { translate } = useTranslations();

  const {
    getAllAccountsByCompanyId,
    createAccount,
    patchAccount,
    deleteAccountsByIds
  } = AccountService;

  const getAccounts = useCallback(
    (id: number, accountType: AccountType) => {
      return interceptRequest(getAllAccountsByCompanyId, {}, id, accountType);
    },
    [getAllAccountsByCompanyId]
  );

  const createNewAccount = (id: number, body: AccountCreate) =>
    interceptRequest(
      createAccount,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyCreated'))
      },
      id,
      body
    );

  const editAccount = (id: number, accountId: number, body: AccountPatch) =>
    interceptRequest(
      patchAccount,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyUpdated'))
      },
      id,
      accountId,
      body
    );

  const deleteAccounts = (id: number, accountIds: number[]) =>
    interceptRequest(
      deleteAccountsByIds,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyDeleted'))
      },
      id,
      accountIds
    );

  return {
    getAccounts,
    createNewAccount,
    editAccount,
    deleteAccounts
  };
};
