import React, { ReactNode, useContext, useMemo, useState } from 'react';

import en from 'i18n/en.json';
import bg from 'i18n/bg.json';

import { APP_LANGUAGE, LANGUAGE_CONFIG } from 'config/i18nConfig';

import { JSONObject, Translations } from 'utils/interfaces/Translations';
import {
  replaceParams,
  getNestedValues
} from 'utils/helpers/translationHelper';

const TRANSLATIONS_DICTIONARY: JSONObject = {
  en,
  bg
};

const TranslationsContext = React.createContext<Translations>({
  currentLanguage: '',
  setCurrentLanguage: () => {},
  translate: () => ''
});

interface TranslationsProviderProps {
  readonly children: ReactNode;
}

export const TranslationsProvider = ({
  children
}: TranslationsProviderProps) => {
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem(APP_LANGUAGE) || LANGUAGE_CONFIG.defaultLanguage.code
  );

  const setNewLanguage = (newLanguage: string) => {
    if (TRANSLATIONS_DICTIONARY[newLanguage]) {
      localStorage.setItem(APP_LANGUAGE, newLanguage);
      setCurrentLanguage(newLanguage);
    } else {
      localStorage.setItem(APP_LANGUAGE, LANGUAGE_CONFIG.defaultLanguage.code);
      setCurrentLanguage(LANGUAGE_CONFIG.defaultLanguage.code);
    }
  };

  const translate = (
    module: string,
    params: { [key: string]: string } = {}
  ): string => {
    const language = TRANSLATIONS_DICTIONARY[currentLanguage];
    const label = getNestedValues(language, module);

    if (label === undefined) {
      return module;
    }

    const templateLabel = replaceParams(label, params);

    return templateLabel || label;
  };

  const context = useMemo(
    () => ({
      currentLanguage,
      setCurrentLanguage: setNewLanguage,
      translate
    }),

    [currentLanguage]
  );

  return (
    <TranslationsContext.Provider value={context}>
      {children}
    </TranslationsContext.Provider>
  );
};

export const useTranslations = (): Translations => {
  const context = useContext(TranslationsContext);
  if (context === undefined) {
    throw new Error(
      'useTranslations must be used with-in a TranslationsProvider'
    );
  }
  return context;
};
