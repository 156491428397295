import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Company } from 'openapi';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  createTheme,
  Link,
  List,
  ListItem,
  ThemeProvider
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { useCompanyController } from 'api/controllers/CompanyController';

import { useCompanies } from 'context/CompanyContext';
import { useTranslations } from 'context/TranslationContext';
import { createSidebarConditionalsManager } from 'components/Sidebar/SidebarHelper';
import { InvoicesTab } from 'components/Sidebar/SidebarTabs/InvoicesTab';
import { BankStatementsTab } from 'components/Sidebar/SidebarTabs/BankStatements';
import { SettingsTab } from 'components/Sidebar/SidebarTabs/SettingsTab';

import {
  sidebarMainList,
  sidebarLightTheme
} from 'styles/components/SidebarStyle';
import { linkStyle, logo as logoStyle } from 'styles/components/HeaderStyle';

import { ROUTE_INVOICES } from 'utils/routes';
import { TabType } from 'utils/enums/SidebarTabType';
import { isAuthenticated } from 'utils/helpers/authHelper';

import {
  ALL,
  BANK_STATEMENTS,
  COMPANIES,
  COMPANIES_SETTINGS,
  INVOICE,
  INVOICES,
  PROMPT_SETTINGS,
  SETTINGS
} from 'utils/constants/constants';

interface SidebarProps {
  setSidebarExpanded: (value: boolean) => void;
  isSidebarExpanded: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({
  setSidebarExpanded,
  isSidebarExpanded
}) => {
  const [selectedTab, setSelectedTab] = useState<TabType | null>(null);
  const [openTabs, setOpenTab] = useState<TabType[]>([]);
  const [selectedNestedTab, setSelectedNestedTab] = useState<string | null>(
    null
  );

  const navigate = useNavigate();
  const location = useLocation();
  const { translate } = useTranslations();
  const { getAllCompanies } = useCompanyController();
  const { companiesList, setCompaniesList } = useCompanies();

  const sidebarTheme = createTheme(sidebarLightTheme);

  const layoutConditionalsManager = useMemo(
    () => createSidebarConditionalsManager(isSidebarExpanded),
    [isSidebarExpanded]
  );

  const fetchCompanies = useCallback(async () => {
    const allCompanies = await getAllCompanies();
    const sortedCompanies = allCompanies.sort(
      (a: Company, b: Company) => a.id! - b.id!
    );
    setCompaniesList([...sortedCompanies]);
  }, [setCompaniesList]);

  const getSidebarStateFromRoute = () => {
    const routeArray = location.pathname.split('/');

    const primaryRoute = routeArray[1];
    const secondaryRoute = routeArray[2];
    const tertiaryRoute = routeArray[3];

    const updateSidebarState = (
      selected: TabType | null,
      open: TabType[] = [],
      nested: string | null = null
    ) => {
      setSelectedTab(selected);
      setOpenTab(open);
      setSelectedNestedTab(nested);
    };

    switch (primaryRoute) {
      case COMPANIES:
        if (tertiaryRoute === INVOICE) {
          updateSidebarState(
            TabType.Invoice,
            [TabType.Invoice],
            secondaryRoute
          );
        } else {
          updateSidebarState(
            TabType.BankStatements,
            [TabType.BankStatements],
            secondaryRoute
          );
        }
        break;

      case INVOICES:
        updateSidebarState(TabType.Invoice, [TabType.Invoice], ALL);
        break;

      case BANK_STATEMENTS:
        updateSidebarState(
          TabType.BankStatements,
          [TabType.BankStatements],
          ALL
        );
        break;

      case SETTINGS:
        if (secondaryRoute === PROMPT_SETTINGS && !tertiaryRoute) {
          updateSidebarState(TabType.Prompts, [
            TabType.Prompts,
            TabType.Settings
          ]);
        } else if (secondaryRoute === COMPANIES_SETTINGS && tertiaryRoute) {
          updateSidebarState(
            TabType.Companies,
            [TabType.Companies, TabType.Settings],
            tertiaryRoute
          );
        }
        break;

      default:
        updateSidebarState(null);
        break;
    }
  };

  useEffect(() => {
    if (!companiesList.length) {
      fetchCompanies();
    }
  }, [companiesList.length, fetchCompanies]);

  useEffect(() => {
    getSidebarStateFromRoute();
  }, [location.pathname]);

  const handleSidebarResize = () => {
    setSidebarExpanded(!isSidebarExpanded);
  };

  const handleTabClick = (
    tabType: TabType,
    shouldCloseOthers: boolean = true
  ) => {
    if (isSidebarExpanded) {
      if (openTabs.includes(tabType)) {
        setOpenTab(
          shouldCloseOthers ? [] : openTabs.filter((tab) => tab !== tabType)
        );
      } else if (shouldCloseOthers) {
        setOpenTab([tabType]);
      } else {
        setOpenTab([...openTabs, tabType]);
      }
    } else if (!isSidebarExpanded) {
      if (openTabs.includes(tabType)) {
        setSidebarExpanded(true);
      } else if (shouldCloseOthers) {
        setOpenTab([tabType]);
        setSidebarExpanded(true);
      } else {
        setOpenTab([...openTabs, tabType]);
        setSidebarExpanded(true);
      }
    }
  };

  const handleNestedTabClick = (navigationRoute: string) => {
    navigate(navigationRoute);
  };

  return (
    <ThemeProvider theme={sidebarTheme}>
      {isAuthenticated() && (
        <Box sx={layoutConditionalsManager.sidebarStyle}>
          <Button
            sx={layoutConditionalsManager.expandButtonStyle}
            onClick={handleSidebarResize}
          >
            {isSidebarExpanded ? <ChevronLeft /> : <ChevronRight />}
          </Button>

          <List sx={sidebarMainList}>
            <ListItem sx={logoStyle}>
              <Link component={NavLink} to={ROUTE_INVOICES} sx={linkStyle}>
                {translate('labels.iHub')}
              </Link>
            </ListItem>

            <InvoicesTab
              openTabs={openTabs}
              isSidebarExpanded={isSidebarExpanded}
              selectedTab={selectedTab}
              selectedNestedTab={selectedNestedTab}
              handleTabClick={handleTabClick}
              handleNestedTabClick={handleNestedTabClick}
            />

            <BankStatementsTab
              openTabs={openTabs}
              isSidebarExpanded={isSidebarExpanded}
              selectedTab={selectedTab}
              selectedNestedTab={selectedNestedTab}
              handleTabClick={handleTabClick}
              handleNestedTabClick={handleNestedTabClick}
            />

            <SettingsTab
              openTabs={openTabs}
              isSidebarExpanded={isSidebarExpanded}
              selectedTab={selectedTab}
              selectedNestedTab={selectedNestedTab}
              handleTabClick={handleTabClick}
              handleNestedTabClick={handleNestedTabClick}
            />
          </List>
        </Box>
      )}
    </ThemeProvider>
  );
};

export default Sidebar;
