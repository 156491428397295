import React, { useMemo } from 'react';
import { Company } from 'openapi';
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import companiesIcon from 'assets/images/ic_job.svg';

import { useCompanies } from 'context/CompanyContext';
import { usePermissions } from 'context/PermissionsContext';
import SidebarIcon from 'components/Sidebar/SidebarIcon/SidebarIcon';
import { createCompaniesTabConditionalsManager } from 'components/Sidebar/SidebarHelper';
import { useTranslations } from 'context/TranslationContext';

import { icon, selectedIcon } from 'styles/components/SidebarStyle';

import { ROUTE_ADD_COMPANY, ROUTE_COMPANY_DETAILS } from 'utils/routes';
import { idStringReplace } from 'utils/helpers/idStringReplace';
import { TabType } from 'utils/enums/SidebarTabType';

interface CompaniesTabProps {
  openTabs: TabType[];
  isSidebarExpanded: boolean;
  selectedTab: TabType | null;
  selectedNestedTab: string | null;
  handleTabClick: (tab: TabType, shouldCloseOthers: boolean) => void;
  handleNestedTabClick: (navigationRoute: string) => void;
}

export const CompaniesTab: React.FC<CompaniesTabProps> = ({
  isSidebarExpanded,
  selectedTab,
  openTabs,
  selectedNestedTab,
  handleTabClick,
  handleNestedTabClick
}) => {
  const permissions = usePermissions();
  const { companiesList } = useCompanies();
  const { translate } = useTranslations();

  const tabType = TabType.Companies;

  const layoutConditionalsManager = useMemo(
    () =>
      createCompaniesTabConditionalsManager(
        isSidebarExpanded,
        selectedTab,
        openTabs,
        selectedNestedTab
      ),
    [isSidebarExpanded, selectedTab, openTabs, selectedNestedTab]
  );

  return (
    <div>
      {permissions.COMPANIES.read && (
        <ListItemButton
          onClick={() => handleTabClick(TabType.Companies, false)}
          sx={layoutConditionalsManager.tabStyle}
        >
          <ListItemIcon sx={layoutConditionalsManager.iconStyle}>
            <SidebarIcon
              iconSource={companiesIcon}
              shouldBeSelected={
                openTabs.includes(tabType) || selectedTab === tabType
              }
            />
          </ListItemIcon>

          <ListItemText>
            <Typography
              sx={layoutConditionalsManager.textStyle}
              variant={
                layoutConditionalsManager.textTypography as 'body1' | 'body2'
              }
            >
              {translate('pages.companies')}
            </Typography>
          </ListItemText>

          {layoutConditionalsManager.expandIconState ? (
            <ExpandLess sx={selectedIcon} />
          ) : (
            <ExpandMore sx={icon} />
          )}
        </ListItemButton>
      )}

      {permissions.COMPANIES.read && (
        <Collapse in={layoutConditionalsManager.isDropdownOpen} timeout="auto">
          <List component="div" disablePadding>
            {permissions.COMPANIES.create && (
              <ListItemButton
                sx={layoutConditionalsManager.addSubTabStyle}
                onClick={() => handleNestedTabClick(ROUTE_ADD_COMPANY)}
              >
                <Box sx={layoutConditionalsManager.addSubTabBulletStyle} />

                <Typography
                  variant={
                    layoutConditionalsManager.addSubTabTypography as
                      | 'body1'
                      | 'body2'
                  }
                >
                  {translate('buttons.add')}
                </Typography>
              </ListItemButton>
            )}

            {companiesList.map((company: Company) => (
              <ListItemButton
                sx={layoutConditionalsManager.subTabsStyle(company.id!)}
                onClick={() =>
                  handleNestedTabClick(
                    idStringReplace(
                      ROUTE_COMPANY_DETAILS,
                      company.id!.toString()
                    )
                  )
                }
                key={company.id}
              >
                <Box
                  sx={layoutConditionalsManager.subTabsBulletStyle(company.id!)}
                />

                <Typography
                  variant={layoutConditionalsManager.subTabsTypography(
                    company.id!
                  )}
                >
                  {' '}
                  {company.alias}{' '}
                </Typography>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      )}
    </div>
  );
};

export default CompaniesTab;
