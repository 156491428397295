import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { usePromptController } from 'api/controllers/PromptController';

import { CreateUpdatePromptForm } from 'components/forms/CreateUpdatePromptForm';
import { Layout } from 'components/Layout/Layout';

import { useTranslations } from 'context/TranslationContext';

import { ROUTE_PROMPT_SETTINGS } from 'utils/routes';

import { mediumVerificationFieldWidth } from 'styles/pages/InvoiceVerificationStyle';

export const AddPrompt: React.FC = (): JSX.Element => {
  const { translate } = useTranslations();
  const { addPrompt } = usePromptController();
  const navigate = useNavigate();

  const onSubmit = async (values: any) => {
    await addPrompt(values);
    navigate(ROUTE_PROMPT_SETTINGS);
  };

  const initialValues = {
    promptName: '',
    userPrompt: ''
  };

  return (
    <Layout pageTitle={translate('pages.addPrompt')}>
      <Box sx={mediumVerificationFieldWidth}>
        <CreateUpdatePromptForm
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      </Box>
    </Layout>
  );
};
