import { useState, useMemo } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button
} from '@mui/material';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';

import { Account, AccountType } from 'openapi';

import { formSmallContainer, submitButton } from 'styles/components/Common';

import { useTranslations } from 'context/TranslationContext';

interface CreateUpdateAccountProps {
  readonly handleSubmit: (values: Account) => void;
  readonly initialValues?: Account;
}
export const CreateUpdateAccount = ({
  handleSubmit,
  initialValues
}: CreateUpdateAccountProps) => {
  const { translate } = useTranslations();

  const isEditMode = useMemo(() => !!initialValues, [initialValues]);

  const [formValues, setFormValues] = useState<Account>(
    initialValues || {
      type: undefined,
      value: '',
      description: ''
    }
  );

  // Form
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        type: Yup.string().required(translate('errors.type')),
        value: Yup.string().required(translate('errors.accountNumber')),
        description: Yup.string().required(translate('errors.accountName'))
      }),
    [translate]
  );
  const accountTypeOptions = useMemo(
    () => [
      { id: AccountType.BS, label: translate('labels.balanceSheetAccount') },
      { id: AccountType.PL, label: translate('labels.pnlAccount') }
    ],
    [translate]
  );

  return (
    <Box>
      <Formik
        onSubmit={handleSubmit}
        initialValues={formValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ setFieldValue, touched, errors, values }) => (
          <Form
            style={{ ...formSmallContainer, flexDirection: 'column' }}
            noValidate
          >
            <FormControl error={!!(touched.type && errors.type)}>
              <InputLabel required>{translate('labels.type')}</InputLabel>
              <Select
                name="type"
                value={formValues.type || ''}
                label={translate('labels.type')}
                onChange={(e) => {
                  setFormValues({
                    ...values,
                    type: e.target.value as AccountType
                  });
                  setFieldValue('type', e.target.value);
                }}
              >
                {accountTypeOptions.map((type: any) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
              {touched.type && errors.type && (
                <FormHelperText>{errors.type}</FormHelperText>
              )}
            </FormControl>

            <Field
              as={TextField}
              label={translate('labels.accountNumber')}
              name="value"
              placeholder={translate('labels.accountNumber')}
              helperText={<ErrorMessage name="value" />}
              error={touched.value && !!errors.value}
              disabled={isEditMode}
              required
            />

            <Field
              as={TextField}
              label={translate('labels.name')}
              name="description"
              placeholder={translate('labels.name')}
              helperText={<ErrorMessage name="description" />}
              error={touched.description && !!errors.description}
              required
            />

            <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Button variant="contained" type="submit" sx={submitButton}>
                {translate('buttons.save')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
