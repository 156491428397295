/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceListResponse } from '../models/InvoiceListResponse';
import type { InvoicePatch } from '../models/InvoicePatch';
import type { InvoiceResponse } from '../models/InvoiceResponse';
import type { InvoiceSection } from '../models/InvoiceSection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvoiceService {

    /**
     * This method returns the list of invoices by company id
     * @param id The company id
     * @param stage Stage of invoices
     * @returns InvoiceListResponse OK
     * @throws ApiError
     */
    public static getInvoicesByCompanyId(
        id: number,
        stage?: string,
    ): CancelablePromise<InvoiceListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/invoices',
            path: {
                'id': id,
            },
            query: {
                'stage': stage,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the data extracted from the invoice by the ChatGPT model
     * @param id The company id
     * @param formData File image to be uploaded
     * @returns any OK
     * @throws ApiError
     */
    public static uploadInvoice(
        id: number,
        formData: {
            files?: Array<Blob>;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/invoices',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of invoices
     * @param stage Stage of invoices
     * @returns InvoiceListResponse OK
     * @throws ApiError
     */
    public static getInvoices(
        stage?: string,
    ): CancelablePromise<InvoiceListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/invoices',
            query: {
                'stage': stage,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method deletes multiple invoices by id
     * @param invoiceIds List of invoice ids to be deleted
     * @returns void
     * @throws ApiError
     */
    public static deleteInvoicesByIds(
        invoiceIds: Array<number>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/invoices',
            query: {
                'invoiceIds': invoiceIds,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update an existing invoice
     * Modifies an existing invoice object with new data
     * @param id The invoice id
     * @param invoiceSection The invoice section
     * @param requestBody The updated data for the invoice
     * @returns InvoiceResponse OK
     * @throws ApiError
     */
    public static patchInvoice(
        id: number,
        invoiceSection: InvoiceSection,
        requestBody: InvoicePatch,
    ): CancelablePromise<InvoiceResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/invoices/{id}/invoiceSections/{invoiceSection}',
            path: {
                'id': id,
                'invoiceSection': invoiceSection,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get pdf document for a single invoice
     * @param id The invoice id
     * @returns binary OK
     * @throws ApiError
     */
    public static getInvoicePdfById(
        id: number,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/invoices/{id}/document',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Verify an invoice section
     * @param id The invoice id
     * @param invoiceSection The invoice section to verify
     * @returns any OK
     * @throws ApiError
     */
    public static verifyInvoiceSectionById(
        id: number,
        invoiceSection: InvoiceSection,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/invoices/{id}/invoiceSections/{invoiceSection}/verify',
            path: {
                'id': id,
                'invoiceSection': invoiceSection,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
