import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { ApiError, BankStatement, Company, Invoice } from 'openapi';

import { useInvoiceController } from 'api/controllers/InvoiceController';
import { useCompanyController } from 'api/controllers/CompanyController';
import { useBankStatementController } from 'api/controllers/BankStatementController';

import { useTranslations } from 'context/TranslationContext';
import { useNotification } from 'context/NotificationContext';

import { Layout } from 'components/Layout/Layout';
import { DropzoneArea } from 'components/DropzoneArea/DropzoneArea';
import { InvoicesDataGrid } from 'components/InvoicesDataGrid/InvoicesDataGrid';
import { BankStatementDataGrid } from 'components/BankStatementDataGrid/BankStatementDataGrid';

import { ROUTE_UPLOAD_INVOICES } from 'utils/routes';
import { BUTTON } from 'utils/constants/constants';
import { DocumentsType } from 'utils/enums/DocumentsType';

export const DocumentsUpload = ({
  documentsType
}: {
  documentsType: DocumentsType;
}): JSX.Element => {
  const { id: companyId } = useParams();
  const { translate } = useTranslations();
  const { addInvoice, getAllInvoicesByCompanyId } = useInvoiceController();
  const { getCompanyDetailsById } = useCompanyController();
  const { getCompanyBankStatements, addBankStatements } =
    useBankStatementController();
  const { notification } = useNotification();
  const location = useLocation();

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [currentCompany, setCurrentCompany] = useState<Company>();
  const [loading, setLoading] = useState<boolean>(false);
  const [bankStatements, setBankStatements] = useState<BankStatement[]>([]);

  const [showVisualization, setShowVisualization] = useState<boolean>(false);

  const memorizedPageTitle = useMemo(() => {
    let pageTitle;

    if (currentCompany) {
      pageTitle = `${currentCompany.name} ${currentCompany.vatNumber}`;
    } else {
      pageTitle = translate('pages.companyDetails');
    }

    return pageTitle;
  }, [currentCompany, translate]);

  const handleUploadInvoice = async (
    fileData: FileList | File[],
    field: string
  ) => {
    let blobArray;

    setLoading(true);
    if (field === BUTTON) {
      blobArray = Array.from(fileData as []).map((file) => file as Blob);
    } else {
      blobArray = fileData;
    }

    let response = [];
    if (documentsType === DocumentsType.INVOICES) {
      response = await addInvoice(Number(companyId), {
        files: blobArray as Array<Blob>
      });
    } else {
      response = await addBankStatements(Number(companyId), {
        files: blobArray as Array<Blob>
      });

      if (!(response instanceof ApiError)) {
        setBankStatements([...response, ...bankStatements]);
      }
    }

    setLoading(false);
  };

  const getCurrentCompany = async () => {
    const company = await getCompanyDetailsById(Number(companyId));
    setCurrentCompany(company);
  };

  const fetchCompanyBankStatements = async () => {
    const companyBankStatements = await getCompanyBankStatements(
      Number(companyId)
    );
    const sortedBankStatements = companyBankStatements.sort(
      (a: BankStatement, b: BankStatement) => {
        return a.id! - b.id!;
      }
    );
    setBankStatements(sortedBankStatements);
  };

  const fetchCompanyInvoices = async () => {
    const invoicesByCompanyId = await getAllInvoicesByCompanyId(
      Number(companyId)
    );
    setInvoices(invoicesByCompanyId);
  };

  useEffect(() => {
    getCurrentCompany();

    if (documentsType === DocumentsType.BANK_STATEMENTS) {
      fetchCompanyBankStatements();
    }

    if (documentsType === DocumentsType.INVOICES) {
      fetchCompanyInvoices();
    }
  }, [notification, location.pathname]);

  return (
    <Layout pageTitle={memorizedPageTitle}>
      {!showVisualization && (
        <>
          <Paper elevation={6}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              justifyContent="space-between"
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'row', padding: '10px' }}
              >
                <FileUploadIcon color="primary" />
                <Typography sx={{ color: '#7C7C7C' }}>
                  {translate('labels.uploadFiles')}
                </Typography>
              </Box>
              <Box sx={{ padding: '10px' }}>
                <Button variant="contained" component="label">
                  {translate('buttons.upload')}
                  <input
                    hidden
                    accept="application/pdf"
                    type="file"
                    name="invoice"
                    multiple
                    onChange={(e) => {
                      handleUploadInvoice(e.target.files as FileList, 'button');
                      e.target.value = '';
                    }}
                  />
                </Button>
              </Box>
            </Stack>
          </Paper>
          <Box mb="24px">
            <DropzoneArea
              onFilesSelected={(files) =>
                handleUploadInvoice(files, 'dragAndDropArea')
              }
            />
          </Box>
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}

      {documentsType === DocumentsType.INVOICES && (
        <InvoicesDataGrid
          invoicesList={invoices}
          setInvoicesList={setInvoices}
          stateRoute={ROUTE_UPLOAD_INVOICES}
        />
      )}

      {documentsType === DocumentsType.BANK_STATEMENTS && (
        <BankStatementDataGrid
          bankStatements={bankStatements}
          updateBankStatements={fetchCompanyBankStatements}
          setShowVisualization={setShowVisualization}
        />
      )}
    </Layout>
  );
};
