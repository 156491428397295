import React from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import {
  GridToolbarContainer,
  GridRowSelectionModel,
  GridToolbar as DefaultGridToolbar
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';

import { useTranslations } from 'context/TranslationContext';

import {
  gridToolbarContainerStyle,
  gridToolbarStyle,
  gridToolbarButtonsStyle,
  defaultGridToolbarStyle
} from 'styles/components/DataGridStyle';

interface GridToolbarProps {
  readonly handleAddClick?: () => void;
  readonly handleDeleteClick?: () => void;
  readonly handleExportForPaymentClick?: () => void;
  readonly handleExportExcelClick?: () => void;
  readonly selectedRows?: GridRowSelectionModel;
  readonly isExportDisabled?: boolean;
  readonly exportButtonTitle?: string;
}

export const GridToolbar = ({
  handleAddClick,
  handleDeleteClick,
  handleExportForPaymentClick,
  handleExportExcelClick,
  selectedRows,
  isExportDisabled,
  exportButtonTitle
}: GridToolbarProps) => {
  const { translate } = useTranslations();

  const justifyContent =
    selectedRows && selectedRows.length > 0 ? 'space-between' : 'flex-end';

  return (
    <Box sx={gridToolbarContainerStyle}>
      <DefaultGridToolbar sx={defaultGridToolbarStyle} />
      <GridToolbarContainer sx={{ ...gridToolbarStyle, justifyContent }}>
        {selectedRows && selectedRows.length > 0 && (
          <Box sx={gridToolbarButtonsStyle}>
            {handleDeleteClick && (
              <Button
                variant="outlined"
                color="error"
                onClick={handleDeleteClick}
              >
                {translate('buttons.delete')}
              </Button>
            )}
            {handleExportForPaymentClick && (
              <Tooltip title={exportButtonTitle}>
                {/* Span tag is needed for the tooltip. */}
                <span>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleExportForPaymentClick}
                    disabled={isExportDisabled}
                  >
                    {translate('buttons.exportForPayment')}
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>
        )}
        {handleAddClick && (
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddClick}
          >
            {translate('buttons.add')}
          </Button>
        )}
        {handleExportExcelClick && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleExportExcelClick}
            disabled={isExportDisabled}
          >
            {translate('buttons.exportExcel')}
          </Button>
        )}
      </GridToolbarContainer>
    </Box>
  );
};
