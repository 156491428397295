import { useCallback } from 'react';

import { Company, CompanyService } from 'openapi';

import { showSuccessToastMessage } from 'components/shared/Toast/Toast';

import { useTranslations } from 'context/TranslationContext';

import interceptRequest from 'utils/helpers/interceptRequest';

export const useCompanyController = () => {
  const { translate } = useTranslations();
  const {
    getCompanies,
    getCompanyById,
    updateCompany,
    createCompany,
    deleteCompanyById
  } = CompanyService;

  const getAllCompanies = useCallback(
    () => interceptRequest(getCompanies, {}),
    [getCompanies]
  );

  const getCompanyDetailsById = useCallback(
    (id: number) => interceptRequest(getCompanyById, {}, id),
    [getCompanyById]
  );

  const editCompany = (id: number, requestBody: Company) =>
    interceptRequest(
      updateCompany,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyUpdated'))
      },
      id,
      requestBody
    );

  const addCompany = (requestBody: Company) =>
    interceptRequest(
      createCompany,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyCreated'))
      },
      requestBody
    );

  const deleteCompany = (id: number) =>
    interceptRequest(
      deleteCompanyById,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyDeleted'))
      },
      id
    );

  return {
    getAllCompanies,
    getCompanyDetailsById,
    editCompany,
    addCompany,
    deleteCompany
  };
};
