import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  TextareaAutosize,
  Button,
  Box,
  TextField,
  FormHelperText,
  FormControl
} from '@mui/material';
import * as Yup from 'yup';

import { useTranslations } from 'context/TranslationContext';

import { Prompt } from 'openapi';

import {
  promptFormFields,
  promptTextArea
} from 'styles/pages/PromptSettingsStyle';
import { submitButton } from 'styles/components/Common';

interface CreateUpdatePromptFormProps {
  initialValues: Prompt;
  onSubmit: (values: any) => void;
  isSubmitting?: boolean;
  onDelete?: () => void;
}

export const CreateUpdatePromptForm: React.FC<CreateUpdatePromptFormProps> = ({
  initialValues,
  onSubmit,
  isSubmitting = false,
  onDelete
}): JSX.Element => {
  const { translate } = useTranslations();
  const { id } = useParams();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        promptName: Yup.string().required(translate('errors.promptName')),
        userPrompt: Yup.string().required(translate('errors.prompt'))
      }),
    [translate]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ touched, errors }) => (
        <Form style={promptFormFields}>
          <Field
            as={TextField}
            label={translate('labels.promptName')}
            name="promptName"
            placeholder={translate('labels.promptName')}
            helperText={<ErrorMessage name="promptName" />}
            error={touched.promptName && !!errors.promptName}
          />
          <FormControl error={!!(touched.userPrompt && errors.userPrompt)}>
            <Field
              as={TextareaAutosize}
              label={translate('labels.prompt')}
              placeholder={translate('labels.prompt')}
              name="userPrompt"
              style={promptTextArea}
            />
            {touched.userPrompt && !!errors.userPrompt && (
              <FormHelperText>{errors.userPrompt}</FormHelperText>
            )}
          </FormControl>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              sx={{ ...submitButton, width: '45%' }}
            >
              {id ? translate('buttons.edit') : translate('buttons.save')}
            </Button>
            {id && (
              <Button
                color="error"
                variant="contained"
                sx={{ ...submitButton, width: '45%' }}
                onClick={onDelete}
              >
                {translate('buttons.delete')}
              </Button>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
