import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Paper, Box, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import { Company } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { DETAILS, ZERO } from 'utils/constants/constants';
import {
  ROUTE_ADD_COMPANY,
  ROUTE_COMPANY_DETAILS,
  ROUTE_UPLOAD_BANK_STATEMENTS,
  ROUTE_UPLOAD_INVOICES
} from 'utils/routes';
import { DocumentsType } from 'utils/enums/DocumentsType';
import { idStringReplace } from 'utils/helpers/idStringReplace';

import { cardWrapper } from 'styles/components/CompanyWrapperStyle';

interface CompaniesListProps {
  companies: Company[];
  isCompaniesSettingsPage: boolean;
  documentsType?: DocumentsType;
}

export const CompaniesList = React.memo(
  ({
    companies,
    isCompaniesSettingsPage,
    documentsType
  }: CompaniesListProps): JSX.Element => {
    const { translate } = useTranslations();
    const navigate = useNavigate();

    const handleNavigate = (id: number) => {
      const ROUTE_TO_REDIRECT =
        documentsType === DocumentsType.INVOICES
          ? ROUTE_UPLOAD_INVOICES
          : ROUTE_UPLOAD_BANK_STATEMENTS;

      navigate(idStringReplace(ROUTE_TO_REDIRECT, id.toString()));
    };

    const renderCompany = (company: Company) => {
      return (
        <Paper
          elevation={4}
          className="paper"
          key={company.id}
          onClick={() => {
            !isCompaniesSettingsPage
              ? handleNavigate(Number(company.id))
              : navigate(
                  `${idStringReplace(
                    ROUTE_COMPANY_DETAILS,
                    company.id!.toString()
                  )}#${DETAILS}`
                );
          }}
          sx={{ width: '260px' }}
        >
          <Box className="textWrapper">
            <Typography variant="body1">{company.name}</Typography>
          </Box>
          {isCompaniesSettingsPage && (
            <Button variant="text" className="button" sx={{ color: '#3ad551' }}>
              <ModeEditOutlineIcon />
            </Button>
          )}
        </Paper>
      );
    };

    return (
      <Box
        sx={{
          ...cardWrapper,
          ...(isCompaniesSettingsPage && { flexDirection: 'row' })
        }}
      >
        {companies.length > ZERO && companies.map(renderCompany)}
        {isCompaniesSettingsPage && (
          <Paper
            elevation={4}
            className="createNewCard"
            onClick={() => navigate(ROUTE_ADD_COMPANY)}
          >
            <AddIcon className="icon" />
            <Typography variant="body1">{translate('buttons.add')}</Typography>
          </Paper>
        )}
      </Box>
    );
  }
);
