import { Account, AccountCreate, AccountPatch } from 'openapi';

export const mapAccountForCreate = (account: Account): AccountCreate => {
  const accountForCreate = {
    ...account
  };

  delete accountForCreate.id;

  return accountForCreate as AccountCreate;
};

export const mapAccountForEdit = (account: Account): AccountPatch => {
  const accountForEdit = {
    ...account
  };

  delete accountForEdit.id;
  delete accountForEdit.value;

  return accountForEdit as AccountPatch;
};
