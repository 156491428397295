import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { BankStatement, Invoice } from 'openapi';

import { useInvoiceController } from 'api/controllers/InvoiceController';
import { useBankStatementController } from 'api/controllers/BankStatementController';

import { Layout } from 'components/Layout/Layout';
import { InvoicesDataGrid } from 'components/InvoicesDataGrid/InvoicesDataGrid';
import { BankStatementDataGrid } from 'components/BankStatementDataGrid/BankStatementDataGrid';

import { useNotification } from 'context/NotificationContext';

import { ROUTE_HOME } from 'utils/routes';
import { DocumentsType } from 'utils/enums/DocumentsType';

import { invoicesWrapper } from 'styles/pages/InvoicesStyle';
import { dataGridContainerStyle } from 'styles/components/DataGridStyle';

export const CompanyDocumentsView = ({
  documentsType
}: {
  documentsType: DocumentsType;
}): JSX.Element => {
  const { getAllInvoices } = useInvoiceController();
  const { getBankStatements } = useBankStatementController();

  const { notification } = useNotification();

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [bankStatements, setBankStatements] = useState<BankStatement[]>([]);

  const fetchInvoices = useCallback(async () => {
    const allInvoices = await getAllInvoices();
    setInvoices(allInvoices);
  }, []);

  const fetchBankStatements = useCallback(async () => {
    const allBankStatements = await getBankStatements();
    setBankStatements(allBankStatements);
  }, []);

  useEffect(() => {
    if (documentsType === DocumentsType.INVOICES) {
      fetchInvoices();
    }

    if (documentsType === DocumentsType.BANK_STATEMENTS) {
      fetchBankStatements();
    }
  }, [
    notification,
    documentsType,
    fetchInvoices,
    fetchBankStatements,
  ]);

  return (
    <Layout>
      <Box sx={invoicesWrapper}>
        {documentsType === DocumentsType.INVOICES && invoices && (
          <Box sx={dataGridContainerStyle}>
            <InvoicesDataGrid
              invoicesList={invoices}
              setInvoicesList={setInvoices}
              stateRoute={ROUTE_HOME}
            />
          </Box>
        )}

        {documentsType === DocumentsType.BANK_STATEMENTS &&
          bankStatements && (
            <Box sx={dataGridContainerStyle}>
              <BankStatementDataGrid
                bankStatements={bankStatements}
                updateBankStatements={fetchBankStatements}
              />
            </Box>
          )}
      </Box>
    </Layout>
  );
};
