import { createContext, useContext, useMemo, useState } from 'react';

interface NotificationState {
  title: string;
  body: string;
}

interface NotificationContextType {
  notification: NotificationState;
  setNotification: React.Dispatch<React.SetStateAction<NotificationState>>;
  clearNotification: () => void;
}

const NotificationContext = createContext<NotificationContextType | null>(null);

interface NotificationProviderProps {
  children: React.ReactNode;
}
export const NotificationProvider = ({
  children
}: NotificationProviderProps) => {
  const [notification, setNotification] = useState<NotificationState>({
    title: '',
    body: ''
  });

  const clearNotification = () => {
    setNotification({ title: '', body: '' });
  };

  const context = useMemo<NotificationContextType>(
    () => ({
      notification,
      setNotification,
      clearNotification
    }),
    [notification]
  );

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  return context;
};
