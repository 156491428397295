import { useRef, useState } from 'react';
import { Box, MenuItem } from '@mui/material';

import { useTranslations } from 'context/TranslationContext';

import { LANGUAGE_CONFIG } from 'config/i18nConfig';
import { CustomPopper } from 'components/shared/CustomPopper/CustomPopper';

interface LanguageMenuProps {
  sx: { [key: string]: string };
  alt: string;
  src: string;
}

export const LanguageMenu: React.FC<LanguageMenuProps> = ({
  sx,
  alt,
  src
}): JSX.Element => {
  const { setCurrentLanguage } = useTranslations();
  const anchorRef = useRef<HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const onHandleClick = (
    currentLang: string,
    event: Event | React.SyntheticEvent
  ) => {
    setCurrentLanguage(currentLang);
    handleClose(event);
  };

  return (
    <>
      <Box
        component="img"
        ref={anchorRef}
        sx={sx}
        alt={alt}
        src={src}
        onClick={() => handleToggle()}
      />
      <CustomPopper
        open={open}
        anchorEl={anchorRef.current}
        handleClose={handleClose}
      >
        {LANGUAGE_CONFIG &&
          LANGUAGE_CONFIG.languages.map((lang) => (
            <MenuItem
              onClick={(event: Event | React.SyntheticEvent) =>
                onHandleClick(lang.code, event)
              }
              key={lang.code}
            >
              {lang.language}
            </MenuItem>
          ))}
      </CustomPopper>
    </>
  );
};
