import { TypographyVariant } from '@material-ui/core/styles';

export const sidebarWrapper = {
  position: 'fixed',
  top: '0px',
  left: '0px',
  overflowX: 'hidden',
  width: '100%',
  height: '100%',
  backgroundColor: '#ffffff',
  fontWeight: 600,
  fontFamily: 'Public Sans sans-serif',
  color: 'rgb(38, 38, 38)',
  overflowY: 'auto',
  borderRight: '1px dashed #0000001f',
  transition: 'width 0.5s',

  '&::-webkit-scrollbar': {
    width: '0.4em'
  },

  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    outline: '1px solid slategrey'
  },

  hr: {
    borderStyle: 'dashed',
    margin: '0 10px'
  }
};

export const sidebarOpen = {
  top: '0px',
  left: '0px',
  width: '300px'
};

export const sidebarClosed = {
  width: '120px'
};

export const sidebarButtonExpanded = {
  left: '285px'
};

export const sidebarButtonCollapsed = {
  left: '105px'
};

export const tabText = {
  visibility: 'visible',
  opacity: '1',
  transition: 'opacity 0.4s linear, visibility 0.4s linear',
  whiteSpace: 'nowrap'
};

export const tabTextRemoved = {
  visibility: 'hidden',
  opacity: '0',
  transition: 'opacity 0.2s linear, visibility 0.2s linear',
  whiteSpace: 'nowrap'
};

export const sidebarExpandButton = {
  zIndex: 2,
  position: 'fixed',
  top: '15px',
  left: '285px',
  border: '1px dashed #0000001f',
  width: '30px',
  height: '30px',
  minWidth: '30px',
  minHeight: '30px',
  borderRadius: '50%',
  backgroundColor: '#ffffff',
  transition: 'left 0.5s'
};

export const sidebarMainList = {
  padding: '0'
};

export const nestedTabBullet = {
  margin: '0 24px 0 10px',
  width: '6px',
  height: '6px',
  borderRadius: '50%',
  backgroundColor: 'rgb(145, 158, 171)',
  transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
};

export const nestedTabBulletSelected = {
  margin: '0 38px 0 8px',
  width: '10px',
  height: '10px',
  backgroundColor: 'rgb(7, 141, 238)',
  transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
};

export const nestedTab = {
  height: '35px',
  borderRadius: '10px 10px 10px 10px',
  margin: '5px 10px 5px 10px',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  color: 'rgb(99, 115, 129)',

  '&:hover': {
    backgroundColor: 'rgba(145, 158, 171, 0.08)'
  }
};

export const selectedNestedTabStyles = {
  borderRadius: '10px 10px 10px 10px',
  margin: '5px 10px 5px 10px',
  fontWeight: '600',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  color: 'rgb(33, 43, 54)'
};

export const selectedTabStyles = {
  margin: '10px',
  color: 'rgb(7, 141, 238)',
  backgroundColor: 'rgba(7, 141, 238, 0.08)',
  borderRadius: '10px 10px 10px 10px',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

  '&:hover': {
    backgroundColor: 'rgba(7, 141, 238, 0.16)'
  }
};

export const tab = {
  margin: '10px',
  borderRadius: '10px 10px 10px 10px',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

  '&:hover': {
    backgroundColor: 'rgba(145, 158, 171, 0.08)'
  }
};

export const icon = {
  minWidth: '40px'
};

export const selectedIcon = {
  minWidth: '40px',
  color: 'rgb(7, 141, 238)'
};

export const logoStyle = {
  maxWidth: '196px',
  height: '32px',
  margin: '0',
  padding: '0',
  color: 'rgb(7, 141, 238)'
};

export const subTabWrapper = {
  paddingLeft: '30px'
};

export const sidebarLightTheme = {
  typography: {
    fontFamily: 'Regular',
    fontSize: 14
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'body1' as TypographyVariant },
          style: {
            fontFamily: 'Regular'
          }
        },
        {
          props: { variant: 'body2' as TypographyVariant },
          style: {
            fontFamily: 'Medium',
            fontSize: 16
          }
        }
      ]
    }
  }
};
