import {
  KeycloakConfigDefault,
  KeycloakServiceDefault,
  AuthServiceFactory,
  ServiceType
} from 'virava';

export const authConfig: KeycloakConfigDefault = {
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
  baseUrl: process.env.REACT_APP_KEYCLOAK_BASE_URL as string,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string
};

export const viravaDefault = AuthServiceFactory.create(
  ServiceType.DEFAULT
) as KeycloakServiceDefault;
