import React, { useCallback } from 'react';

import { Box, Button } from '@mui/material';

import { FormikErrors, FormikState } from 'formik';

import { Invoice } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import {
  actionButtonsWrapper,
  verifyButtons
} from 'styles/pages/InvoiceVerificationStyle';

interface ActionButtonsProps {
  editSection: string;
  values: Invoice;
  section: string;
  onClickEditSection: (
    section: string,
    setFieldValue?: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<Invoice>>
  ) => void;
  onClickSave?: (values: Invoice) => void;
  handleSubmit: () => void;
  resetForm: (nextState?: Partial<FormikState<Invoice>> | undefined) => void;
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<Invoice>>;
  isEditButtonDisabled?: boolean;
  isVerifyButtonDisabled?: boolean;
}

export const ActionButtons = ({
  editSection,
  values,
  section,
  onClickEditSection,
  onClickSave,
  handleSubmit,
  resetForm,
  setFieldValue,
  isEditButtonDisabled,
  isVerifyButtonDisabled
}: ActionButtonsProps): JSX.Element => {
  const { translate } = useTranslations();

  const onEdit = useCallback(() => {
    onClickEditSection(section);
  }, [onClickEditSection, section]);

  const onVerify = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const onCancel = useCallback(() => {
    resetForm();
    onClickEditSection('', setFieldValue);
  }, [onClickEditSection, resetForm, setFieldValue]);

  const onSave = useCallback(() => {
    if (onClickSave && values) {
      onClickSave(values);
    }
  }, [onClickSave, values]);

  return (
    <Box sx={actionButtonsWrapper}>
      {editSection !== section ? (
        <>
          <Button
            variant="contained"
            type="button"
            onClick={onEdit}
            disabled={isEditButtonDisabled}
          >
            {translate('buttons.edit')}
          </Button>
          <Button
            sx={verifyButtons}
            variant="contained"
            type="button"
            onClick={onVerify}
            disabled={isVerifyButtonDisabled}
          >
            {translate('buttons.verify')}
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="contained"
            type="button"
            color="error"
            onClick={onCancel}
          >
            {translate('buttons.cancel')}
          </Button>
          <Button type="button" variant="contained" onClick={onSave}>
            {translate('buttons.save')}
          </Button>
        </>
      )}
    </Box>
  );
};
