import React, { useMemo } from 'react';

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';

import promptsIcon from 'assets/images/ic_menu_item.svg';

import { usePermissions } from 'context/PermissionsContext';
import { createPromptsTabConditionalsManager } from 'components/Sidebar/SidebarHelper';
import SidebarIcon from 'components/Sidebar/SidebarIcon/SidebarIcon';
import { useTranslations } from 'context/TranslationContext';

import { ROUTE_PROMPT_SETTINGS } from 'utils/routes';
import { TabType } from 'utils/enums/SidebarTabType';

interface PromptsTabProps {
  openTabs: TabType[];
  isSidebarExpanded: boolean;
  selectedTab: TabType | null;
  handleTabClick: (tab: TabType, shouldCloseOthers: boolean) => void;
  handleNestedTabClick: (navigationRoute: string) => void;
}

export const PromptsTab: React.FC<PromptsTabProps> = ({
  isSidebarExpanded,
  selectedTab,
  openTabs,
  handleTabClick,
  handleNestedTabClick
}) => {
  const permissions = usePermissions();
  const tabType = TabType.Prompts;
  const { translate } = useTranslations();

  const layoutConditionalsManager = useMemo(
    () =>
      createPromptsTabConditionalsManager(
        isSidebarExpanded,
        selectedTab,
        openTabs
      ),
    [isSidebarExpanded, selectedTab, openTabs]
  );

  const onTabClick = () => {
    handleTabClick(tabType, false);
    handleNestedTabClick(ROUTE_PROMPT_SETTINGS);
  };

  return (
    <div>
      {permissions.PROMPTS.read && (
        <ListItemButton
          onClick={() => onTabClick()}
          sx={layoutConditionalsManager.tabStyle}
        >
          <ListItemIcon sx={layoutConditionalsManager.iconStyle}>
            <SidebarIcon
              iconSource={promptsIcon}
              shouldBeSelected={
                openTabs.includes(tabType) || selectedTab === tabType
              }
            />
          </ListItemIcon>

          <ListItemText>
            <Typography
              sx={layoutConditionalsManager.textStyle}
              variant={
                layoutConditionalsManager.textTypography as 'body1' | 'body2'
              }
            >
              {translate('pages.prompts')}
            </Typography>
          </ListItemText>
        </ListItemButton>
      )}
    </div>
  );
};

export default PromptsTab;
