import { useCallback } from 'react';
import { CounterPartyService } from 'openapi';

import { CounterPartyCreateUpdate } from 'openapi/models/CounterPartyCreateUpdate';

import { showSuccessToastMessage } from 'components/shared/Toast/Toast';

import { useTranslations } from 'context/TranslationContext';
import interceptRequest from 'utils/helpers/interceptRequest';

export const useCounterPartyController = () => {
  const { translate } = useTranslations();

  const {
    getCounterPartiesByCompanyId,
    getCounterPartyById,
    createCounterParty,
    updateCounterParty,
    deleteCounterPartiesByIds
  } = CounterPartyService;

  const getCounterParties = useCallback(
    (id: number) => interceptRequest(getCounterPartiesByCompanyId, {}, id),
    [getCounterPartiesByCompanyId]
  );

  const getSingleCounterParty = (companyId: number, counterPartyId: number) =>
    interceptRequest(getCounterPartyById, {}, companyId, counterPartyId);

  const createNewCounterParty = (id: number, body: CounterPartyCreateUpdate) =>
    interceptRequest(
      createCounterParty,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyCreated'))
      },
      id,
      body
    );

  const editCounterParty = (
    id: number,
    counterPartyId: number,
    body: CounterPartyCreateUpdate
  ) =>
    interceptRequest(
      updateCounterParty,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyUpdated'))
      },
      id,
      counterPartyId,
      body
    );

  const deleteCounterParties = (id: number, counterPartyIds: number[]) =>
    interceptRequest(
      deleteCounterPartiesByIds,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyDeleted'))
      },
      id,
      counterPartyIds
    );

  return {
    getCounterParties,
    getSingleCounterParty,
    createNewCounterParty,
    editCounterParty,
    deleteCounterParties
  };
};
