export const commonDataGridContainerStyle = {
  '& .MuiDataGrid-columnHeaders': {
    background: '#F9F9F9'
  },
  width: '100%',
  overflow: 'hidden',
  height: '100%'
};

export const noPaginationDataGridContainerStyle = {
  '& .MuiDataGrid-virtualScrollerContent ': {
    minHeight: '450px !important'
  },
  ...commonDataGridContainerStyle
};

export const dataGridContainerStyle = {
  '& .MuiDataGrid-virtualScrollerContent ': {
    height: '64vh !important'
  },
  ...commonDataGridContainerStyle
};

export const gridToolbarContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between'
};

export const defaultGridToolbarStyle = {
  gap: 2
};

export const gridToolbarStyle = {
  display: 'flex',
  p: 1,
  minHeight: '52px'
};

export const gridToolbarButtonsStyle = {
  display: 'flex',
  gap: 2
};
