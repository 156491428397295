/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentType } from '../models/DocumentType';
import type { ExpenseType } from '../models/ExpenseType';
import type { FirebaseResponse } from '../models/FirebaseResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GeneralService {

    /**
     * Get all expense types
     * @returns ExpenseType OK
     * @throws ApiError
     */
    public static getAllExpenseTypes(): CancelablePromise<Array<ExpenseType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/expense_types',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get all document types
     * @returns DocumentType OK
     * @throws ApiError
     */
    public static getAllDocumentTypes(): CancelablePromise<Array<DocumentType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/document_types',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Save the FCM token
     * @param token new token
     * @param email email of user
     * @returns FirebaseResponse OK
     * @throws ApiError
     */
    public static saveFcmToken(
        token: string,
        email: string,
    ): CancelablePromise<FirebaseResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/save-fcm-token',
            query: {
                'token': token,
                'email': email,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
