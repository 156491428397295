import { useEffect, useCallback, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  GridColDef,
  DataGrid,
  GridRowSelectionModel,
  GridActionsCellItem
} from '@mui/x-data-grid';
import { Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { AccountType, Account } from 'openapi';

import { useAccountController } from 'api/controllers/AccountController';

import { GridToolbar } from 'components/shared/GridToolbar/GridToolbar';
import { Modal } from 'components/shared/Modal/Modal';

import { useTranslations } from 'context/TranslationContext';

import { COLUMNS_DEFAULT_OPTIONS } from 'utils/constants/constants';
import { mapAccountForCreate, mapAccountForEdit } from 'utils/mappers/accounts';

import { useModal } from 'hooks/useModal';

import { noPaginationDataGridContainerStyle } from 'styles/components/DataGridStyle';
import { CreateUpdateAccount } from './CreateUpdateAccount';

export const ChartOfAccounts = () => {
  const { id } = useParams();

  const { getAccounts, createNewAccount, editAccount, deleteAccounts } =
    useAccountController();
  const { translate } = useTranslations();
  const { isOpen, closeModal, openModal } = useModal();

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountForEdit, setAccountForEdit] = useState<Account | undefined>();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const fetchAccounts = useCallback(async () => {
    const bsAccounts = await getAccounts(Number(id), AccountType.BS);
    const plAccounts = await getAccounts(Number(id), AccountType.PL);

    setAccounts([...bsAccounts, ...plAccounts]);
  }, [id]);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const handleEditClick = (account: Account) => {
    setAccountForEdit(account);
    openModal();
  };

  const handleDeleteClick = async () => {
    await deleteAccounts(Number(id), selectedRows as number[]);
    fetchAccounts();
  };

  const handleCloseModal = () => {
    setAccountForEdit(undefined);
    closeModal();
  };

  const columns: GridColDef[] = [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'type',
      headerName: translate('labels.type')
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'value',
      headerName: translate('labels.accountNumber')
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'description',
      headerName: translate('labels.name')
    },
    {
      field: 'actions',
      headerName: translate('labels.actions'),
      type: 'actions',
      getActions: ({ row }: { row: Account }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={translate('labels.edit')}
            onClick={() => handleEditClick(row)}
            color="inherit"
          />
        ];
      }
    }
  ];

  const handleSubmitForm = async (values: Account) => {
    try {
      accountForEdit
        ? await editAccount(
            Number(id),
            Number(accountForEdit.id),
            mapAccountForEdit(values)
          )
        : await createNewAccount(Number(id), mapAccountForCreate(values));

      handleCloseModal();
      fetchAccounts();
    } catch (error: any) {
      throw new Error(error);
    }
  };

  // Render values
  const modalTitle = useMemo(
    () =>
      accountForEdit
        ? `${translate('labels.editAccount')}: ${accountForEdit.value} - ${
            accountForEdit.description
          }`
        : translate('labels.addAccount'),
    [translate, accountForEdit]
  );

  return (
    <Paper elevation={8} sx={noPaginationDataGridContainerStyle}>
      <Modal
        headerTitle={modalTitle}
        isOpen={isOpen}
        hide={handleCloseModal}
        maxWidth="sm"
      >
        <CreateUpdateAccount
          initialValues={accountForEdit}
          handleSubmit={handleSubmitForm}
        />
      </Modal>
      <DataGrid
        rows={accounts}
        columns={columns}
        getRowId={(row) => row.id || 0}
        checkboxSelection
        onRowSelectionModelChange={setSelectedRows}
        slots={{
          toolbar: GridToolbar
        }}
        slotProps={{
          toolbar: {
            handleAddClick: openModal,
            handleDeleteClick,
            selectedRows
          }
        }}
        localeText={{
          noRowsLabel: translate('labels.noData')
        }}
      />
    </Paper>
  );
};
