export const cardWrapper = {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  minWidth: '240px',
  gap: '16px',
  '.paper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '80px',
    maxWidth: '240px',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      color: '#1f521f',
      background: '#99f38c6b'
    },
    '.textWrapper': {
      textAlign: 'left',
      padding: '8px 16px'
    },
    '& .MuiButtonBase-root:hover': {
      color: '#ffffff'
    }
  },
  '.createNewCard': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    minHeight: '80px',
    minWidth: '240px',
    cursor: 'pointer',
    '&:hover': {
      color: '#1f521f',
      background: '#99f38c6b'
    }
  }
};
