import {
  sidebarWrapper,
  nestedTab,
  selectedTabStyles,
  tab,
  icon,
  selectedIcon,
  selectedNestedTabStyles,
  nestedTabBullet,
  nestedTabBulletSelected,
  sidebarExpandButton,
  sidebarOpen,
  sidebarClosed,
  sidebarButtonCollapsed,
  sidebarButtonExpanded,
  tabText,
  tabTextRemoved
} from 'styles/components/SidebarStyle';

import { TabType } from 'utils/enums/SidebarTabType';

export const createSidebarConditionalsManager = (isSidebarOpen: boolean) => {
  return {
    sidebarStyle: isSidebarOpen ? {...sidebarWrapper, ...sidebarOpen} : {...sidebarWrapper, ...sidebarClosed},
    expandButtonStyle: isSidebarOpen ? {...sidebarExpandButton, ...sidebarButtonExpanded } : {...sidebarExpandButton,...sidebarButtonCollapsed }
  }
}

export const createInvoicesTabConditionalsManager = (isSidebarOpen: boolean, selectedTab: TabType | null, openTabs: TabType[], selectedNestedTab: string | null) => {
  return {
    tabStyle: selectedTab === TabType.Invoice || openTabs.includes(TabType.Invoice) ? selectedTabStyles : tab,
    iconStyle: selectedTab === TabType.Invoice || openTabs.includes(TabType.Invoice) ? selectedIcon : icon,
    textStyle: isSidebarOpen ? tabText : tabTextRemoved,
    textTypography: selectedTab === TabType.Invoice || openTabs.includes(TabType.Invoice) ? 'body2' : 'body1',
    isDropdownOpen: openTabs.includes(TabType.Invoice)
    && isSidebarOpen,
    allSubTabStyle: selectedNestedTab === 'all' && selectedTab === TabType.Invoice ? { ...nestedTab, ...selectedNestedTabStyles } : { ...nestedTab },
    allSubTabBulletStyle: selectedNestedTab === 'all' && selectedTab === TabType.Invoice ? { ...nestedTabBullet, ...nestedTabBulletSelected } : { ...nestedTabBullet },
    allSubTabTypography: selectedNestedTab === 'all' && selectedTab === TabType.Invoice ? 'body2' : 'body1',
    subTabsStyle: (companyId: number) => selectedNestedTab === String(companyId) && selectedTab === TabType.Invoice ? { ...nestedTab, ...selectedNestedTabStyles } : { ...nestedTab },
    subTabsBulletStyles: (companyId: number) => selectedNestedTab === String(companyId) && selectedTab === TabType.Invoice ? { ...nestedTabBullet, ...nestedTabBulletSelected } : { ...nestedTabBullet },
    subTabsTypography: (companyId: number) => selectedNestedTab === String(companyId) && selectedTab === TabType.Invoice ? 'body2' : 'body1',
  }
}

export const createBankStatementsTabConditionalsManager = (isSidebarOpen: boolean, selectedTab: TabType | null, openTabs: TabType[], selectedNestedTab: string | null) => {
 return {
    tabStyle: selectedTab === TabType.BankStatements || openTabs.includes(TabType.BankStatements) ? selectedTabStyles : tab,
    iconStyle: selectedTab === TabType.BankStatements || openTabs.includes(TabType.BankStatements) ? selectedIcon : icon,
    textStyle: isSidebarOpen ? tabText : tabTextRemoved,
    textTypography: selectedTab === TabType.BankStatements || openTabs.includes(TabType.BankStatements) ? 'body2' : 'body1',
    isDropdownOpen: openTabs.includes(TabType.BankStatements) && isSidebarOpen,
    allSubTabStyle: selectedNestedTab === 'all' && selectedTab === TabType.BankStatements ? { ...nestedTab, ...selectedNestedTabStyles } : { ...nestedTab },
    allSubTabBulletStyle: selectedNestedTab === 'all' && selectedTab === TabType.BankStatements ? { ...nestedTabBullet, ...nestedTabBulletSelected } : { ...nestedTabBullet },
    allSubTabTypography: selectedNestedTab === 'all' && selectedTab === TabType.BankStatements ? 'body2' : 'body1',
    subTabsStyle: (companyId: number) => selectedNestedTab === String(companyId) && selectedTab === TabType.BankStatements ? { ...nestedTab, ...selectedNestedTabStyles } : { ...nestedTab },
    subTabsBulletStyles: (companyId: number) => selectedNestedTab === String(companyId) && selectedTab === TabType.BankStatements ? { ...nestedTabBullet, ...nestedTabBulletSelected } : { ...nestedTabBullet },
    subTabsTypography: (companyId: number) => selectedNestedTab === String(companyId) && selectedTab === TabType.BankStatements ? 'body2' : 'body1',
  }
}

export const createSettingsTabConditionalsManager = (isSidebarOpen: boolean, selectedTab: TabType | null, openTabs: TabType[]) => {
  return {
    tabStyle: selectedTab === TabType.Settings || openTabs.includes(TabType.Settings) || openTabs.includes(TabType.Companies) || selectedTab === TabType.Prompts || selectedTab === TabType.Companies ? selectedTabStyles : tab,
    iconStyle: selectedTab === TabType.Prompts || selectedTab === TabType.Companies || openTabs.includes(TabType.Companies),
    textStyle: isSidebarOpen ? tabText : tabTextRemoved,
    textTypography: selectedTab === TabType.Settings || openTabs.includes(TabType.Settings) ? 'body2' : 'body1',
    expandIconState: openTabs.includes(TabType.Settings) || ((openTabs.includes(TabType.Prompts) || openTabs.includes(TabType.Companies)) && (selectedTab === TabType.Prompts || selectedTab === TabType.Companies || selectedTab === null)) || (openTabs.includes(TabType.Companies) && selectedTab === TabType.Companies),
    isDropdownOpen: (openTabs.includes(TabType.Settings) || openTabs.includes(TabType.Prompts) || openTabs.includes(TabType.Companies)) && isSidebarOpen,
  }
}

export const createPromptsTabConditionalsManager = (isSidebarOpen: boolean, selectedTab: TabType | null, openTabs: TabType[]) => {
  return {
    tabStyle: selectedTab === TabType.Prompts || openTabs.includes(TabType.Prompts) ? selectedTabStyles : tab,
    iconStyle: selectedTab === TabType.Prompts || openTabs.includes(TabType.Prompts) ? selectedIcon : icon,
    textStyle: isSidebarOpen ? tabText : tabTextRemoved,
    textTypography: selectedTab === TabType.Prompts ? 'body2' : 'body1',
  }
}

export const createCompaniesTabConditionalsManager = (isSidebarOpen: boolean, selectedTab: TabType | null, openTabs: TabType[], selectedNestedTab: string | null) => {
  return {
    tabStyle: selectedTab === TabType.Companies || openTabs.includes(TabType.Companies) ? selectedTabStyles : tab,
    iconStyle: selectedTab === TabType.Companies || openTabs.includes(TabType.Companies) ? selectedIcon : icon,
    textStyle: isSidebarOpen ? tabText : tabTextRemoved,
    textTypography: selectedTab === TabType.Companies || openTabs.includes(TabType.Companies) ? 'body2' : 'body1',
    expandIconState: openTabs.includes(TabType.Companies),
    isDropdownOpen: openTabs.includes(TabType.Companies),
    addSubTabStyle: selectedNestedTab === 'add' && selectedTab === TabType.Companies ? { ...nestedTab, ...selectedNestedTabStyles } : { ...nestedTab },
    addSubTabBulletStyle: selectedNestedTab === 'add' && selectedTab === TabType.Companies ? { ...nestedTabBullet, ...nestedTabBulletSelected } : { ...nestedTabBullet },
    addSubTabTypography: selectedNestedTab === 'add' && selectedTab === TabType.Companies ? 'body2' : 'body1',
    subTabsStyle: (companyId: number) => selectedNestedTab === String(companyId) && selectedTab === TabType.Companies ? { ...nestedTab, ...selectedNestedTabStyles } : { ...nestedTab },
    subTabsBulletStyle: (companyId: number) => selectedNestedTab === String(companyId) && selectedTab === TabType.Companies ? { ...nestedTabBullet, ...nestedTabBulletSelected } : { ...nestedTabBullet },
    subTabsTypography: (companyId: number) =>selectedNestedTab === String(companyId) && selectedTab === TabType.Companies ? 'body2' : 'body1',
  }
}
