import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FinancialTransactionPreview } from 'openapi';

import { Paper, Button } from '@mui/material';
import { DataGrid, GridColDef, GridValidRowModel } from '@mui/x-data-grid';

import { useBankStatementController } from 'api/controllers/BankStatementController';

import { Layout } from 'components/Layout/Layout';

import {
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  COLUMNS_DEFAULT_OPTIONS,
  TRANSACTION_NUMBER_FIELD,
  TRANSACTION_COMPANY_NAME,
  TRANSACTION_VALUE_DATE,
  TRANSACTION_REASON,
  TRANSACTION_REASON_TYPE,
  TRANSACTION_COUNTER_PARTY_NAME,
  TRANSACTION_CREDIT,
  TRANSACTION_DEBIT,
  TRANSACTION_IBAN,
  TRANSACTION_INVOICE_NUMBER,
  TRANSACTION_VREDIT_NOTICE,
  TRANSACTION_COMPANY_REGISTRATION_NUMBER,
  TRANSACTION_ACCOUNT_NUMBER
} from 'utils/constants/constants';
import { ROUTE_BANK_STATEMENTS } from 'utils/routes';

import { dataGridContainerStyle } from 'styles/components/DataGridStyle';
import { bankStatementVerificationDataGridStyle } from 'styles/components/BankStatementVerificationDataGridStyle';

import { useDataGridHelpers } from 'hooks/useDataGridHelpers';

import { useTranslations } from 'context/TranslationContext';

export const BankStatementVerification = (): JSX.Element => {
  const { bankStatementId } = useParams<{ bankStatementId: string }>();
  const navigate = useNavigate();

  const [transactionsList, setTransactionsList] = useState<
    FinancialTransactionPreview[]
  >([]);
  const [editedField, setEditedField] = useState<string>('');

  const { previewBankStatements, updateFinancialTransaction } =
    useBankStatementController();

  const { translate } = useTranslations();

  const { getCellValue, getTransactionCellClassName } = useDataGridHelpers();

  const getTransactionsList = useCallback(async () => {
    const allTransactions = await previewBankStatements(
      Number(bankStatementId)
    );
    setTransactionsList(allTransactions);
  }, [bankStatementId]);

  useEffect(() => {
    getTransactionsList();
  }, [getTransactionsList]);

  const goBack = () => navigate(ROUTE_BANK_STATEMENTS);

  const updateTransaction = useCallback(
    async (newRow: GridValidRowModel) => {
      const { id } = newRow;

      if (editedField in newRow) {
        await updateFinancialTransaction(Number(bankStatementId), Number(id), {
          [editedField]: newRow[editedField]
        });
        getTransactionsList();
      } else {
        console.error(`Error: editedField ${editedField} not found in newRow`);
      }
      return newRow;
    },
    [
      editedField,
      updateFinancialTransaction,
      bankStatementId,
      getTransactionsList
    ]
  );

  const columns: GridColDef[] = [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_NUMBER_FIELD,
      headerName: translate('labels.bankStatementNumber'),
      minWidth: 200,
      editable: true,
      valueGetter: getCellValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_COMPANY_NAME,
      headerName: translate('labels.companyName'),
      minWidth: 220,
      editable: true,
      valueGetter: getCellValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_VALUE_DATE,
      headerName: translate('labels.valueDate'),
      minWidth: 150,
      editable: true,
      valueGetter: getCellValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_REASON,
      headerName: translate('labels.reason'),
      minWidth: 250,
      editable: true,
      valueGetter: getCellValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_REASON_TYPE,
      headerName: translate('labels.reasonType'),
      minWidth: 150,
      editable: true,
      valueGetter: getCellValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_COUNTER_PARTY_NAME,
      headerName: translate('labels.counterPartyName'),
      minWidth: 280,
      editable: true,
      valueGetter: getCellValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_CREDIT,
      headerName: translate('labels.credit'),
      minWidth: 120,
      editable: true,
      valueGetter: getCellValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_DEBIT,
      headerName: translate('labels.debit'),
      minWidth: 120,
      editable: true,
      valueGetter: getCellValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_IBAN,
      headerName: translate('labels.iban'),
      minWidth: 280,
      editable: true,
      valueGetter: getCellValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_INVOICE_NUMBER,
      headerName: translate('labels.invoiceNumber'),
      minWidth: 200,
      editable: true,
      valueGetter: getCellValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_VREDIT_NOTICE,
      headerName: translate('labels.creditNotice'),
      minWidth: 200,
      editable: true,
      valueGetter: getCellValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_COMPANY_REGISTRATION_NUMBER,
      headerName: translate('labels.companyRegistrationNumber'),
      minWidth: 250,
      editable: true,
      valueGetter: getCellValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: TRANSACTION_ACCOUNT_NUMBER,
      headerName: translate('labels.accountNumber'),
      minWidth: 200,
      editable: true,
      valueGetter: getCellValue
    }
  ];

  return (
    <Layout pageTitle={translate('labels.transactions')}>
      <Paper elevation={8} sx={dataGridContainerStyle}>
        <DataGrid
          onCellEditStart={(params) => setEditedField(params.field)}
          rows={transactionsList}
          columns={columns}
          pageSizeOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          sx={bankStatementVerificationDataGridStyle}
          checkboxSelection
          localeText={{
            noRowsLabel: translate('labels.noData')
          }}
          getCellClassName={(params) => getTransactionCellClassName(params)}
          processRowUpdate={updateTransaction}
        />
      </Paper>

      <Button variant="contained" onClick={goBack} sx={{ mt: 2 }}>
        {translate('buttons.back')}
      </Button>
    </Layout>
  );
};
