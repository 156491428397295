import { getToken } from '@firebase/messaging';

import { GeneralService, UserService } from 'openapi';

import { errorResultHandler } from 'utils/helpers/errorResultHandler';

export const UserController = () => {
  const { saveFcmToken: saveFcmTokenApi } = GeneralService;
  const { login, register } = UserService;

  const saveFcmToken = async (fcmToken: string, email: string) => {
    try {
      const response = await saveFcmTokenApi(fcmToken, email);

      return response;
    } catch (error: any) {
      return error;
    }
  };

  const requestNotificationPermission = async (
    email: string,
    messaging: any
  ) => {
    try {
      const permission = await Notification.requestPermission();

      if (permission === 'granted') {
        const token = await getToken(messaging);
        await saveFcmToken(token, email);
      } else {
        console.error('Notification permission not granted');
      }
    } catch (error) {
      console.error('Error getting FCM token:', error);
    }
  };

  const userLogin = async (messaging: any) => {
    try {
      const response = await login();

      await requestNotificationPermission(
        response.data!.email as string,
        messaging
      );

      return response.data;
    } catch (error: any) {
      errorResultHandler(error.body.error.status, messaging);

      return error;
    }
  };

  const userRegister = async (messaging: any) => {
    try {
      const response = await register();

      await requestNotificationPermission(
        response.data!.email as string,
        messaging
      );

      return response;
    } catch (error: any) {
      return error;
    }
  };

  return {
    userLogin,
    userRegister,
    saveFcmToken
  };
};
