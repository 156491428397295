import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { Company, Prompt } from 'openapi';

import { usePromptController } from 'api/controllers/PromptController';
import { useCompanyController } from 'api/controllers/CompanyController';

import { useTranslations } from 'context/TranslationContext';

import {
  DETAILS,
  COUNTER_PARTIES,
  CHART_OF_ACCOUNTS
} from 'utils/constants/constants';

import { tabsContainerStyle } from 'styles/components/TabsSyle';

import { ITabMapping, TabId } from 'utils/interfaces/Tab';

import { Layout } from 'components/Layout/Layout';
import { CompanyDetails } from 'components/CompanySettings/CompanyDetails';
import { CounterParties } from 'components/CompanySettings/CounterParties';
import { ChartOfAccounts } from 'components/CompanySettings/ChartOfAccounts';

export const CompanySettings: React.FC = (): JSX.Element | null => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { translate } = useTranslations();
  const { getPrompts } = usePromptController();
  const { getCompanyDetailsById } = useCompanyController();

  const [companyDetails, setCompanyDetails] = useState<Company | null>(null);
  const [prompts, setPrompts] = useState<Prompt[]>([]);

  // Tabs
  const TAB_MAPPING: ITabMapping = useMemo(
    () => ({
      [TabId.Details]: {
        id: TabId.Details,
        label: DETAILS,
        component: (
          <CompanyDetails
            companyDetails={companyDetails as Company}
            prompts={prompts}
          />
        )
      },
      [TabId.ChartOfAccounts]: {
        id: TabId.ChartOfAccounts,
        label: CHART_OF_ACCOUNTS,
        component: <ChartOfAccounts />
      },
      [TabId.CounterParties]: {
        id: TabId.CounterParties,
        label: COUNTER_PARTIES,
        component: <CounterParties />
      }
    }),
    [companyDetails, prompts]
  );

  const hash = location.hash.slice(1);

  const selectedTab =
    Object.values(TAB_MAPPING).find((tab) => tab.label === hash) ||
    TAB_MAPPING[0];

  const [activeTab, setActiveTab] = useState<TabId>(selectedTab.id);

  useEffect(() => {
    const fetchDetails = async () => {
      const compDetails = await getCompanyDetailsById(Number(id));
      setCompanyDetails(compDetails);
      const allPrompts = await getPrompts();
      setPrompts(allPrompts);
    };

    if (id) {
      fetchDetails();
    }
  }, [location.pathname]);

  const handleChangeTab = (_: React.ChangeEvent<{}>, newValue: TabId) => {
    setActiveTab(newValue);
    navigate(`#${TAB_MAPPING[newValue].label}`);
  };

  // Page title
  const pageTitle = useMemo(
    () => `${translate('pages.companySettings')}: ${companyDetails?.name}`,
    [companyDetails?.name, translate]
  );

  return (
    companyDetails && (
      <Layout pageTitle={pageTitle}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          sx={tabsContainerStyle}
        >
          <Tab label={translate('buttons.companyDetails')} />
          <Tab label={translate('buttons.chartOfAccounts')} />
          <Tab label={translate('buttons.counterParties')} />
        </Tabs>

        {TAB_MAPPING[activeTab].component}
      </Layout>
    )
  );
};
