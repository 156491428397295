import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import { NotFound } from 'pages/NotFound/NotFound';
import { Loader } from 'components/shared/Loader/Loader';
import { InvoiceVerification } from 'pages/InvoiceVerification/InvoiceVerification';
import { Companies } from 'pages/Settings/Companies/Companies';
import { Prompts } from 'pages/Settings/Prompt/Prompts';
import { PromptSettings } from 'pages/Settings/Prompt/PromptSettings';
import { AddPrompt } from 'pages/Settings/Prompt/AddPrompt';
import { CompanySettings } from 'pages/Settings/Companies/CompanySettings';
import { AddCompany } from 'pages/Settings/Companies/AddCompany';
import { DocumentsUpload } from 'pages/DocumentsUpload/DocumentsUpload';
import { CompanyDocumentsView } from 'pages/CompanyDocumentsView/CompanyDocumentsView';
import { BankStatementVerification } from 'pages/BankStatementVerification/BankStatementVerification';

import {
  ROUTE_INVOICES,
  ROUTE_NOT_FOUND,
  ROUTE_HOME,
  ROUTE_UPLOAD_INVOICES,
  ROUTE_INVOICE_VERIFICATION,
  ROUTE_PROMPT_SETTINGS,
  ROUTE_COMPANIES_SETTINGS,
  ROUTE_PROMPT_SETTINGS_ID,
  ROUTE_ADD_PROMPT,
  ROUTE_COMPANY_DETAILS,
  ROUTE_ADD_COMPANY,
  ROUTE_BANK_STATEMENTS,
  ROUTE_BANK_STATEMENT_VERIFICATION,
  ROUTE_UPLOAD_BANK_STATEMENTS
} from 'utils/routes';
import { DocumentsType } from 'utils/enums/DocumentsType';
import { ThemeProvider, createTheme } from '@mui/material';
import * as locales from '@mui/material/locale';

import lightTheme from 'styles/theme/light-theme';

import { useTranslations } from 'context/TranslationContext';

import { LANGUAGE_TO_LOCALE } from 'utils/constants/constants';

import { ProtectedRoute } from '../Navigation/ProtectedRoute';

export const AppRoutes = () => {
  const { currentLanguage } = useTranslations();

  const themeWithLocale = createTheme(
    lightTheme,
    locales[LANGUAGE_TO_LOCALE[currentLanguage] as keyof typeof locales]
  );

  return (
    <ThemeProvider theme={themeWithLocale}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path={ROUTE_HOME}
            element={
              <ProtectedRoute path={ROUTE_INVOICES}>
                <CompanyDocumentsView documentsType={DocumentsType.INVOICES} />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_INVOICES}
            element={
              <ProtectedRoute path={ROUTE_INVOICES}>
                <CompanyDocumentsView documentsType={DocumentsType.INVOICES} />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_UPLOAD_INVOICES}
            element={
              <ProtectedRoute path={ROUTE_INVOICES}>
                <DocumentsUpload documentsType={DocumentsType.INVOICES} />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_BANK_STATEMENTS}
            element={
              <ProtectedRoute path={ROUTE_BANK_STATEMENTS}>
                <CompanyDocumentsView
                  documentsType={DocumentsType.BANK_STATEMENTS}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_BANK_STATEMENT_VERIFICATION}
            element={
              <ProtectedRoute path={ROUTE_BANK_STATEMENT_VERIFICATION}>
                <BankStatementVerification />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_UPLOAD_BANK_STATEMENTS}
            element={
              <ProtectedRoute path={ROUTE_UPLOAD_BANK_STATEMENTS}>
                <DocumentsUpload
                  documentsType={DocumentsType.BANK_STATEMENTS}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_INVOICE_VERIFICATION}
            element={
              <ProtectedRoute path={ROUTE_INVOICE_VERIFICATION}>
                <InvoiceVerification />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTE_PROMPT_SETTINGS}
            element={
              <ProtectedRoute path={ROUTE_PROMPT_SETTINGS}>
                <Prompts />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_PROMPT_SETTINGS_ID}
            element={
              <ProtectedRoute path={ROUTE_PROMPT_SETTINGS_ID}>
                <PromptSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_ADD_PROMPT}
            element={
              <ProtectedRoute path={ROUTE_ADD_PROMPT}>
                <AddPrompt />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTE_COMPANIES_SETTINGS}
            element={
              <ProtectedRoute path={ROUTE_COMPANIES_SETTINGS}>
                <Companies />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTE_COMPANY_DETAILS}
            element={
              <ProtectedRoute path={ROUTE_COMPANY_DETAILS}>
                <CompanySettings />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_ADD_COMPANY}
            element={
              <ProtectedRoute path={ROUTE_ADD_COMPANY}>
                <AddCompany />
              </ProtectedRoute>
            }
          />

          <Route path={ROUTE_NOT_FOUND} element={<NotFound />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
};
