import React, { ChangeEvent, useState, useEffect, useCallback } from 'react';
import { ErrorMessage, useField } from 'formik';
import TextField from '@mui/material/TextField';

interface CustomFieldProps {
  readonly label: string;
  readonly name: string;
  readonly placeholder: string;
  readonly required?: boolean;
  readonly disabled?: boolean;
  readonly sx?: any;
  readonly title?: string;
}

export const DecimalField: React.FC<CustomFieldProps> = ({
  label,
  placeholder,
  sx,
  title,
  required,
  disabled,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [localValue, setLocalValue] = useState(field.value);

  function formatValue(value: any) {
    return value !== '' ? Number(value).toFixed(2) : '';
  }

  const onBlur = useCallback(
    (e: any) => {
      let { value } = e.target;
      value = formatValue(value);

      helpers.setValue(value);
    },
    [helpers]
  );

  useEffect(() => {
    if (localValue === '') {
      onBlur({ target: { value: field.value } });
    }
  }, [field.value, localValue, onBlur]);

  useEffect(() => {
    setLocalValue(field.value);
  }, [field.value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let { value } = e.target;

    const numberOfDecimalPoints = (value.match(/\./g) || []).length;

    if (numberOfDecimalPoints <= 1) {
      value = value.replace(',', '.');
      value = value.replace(/[^0-9.]/g, '');
      helpers.setValue(value);

      setLocalValue(value);
    }
  };

  return (
    <TextField
      {...field}
      value={localValue || '0.00'}
      onChange={handleChange}
      onBlur={onBlur}
      label={label}
      placeholder={placeholder}
      sx={sx}
      required={required}
      title={title}
      error={meta.touched && Boolean(meta.error)}
      disabled={disabled}
      helperText={<ErrorMessage name={props.name} />}
    />
  );
};
