/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountListResponse } from '../models/AccountListResponse';
import type { CounterPartyDropDownListResponse } from '../models/CounterPartyDropDownListResponse';
import type { CurrencyDropDownListResponse } from '../models/CurrencyDropDownListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DropDownHelperService {

    /**
     * This method returns the list of counter parties by company id for a drop-down selection
     * @param id The company id
     * @returns CounterPartyDropDownListResponse OK
     * @throws ApiError
     */
    public static getCounterPartiesByCompanyIdForDropDown(
        id: number,
    ): CancelablePromise<CounterPartyDropDownListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/dropDown/counterParties',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get all PL accounts by company id for invoice verification screen drop down
     * @param id The company id
     * @returns AccountListResponse OK
     * @throws ApiError
     */
    public static getAllPlAccountsByCompanyIdDropDown(
        id: number,
    ): CancelablePromise<AccountListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/dropDown/plAccounts',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of currencies for a drop-down selection in invoice screen
     * @returns CurrencyDropDownListResponse OK
     * @throws ApiError
     */
    public static getAllCurrenciesForDropDown(): CancelablePromise<CurrencyDropDownListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dropDown/currencies',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
