import React from 'react';

import InputMask from 'react-input-mask';

import {
  Field,
  ErrorMessage,
  FieldProps,
  FormikTouched,
  FormikErrors
} from 'formik';

import { TextField } from '@mui/material';

import { Invoice } from 'openapi';

interface DateMaskFieldProps {
  name: keyof Invoice;
  label: string;
  touched: FormikTouched<Invoice>;
  errors: FormikErrors<Invoice>;
  sx?: {};
  disabled?: boolean;
}

export const DateMaskField: React.FC<DateMaskFieldProps> = ({
  name,
  label,
  touched,
  errors,
  sx,
  disabled
}): JSX.Element => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <InputMask
        mask="99.99.9999"
        maskChar="_"
        value={field.value}
        onChange={(e) => {
          const { value } = e.target;
          form.setFieldValue(name, value);
        }}
        disabled={disabled}
      >
        {/* @ts-ignore Conflicting information about how InputMask works.  */}
        {(inputProps: any) => (
          <TextField
            {...inputProps}
            label={label}
            placeholder={label}
            sx={sx}
            error={touched[name] && !!errors[name]}
            helperText={<ErrorMessage name={name} />}
            required
            disabled={disabled}
          />
        )}
      </InputMask>
    )}
  </Field>
);
