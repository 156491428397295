/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountCreate } from '../models/AccountCreate';
import type { AccountListResponse } from '../models/AccountListResponse';
import type { AccountPatch } from '../models/AccountPatch';
import type { AccountResponse } from '../models/AccountResponse';
import type { AccountType } from '../models/AccountType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountService {

    /**
     * This method imports accounts master data into the database from an excel file
     * @param id The company id
     * @param formData The Excel file to be imported
     * @returns AccountListResponse OK
     * @throws ApiError
     */
    public static importAccountsMasterDataFromExcel(
        id: number,
        formData: {
            files?: Blob;
        },
    ): CancelablePromise<AccountListResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/accounts/import-data',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get all accounts by company id
     * @param id The company id
     * @param accountType The account type
     * @returns AccountListResponse OK
     * @throws ApiError
     */
    public static getAllAccountsByCompanyId(
        id: number,
        accountType: AccountType,
    ): CancelablePromise<AccountListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/accounts',
            path: {
                'id': id,
            },
            query: {
                'accountType': accountType,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Create a new account for company
     * @param id The company id
     * @param requestBody The account object
     * @returns AccountResponse OK
     * @throws ApiError
     */
    public static createAccount(
        id: number,
        requestBody: AccountCreate,
    ): CancelablePromise<AccountResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/accounts',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Delete multiple accounts for company
     * @param id The account id
     * @param accountIds List of account ids to be deleted
     * @returns void
     * @throws ApiError
     */
    public static deleteAccountsByIds(
        id: number,
        accountIds: Array<number>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/companies/{id}/accounts',
            path: {
                'id': id,
            },
            query: {
                'accountIds': accountIds,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Patch account for company
     * @param id The company id
     * @param accountId The account id
     * @param requestBody The account object
     * @returns AccountResponse OK
     * @throws ApiError
     */
    public static patchAccount(
        id: number,
        accountId: number,
        requestBody: AccountPatch,
    ): CancelablePromise<AccountResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/companies/{id}/accounts/{accountId}',
            path: {
                'id': id,
                'accountId': accountId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
