import { useCallback } from 'react';

import { DropDownHelperService, GeneralService } from 'openapi';

import interceptRequest from 'utils/helpers/interceptRequest';

export const useAdditionalFieldsController = () => {
  const { getAllExpenseTypes, getAllDocumentTypes } = GeneralService;

  const {
    getCounterPartiesByCompanyIdForDropDown,
    getAllPlAccountsByCompanyIdDropDown,
    getAllCurrenciesForDropDown
  } = DropDownHelperService;

  const getExpenseTypes = useCallback(
    () => interceptRequest(getAllExpenseTypes, {}),
    [getAllExpenseTypes]
  );

  const getDocumentTypes = useCallback(
    () => interceptRequest(getAllDocumentTypes, {}),
    [getAllDocumentTypes]
  );

  const getCounterPartyValues = useCallback(
    (id: number) =>
      interceptRequest(getCounterPartiesByCompanyIdForDropDown, {}, id),
    [getCounterPartiesByCompanyIdForDropDown]
  );

  const getAllPlAccounts = useCallback(
    (id: number) =>
      interceptRequest(getAllPlAccountsByCompanyIdDropDown, {}, id),
    [getAllPlAccountsByCompanyIdDropDown]
  );

  const getCurrencies = useCallback(
    () => interceptRequest(getAllCurrenciesForDropDown, {}),
    [getAllCurrenciesForDropDown]
  );

  return {
    getExpenseTypes,
    getDocumentTypes,
    getCounterPartyValues,
    getAllPlAccounts,
    getCurrencies
  };
};
