import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { CreateUpdateCompanyForm } from 'components/forms/CreateUpdateCompanyForm';
import { ROUTE_COMPANIES_SETTINGS } from 'utils/routes';
import { mediumVerificationFieldWidth } from 'styles/pages/InvoiceVerificationStyle';

import { useCompanyController } from 'api/controllers/CompanyController';
import { Company, Prompt } from 'openapi';

interface CompanyDetailsProps {
  companyDetails: Company;
  prompts: Prompt[];
}

export const CompanyDetails = ({
  companyDetails,
  prompts
}: CompanyDetailsProps): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { deleteCompany } = useCompanyController();

  const onDelete = async () => {
    await deleteCompany(Number(id));
    navigate(ROUTE_COMPANIES_SETTINGS);
  };

  return (
    <Box>
      {companyDetails && prompts && prompts.length > 0 && (
        <Box sx={mediumVerificationFieldWidth}>
          <CreateUpdateCompanyForm
            companyDetails={companyDetails}
            prompts={prompts}
            onDelete={onDelete}
          />
        </Box>
      )}
    </Box>
  );
};
