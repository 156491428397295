export enum TabId {
  Details,
  ChartOfAccounts,
  CounterParties
}

interface ITab {
  id: TabId;
  label: string;
  component: JSX.Element;
}

export type ITabMapping = Record<TabId, ITab>;
