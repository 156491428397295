import React, { useContext, ReactNode, useState, useMemo } from 'react';

import { Company } from 'openapi';

type Companies = {
  companiesList: Company[];
  updateCompaniesList: (company: Company) => void;
  setCompaniesList: (companies: Company[]) => void;
};

const CompaniesContext = React.createContext<Companies>({
  companiesList: [],
  updateCompaniesList: () => [],
  setCompaniesList: () => []
});

interface CompaniesProviderProps {
  readonly children: ReactNode;
}

export const CompaniesProvider = ({ children }: CompaniesProviderProps) => {
  const [companiesList, setCompaniesList] = useState<Company[]>([]);

  const updateCompaniesList = (newCompany: Company) => {
    const existingComapany = companiesList.find(
      (el) => el.vatNumber === newCompany.vatNumber
    );

    if (existingComapany) {
      setCompaniesList(
        companiesList.map((company: Company) =>
          company.vatNumber === newCompany.vatNumber
            ? { ...newCompany }
            : company
        )
      );
    } else {
      setCompaniesList([...companiesList, newCompany]);
    }
  };

  const context = useMemo(
    () => ({
      companiesList,
      updateCompaniesList,
      setCompaniesList
    }),
    [companiesList]
  );

  return (
    <CompaniesContext.Provider value={context}>
      {children}
    </CompaniesContext.Provider>
  );
};

export const useCompanies = (): Companies => {
  return useContext(CompaniesContext);
};
