export const decodeExcelBase64 = (base64: string) => {
  // Decode base64
  const binary = atob(base64);

  // Convert binary string to ArrayBuffer
  const { length } = binary;
  const buffer = new ArrayBuffer(length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < length; i += 1) {
    view[i] = binary.charCodeAt(i);
  }

  return buffer;
};
