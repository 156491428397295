/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CounterPartyCreateUpdate } from '../models/CounterPartyCreateUpdate';
import type { CounterPartyListResponse } from '../models/CounterPartyListResponse';
import type { CounterPartyResponse } from '../models/CounterPartyResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CounterPartyService {

    /**
     * This method imports counter parties master data into the database from an excel file
     * @param id The company id
     * @param formData The Excel file to be imported
     * @returns CounterPartyListResponse OK
     * @throws ApiError
     */
    public static importCounterPartiesMasterDataFromExcel(
        id: number,
        formData: {
            files?: Blob;
        },
    ): CancelablePromise<CounterPartyListResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/counterParties/import-data',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of counter parties by company id
     * @param id The company id
     * @returns CounterPartyListResponse OK
     * @throws ApiError
     */
    public static getCounterPartiesByCompanyId(
        id: number,
    ): CancelablePromise<CounterPartyListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/counterParties',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Create a new counter party for company
     * @param id The company id
     * @param requestBody The counter party object
     * @returns CounterPartyResponse OK
     * @throws ApiError
     */
    public static createCounterParty(
        id: number,
        requestBody: CounterPartyCreateUpdate,
    ): CancelablePromise<CounterPartyResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/counterParties',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Delete multiple counter parties for company
     * @param id The company id
     * @param counterPartyIds List of counter party ids to be deleted
     * @returns void
     * @throws ApiError
     */
    public static deleteCounterPartiesByIds(
        id: number,
        counterPartyIds: Array<number>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/companies/{id}/counterParties',
            path: {
                'id': id,
            },
            query: {
                'counterPartyIds': counterPartyIds,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get single counter party for company
     * @param id The company id
     * @param counterPartyId The counter party id
     * @returns CounterPartyResponse OK
     * @throws ApiError
     */
    public static getCounterPartyById(
        id: number,
        counterPartyId: number,
    ): CancelablePromise<CounterPartyResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/counterParties/{counterPartyId}',
            path: {
                'id': id,
                'counterPartyId': counterPartyId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update counter party for company
     * @param id The company id
     * @param counterPartyId The counter party id
     * @param requestBody The counter party object
     * @returns CounterPartyResponse OK
     * @throws ApiError
     */
    public static updateCounterParty(
        id: number,
        counterPartyId: number,
        requestBody: CounterPartyCreateUpdate,
    ): CancelablePromise<CounterPartyResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/companies/{id}/counterParties/{counterPartyId}',
            path: {
                'id': id,
                'counterPartyId': counterPartyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
