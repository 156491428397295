import React, { useState } from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { rotatePlugin } from '@react-pdf-viewer/rotate';

import { Button, Paper, Box, Tooltip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridCellParams
} from '@mui/x-data-grid';

import { useNavigate } from 'react-router-dom';

import { BankStatement } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import {
  ACTIONS,
  BANK_STATEMENT_BANK_NAME,
  BANK_STATEMENT_COMPANY,
  BANK_STATEMENT_NUMBER_FIELD,
  BANK_STATEMENT_PARAMETER,
  BANK_STATEMENT_PERIOD,
  CHECK,
  COLUMNS_DEFAULT_OPTIONS,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  PDF_JS_WORKER_URL
} from 'utils/constants/constants';
import { ROUTE_BANK_STATEMENT_VERIFICATION } from 'utils/routes';
import { exportCsvFile } from 'utils/helpers/exportCsvFile';
import { base64toBlob } from 'utils/helpers/base64toBlob';

import { dataGridContainerStyle } from 'styles/components/DataGridStyle';
import { invoicesDataGrid } from 'styles/components/InvoicesDataGridStyle';
import { zoomButtonsWrapper } from 'styles/pages/InvoiceVerificationStyle';

import { GridToolbar } from 'components/shared/GridToolbar/GridToolbar';
import { PdfViewerWithWatermark } from 'components/PdfViewerWithWatermark/PdfViewerWithWatermark';

import { useBankStatementController } from 'api/controllers/BankStatementController';

import { useDataGridHelpers } from 'hooks/useDataGridHelpers';

export const BankStatementDataGrid = ({
  bankStatements,
  updateBankStatements,
  setShowVisualization
}: {
  bankStatements: BankStatement[];
  updateBankStatements: () => Promise<void>;
  setShowVisualization?: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const zoomPluginInstance = zoomPlugin();
  const rotatePluginInstance = rotatePlugin();
  const navigate = useNavigate();

  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;

  const { exportBankStatements, getBankStatementFile, deleteBankStatements } =
    useBankStatementController();
  const { getNameValue } = useDataGridHelpers();
  const { translate } = useTranslations();

  const [pdfFileUrl, setPdfFileUrl] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [currentBankStatement, setCurrentBankStatement] =
    useState<BankStatement>();

  const onDelete = async () => {
    await deleteBankStatements(selectedRows as number[]);
    updateBankStatements();
  };

  const onExport = async (
    event: React.MouseEvent<HTMLButtonElement>,
    params: GridRenderCellParams
  ) => {
    event.stopPropagation();
    const rowData: BankStatement = params.row;
    const exportedFile = await exportBankStatements(
      Number(rowData.company?.id),
      Number(rowData.id)
    );

    if (exportedFile) {
      exportCsvFile(exportedFile.fileContent, exportedFile.fileName);
    }
  };

  const onViewDocument = async (
    event: React.MouseEvent<HTMLButtonElement>,
    params: GridRenderCellParams
  ) => {
    event.stopPropagation();
    setCurrentBankStatement(params.row);
    const bankStatementFileUrl = await getBankStatementFile(
      Number(params.row.id)
    );
    const pdfUrl = bankStatementFileUrl
      ? URL.createObjectURL(base64toBlob(bankStatementFileUrl))
      : '';
    setPdfFileUrl(pdfUrl);

    if (setShowVisualization) {
      setShowVisualization(true);
    }
  };

  const handleCellClick = (params: GridCellParams) => {
    if (params.field !== CHECK) {
      const route = ROUTE_BANK_STATEMENT_VERIFICATION.replace(
        BANK_STATEMENT_PARAMETER,
        params.row.id.toString()
      );
      navigate(route);
    }
  };

  const columns: GridColDef[] = [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: BANK_STATEMENT_COMPANY,
      headerName: translate('labels.shortCompanyName'),
      width: 150,
      valueGetter: getNameValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: BANK_STATEMENT_PERIOD,
      headerName: translate('labels.monthYear'),
      minWidth: 250
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: BANK_STATEMENT_BANK_NAME,
      headerName: translate('labels.originBank'),
      minWidth: 250
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: BANK_STATEMENT_NUMBER_FIELD,
      headerName: translate('labels.bankStatementNumber'),
      minWidth: 250
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: ACTIONS,
      headerName: translate('labels.actions'),
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <Tooltip title={translate('labels.export')}>
              <Button
                variant="text"
                onClick={(event) => onExport(event, params)}
              >
                <FileDownloadIcon />
              </Button>
            </Tooltip>

            <Tooltip title={translate('labels.view')}>
              <Button
                variant="text"
                onClick={(event) => onViewDocument(event, params)}
              >
                <VisibilityIcon />
              </Button>
            </Tooltip>
          </>
        );
      }
    }
  ];

  return !pdfFileUrl ? (
    <Paper elevation={8} sx={dataGridContainerStyle}>
      <DataGrid
        rows={bankStatements}
        columns={columns}
        getRowId={(row) => row.id || row.name || row.company.name}
        pageSizeOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
        sx={invoicesDataGrid}
        checkboxSelection
        onRowSelectionModelChange={setSelectedRows}
        slots={{
          toolbar: GridToolbar
        }}
        slotProps={{
          toolbar: {
            handleDeleteClick: onDelete,
            selectedRows
          }
        }}
        localeText={{
          noRowsLabel: translate('labels.noData')
        }}
        onCellClick={handleCellClick}
      />
    </Paper>
  ) : (
    <>
      <Worker workerUrl={PDF_JS_WORKER_URL || ''}>
        <Box>
          <PdfViewerWithWatermark
            fileUrl={pdfFileUrl}
            plugins={[zoomPluginInstance, rotatePluginInstance]}
            watermarkText={currentBankStatement?.company?.name as string}
            defaultScale={1}
          />
          <Box sx={zoomButtonsWrapper}>
            <RotateBackwardButton />
            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
            <RotateForwardButton />
          </Box>
        </Box>
      </Worker>

      <Button variant="contained" onClick={() => setPdfFileUrl('')}>
        {translate('buttons.back')}
      </Button>
    </>
  );
};
