export const ROUTE_HOME = '/';

export const ROUTE_NOT_FOUND = '/not-found';

export const ROUTE_INVOICES = '/invoices';
export const ROUTE_INVOICE_VERIFICATION =
  '/invoice-verification/:companyId/:stage/:id';

export const ROUTE_UPLOAD_INVOICES = '/companies/:id/invoice';

export const ROUTE_PROMPT_SETTINGS = '/settings/prompt-settings';
export const ROUTE_PROMPT_SETTINGS_ID = '/settings/prompt-settings/:id';
export const ROUTE_ADD_PROMPT = '/settings/prompt-settings/add';

export const ROUTE_COMPANIES_SETTINGS = '/settings/companies-settings';
export const ROUTE_COMPANY_DETAILS = '/settings/companies-setting/:id';
export const ROUTE_ADD_COMPANY = '/settings/companies-setting/add';

export const ROUTE_BANK_STATEMENTS = '/bank-statements';
export const ROUTE_BANK_STATEMENT_VERIFICATION =
  '/bank-statement-verification/:bankStatementId/';
export const ROUTE_UPLOAD_BANK_STATEMENTS = '/companies/:id/bank-statements';
