import { useEffect, useState } from 'react';
import { CssBaseline, Box } from '@mui/material';
import { OpenAPI } from 'openapi';
import { createBrowserHistory } from 'history';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

import { viravaDefault } from 'config/authConfig';

import { UserController } from 'api/controllers/UserController';

import { AppRoutes } from 'components/AppRoutes/AppRoutes';
import { Header } from 'components/Header/Header';
import { MessageHandler } from 'components/MessageHandler/MessageHandler';

import { TranslationsProvider } from 'context/TranslationContext';
import { CompaniesProvider } from 'context/CompanyContext';
import { NotificationProvider } from 'context/NotificationContext';
import PermissionsProvider from 'context/PermissionsContext';

import { isAuthenticated } from 'utils/helpers/authHelper';
import { PermissionsObject } from 'utils/interfaces/Permissions';
import { mapPermissions } from 'utils/mappers/permissions';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  appStyle,
  displayWrapper,
  expandedSidebarGrid,
  retractedSidebarGrid,
  routesWrapper
} from 'styles/components/AppStyle';
import { Sidebar } from 'components/Sidebar/Sidebar';

OpenAPI.BASE = process.env.REACT_APP_OPENAPI_BASE_URL as string;
OpenAPI.TOKEN = async () => {
  const token = localStorage.getItem('access_token');
  return token !== null ? token : '';
};

export const customHistory = createBrowserHistory();

// TODO: Export this variables to .env file
const firebaseConfig = {
  apiKey: 'AIzaSyDHJzm5gAp-szagX1q1wP_pqxFpgsaRWpI',
  authDomain: 'project-x-6a1f3.firebaseapp.com',
  projectId: 'project-x-6a1f3',
  storageBucket: 'project-x-6a1f3.appspot.com',
  messagingSenderId: '332651374379',
  appId: '1:332651374379:web:1580a192e9d5121073876e',
  measurementId: 'G-MSTFFNN19J'
};

export const App: React.FC = () => {
  const [permissions, setPermissions] = useState<PermissionsObject | null>(
    null
  );
  const [isSidebarExpanded, setSidebarExpanded] = useState<boolean>(true);

  const { userLogin } = UserController();

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  useEffect(() => {
    const getUserInfo = async () => {
      const userData = await userLogin(messaging);

      if (userData && userData.permissions) {
        setPermissions(mapPermissions(userData.permissions));
      }

      if (!isAuthenticated()) {
        viravaDefault.logout();
      }
    };

    getUserInfo();
  }, []);

  return (
    permissions && (
      <>
        <CssBaseline />
        <PermissionsProvider permissions={permissions}>
          <TranslationsProvider>
            <CompaniesProvider>
              <NotificationProvider>
                <Box sx={appStyle}>
                  <ToastContainer />
                  <Box
                    sx={
                      isSidebarExpanded
                        ? { ...displayWrapper, ...expandedSidebarGrid }
                        : { ...displayWrapper, ...retractedSidebarGrid }
                    }
                  >
                    <Box>
                      <Sidebar
                        setSidebarExpanded={setSidebarExpanded}
                        isSidebarExpanded={isSidebarExpanded}
                      />
                    </Box>
                    <Box>
                      <Header />
                      <Box sx={routesWrapper}>
                        <AppRoutes />
                      </Box>
                    </Box>
                    <MessageHandler messaging={messaging} />
                  </Box>
                  <Box id="modal" />
                </Box>
              </NotificationProvider>
            </CompaniesProvider>
          </TranslationsProvider>
        </PermissionsProvider>
      </>
    )
  );
};
