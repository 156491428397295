import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Box,
  Button,
  Typography,
  InputLabel,
  Tooltip,
  Switch
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridTreeNodeWithRender,
  GridValueGetterParams,
  GridValidRowModel,
  GridRowClassNameParams,
  GridCellParams,
  GridActionsCellItem,
  GridRowId
} from '@mui/x-data-grid';
import PaidIcon from '@mui/icons-material/Paid';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import {
  ExpenseType,
  Invoice,
  Company,
  CompanyIban,
  CurrencyDropDown,
  InvoiceStages,
  InvoiceSection
} from 'openapi';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';
import { useCompanyController } from 'api/controllers/CompanyController';
import { useInvoiceController } from 'api/controllers/InvoiceController';

import { usePermissions } from 'context/PermissionsContext';
import { useTranslations } from 'context/TranslationContext';

import { ROUTE_INVOICE_VERIFICATION } from 'utils/routes';
import {
  CHECK,
  COLUMNS_DEFAULT_OPTIONS,
  COMPANY_PARAMETER,
  CURRENCY,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  DRAFT_INVOICE_STYLE,
  DUE_DATE,
  EUROBANK_CODE,
  EXPENSE_TYPE,
  ID_PARAMETER,
  INVOICE_AMOUNT,
  INVOICE_DATE,
  INVOICE_NUMBER,
  IS_BOOKED,
  IS_PAYED,
  EXPORTABLE,
  NOT_SET,
  ONE,
  SHORT_COMPANY_NAME,
  STAGE_PARAMETER,
  COUNTER_PARTY_NAME,
  STAGE,
  ACTIONS
} from 'utils/constants/constants';
import { exportTxtFile } from 'utils/helpers/exportTxtFile';
import { exportExcelFile } from 'utils/helpers/exportExcelFile';
import { InvoiceSectionKey } from 'utils/interfaces/InvoiceProps';

import { GridToolbar } from 'components/shared/GridToolbar/GridToolbar';
import { Modal } from 'components/shared/Modal/Modal';
import { SeverityPill } from 'components/shared/SeverityPill/SeverityPill';

import { useDataGridHelpers } from 'hooks/useDataGridHelpers';
import { useModal } from 'hooks/useModal';

import {
  invoicesDataGrid,
  notExportableSignStyles,
  exportableContainerStyles,
  exportableIconContainerStyles,
  exportableIconStyles,
  exportableSignStyles,
  exportableStyles
} from 'styles/components/InvoicesDataGridStyle';
import { noPaginationDataGridContainerStyle } from 'styles/components/DataGridStyle';
import {
  modalButtonsWrapper,
  smallVerificationFieldWidth
} from 'styles/pages/InvoiceVerificationStyle';

interface InvoicesDataGridProp {
  readonly invoicesList: Invoice[];
  readonly setInvoicesList: Dispatch<SetStateAction<Invoice[]>>;
  readonly stateRoute: string;
}

export const InvoicesDataGrid = ({
  invoicesList,
  setInvoicesList,
  stateRoute
}: InvoicesDataGridProp): React.JSX.Element => {
  const navigate = useNavigate();

  const permissions = usePermissions();
  const { translate } = useTranslations();
  const { isOpen, closeModal, openModal } = useModal();
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal
  } = useModal();

  const { getSeverityColor } = useDataGridHelpers();

  const { getExpenseTypes, getCurrencies } = useAdditionalFieldsController();
  const {
    updateInvoice,
    deleteInvoices,
    exportSelectedInvoicesForPayment,
    exportInvoicesInExcel
  } = useInvoiceController();
  const { getAllCompanies } = useCompanyController();

  localStorage.setItem('state', stateRoute);

  const [expenseTypeOptions, setExpenseTypeOptions] = useState<ExpenseType[]>(
    []
  );
  const [currencyOptions, setCurrencyOptions] = useState<CurrencyDropDown[]>(
    []
  );
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isMultiCompanySelected, setIsMultiCompanySelected] =
    useState<boolean>(false);
  const [hasNonExportableInvoice, setHasNonExportableInvoice] =
    useState<boolean>(false);
  const [allCompanies, setAllCompanies] = useState<Company[]>();
  const [selectedCompanyIbans, setSelectedCompanyIbans] = useState<
    CompanyIban[] | undefined
  >();
  const [selectedIban, setSelectedIban] = useState<string>('');
  const [exportCompanyId, setExportCompanyId] = useState<number>();
  const [selectedIdToDelete, setSelectedIdToDelete] = useState<GridRowId>();

  const getAdditionalFieldColumnValue = (
    params: GridValueGetterParams | any,
    options: ExpenseType[] | CurrencyDropDown[] | [],
    valueType: string
  ) => {
    if (!options) {
      return '';
    }

    let option;

    if (valueType === 'expenseType') {
      option = (options as []).find(
        (status: ExpenseType) => (status.id as number) === params.value
      );
    } else if (valueType === 'isoCode') {
      option = (options as []).find(
        (status: CurrencyDropDown) =>
          (status.id as number) === params.row.currencyId
      );
    }

    return option && option[valueType] !== NOT_SET ? option[valueType] : '';
  };

  const updateInvoiceFromDataGrid = useCallback(
    async (
      section: InvoiceSectionKey,
      updatedInvoice: Invoice,
      newValues: object
    ) => {
      await updateInvoice(updatedInvoice.id as number, section, newValues);
    },
    [updateInvoice]
  );

  const sortInvoices = (invoices: Invoice[]): Invoice[] => {
    return invoices.sort((a, b) => {
      if (a.stage === InvoiceStages.DRAFT && b.stage !== InvoiceStages.DRAFT) {
        return -1;
      }
      if (a.stage !== InvoiceStages.DRAFT && b.stage === InvoiceStages.DRAFT) {
        return 1;
      }
      return 0;
    });
  };

  const onHandleChangeAdditionalFields = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      id: number,
      field: string,
      section: InvoiceSectionKey
    ) => {
      const newValue = event.target.checked;
      const invoiceIndex = invoicesList.findIndex(
        (invoice) => invoice.id === id
      );
      const updatedInvoice = {
        ...invoicesList[invoiceIndex],
        [field]: newValue
      };
      const updatedInvoiceValues = {
        id,
        counterPartyRegistrationNumber:
          invoicesList[invoiceIndex].counterPartyRegistrationNumber,
        invoiceNumber: invoicesList[invoiceIndex].invoiceNumber,
        [field]: newValue
      };
      const updatedInvoiceNewValues = {
        [field]: newValue
      };

      const newInvoices = [
        ...invoicesList.slice(0, invoiceIndex),
        updatedInvoice,
        ...invoicesList.slice(invoiceIndex + ONE)
      ];

      updateInvoiceFromDataGrid(
        section,
        updatedInvoiceValues,
        updatedInvoiceNewValues
      );

      setInvoicesList(sortInvoices(newInvoices));
    },
    [invoicesList, setInvoicesList, updateInvoiceFromDataGrid]
  );

  const onFilter = useCallback(
    (rows: GridRowSelectionModel) =>
      invoicesList.filter((invoice: Invoice) =>
        rows.includes(invoice.id as number)
      ),
    [invoicesList]
  );

  const confirmDelete = async (id: GridRowId) => {
    const updatedInvoiceList = invoicesList.filter(
      (invoice: Invoice) => invoice.id !== id
    );

    await deleteInvoices([id] as number[]);
    setInvoicesList(updatedInvoiceList);
    closeDeleteModal();
  };

  const onDelete = async (id: GridRowId) => {
    setSelectedIdToDelete(id);
    openDeleteModal();
  };

  const onExport = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const selectedInvoices = onFilter(selectedRows);
    const { companyId } = selectedInvoices[0];

    setExportCompanyId(selectedInvoices[0].companyId);
    let ibans = allCompanies?.flatMap((company: Company) =>
      company.id === companyId && company.companyIbanList
        ? company.companyIbanList
        : []
    );

    // Filters only Eurobank ibans
    ibans = ibans?.filter((bank) => bank.iban.includes(EUROBANK_CODE));

    setSelectedCompanyIbans(ibans);

    openModal();
  };

  const onExportExcel = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const exportedFile = await exportInvoicesInExcel();

    if (exportedFile) {
      exportExcelFile(exportedFile.fileContent, exportedFile.fileName);
    }
  };

  const handleIbanChange = useCallback((event: SelectChangeEvent<string>) => {
    setSelectedIban(event.target.value);
  }, []);

  const checkSingleCompanySelected = useCallback(
    (newSelection: GridRowSelectionModel) => {
      const selectedInvoices = onFilter(newSelection);
      const uniqueCompanyIds = [
        ...new Set(selectedInvoices.map((invoice) => invoice.companyId))
      ];

      const exportableInvoice = selectedInvoices.some(
        (invoice) => invoice.exportable === false
      );
      setHasNonExportableInvoice(exportableInvoice);

      if (uniqueCompanyIds.length > 1) {
        setIsMultiCompanySelected(true);
      } else {
        setIsMultiCompanySelected(false);
      }
    },
    [onFilter]
  );

  const exportButtonTitle = () => {
    if (isMultiCompanySelected) {
      return translate('warnings.exportMultiCompanies');
    }
    if (hasNonExportableInvoice) {
      return translate('warnings.nonExportableInvoices');
    }
    return null;
  };

  const onChangeStatus = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
      section: InvoiceSectionKey
    ) => {
      params.api.setEditCellValue({
        id: params.id,
        field: params.field,
        value: event.target.value
      });
      onHandleChangeAdditionalFields(
        event,
        params.row.id,
        params.field,
        section
      );
    },
    [onHandleChangeAdditionalFields]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, params: any) => {
      onChangeStatus(event, params, InvoiceSection.IS_PAID_IS_BOOKED);
    },
    [onChangeStatus]
  );

  const onRenderBookingStatusCell = (
    params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    const { row } = params;

    return (
      <FormControl>
        <Switch
          checked={!!row.isBooked || false}
          onChange={(event) => handleChange(event, params)}
          disabled={
            !permissions.BOOKING_STATUSES.update ||
            row.stage === InvoiceStages.FINALIZED
          }
        />
      </FormControl>
    );
  };

  const onRenderPMTStatusCell = (
    params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    const { row } = params;

    return (
      <FormControl>
        <Switch
          checked={!!row.isPaid || false}
          onChange={(event) => handleChange(event, params)}
          disabled={
            !permissions.PMT_STATUSES.update ||
            params.row.stage === InvoiceStages.FINALIZED
          }
        />
      </FormControl>
    );
  };

  const columns: GridColDef[] = [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: EXPORTABLE,
      width: 44,
      maxWidth: 44,
      sortable: false,
      headerAlign: 'left',
      renderHeader: () => (
        <Tooltip title={translate('labels.exportable')}>
          <Box sx={exportableIconContainerStyles}>
            <PaidIcon sx={exportableIconStyles} />
          </Box>
        </Tooltip>
      ),
      renderCell: (params) => (
        <Box sx={exportableContainerStyles}>
          <Box
            sx={{
              ...exportableStyles,
              ...(params.row.exportable
                ? exportableSignStyles
                : notExportableSignStyles)
            }}
          />
        </Box>
      )
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: STAGE,
      headerName: translate('labels.stage'),
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        const { color, backgroundColor } = getSeverityColor(
          params.formattedValue
        );
        return (
          <SeverityPill color={color} backgroundColor={backgroundColor}>
            {params.formattedValue}
          </SeverityPill>
        );
      }
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: SHORT_COMPANY_NAME,
      headerName: translate('labels.shortCompanyName'),
      minWidth: 100
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: COUNTER_PARTY_NAME,
      headerName: translate('labels.supplier'),
      minWidth: 150
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: INVOICE_NUMBER,
      headerName: translate('labels.invoice'),
      headerAlign: 'right',
      align: 'right',
      minWidth: 120
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: INVOICE_DATE,
      headerName: translate('labels.date'),
      headerAlign: 'right',
      align: 'right',
      minWidth: 100
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: INVOICE_AMOUNT,
      headerName: translate('labels.amount'),
      minWidth: 100,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (params: GridValueGetterParams) => {
        const value = params.value || '';
        const match = value.toString().match(/^(\d+(?:\.\d{1,2})?)/);
        return match ? match[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
      }
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: CURRENCY,
      headerName: translate('labels.currency'),
      minWidth: 90,
      align: 'right',
      valueGetter: (param) =>
        getAdditionalFieldColumnValue(param, currencyOptions, 'isoCode')
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: DUE_DATE,
      headerName: translate('labels.dueDate'),
      headerAlign: 'right',
      align: 'right',
      minWidth: 100
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: EXPENSE_TYPE,
      headerName: translate('labels.expenseType'),
      minWidth: 150,
      valueGetter: (param) =>
        getAdditionalFieldColumnValue(param, expenseTypeOptions, 'expenseType')
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: IS_BOOKED,
      headerName: translate('labels.bookingStatus'),
      sortable: false,
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: onRenderBookingStatusCell
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: IS_PAYED,
      headerName: translate('labels.PMTStatus'),
      sortable: false,
      minWidth: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: onRenderPMTStatusCell
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: ACTIONS,
      headerName: translate('labels.actions'),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        const { id } = params;
        return (
          <GridActionsCellItem
            icon={
              <DeleteTwoToneIcon
                sx={{
                  color: 'rgb(255, 25, 67)'
                }}
                titleAccess={translate('labels.delete')}
              />
            }
            label="Delete"
            onClick={() => onDelete(id)}
          />
        );
      }
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      const expenseTypeOptionValues = await getExpenseTypes();
      setExpenseTypeOptions(expenseTypeOptionValues);

      const currencyOptionValues = await getCurrencies();
      setCurrencyOptions(currencyOptionValues);

      const companies = await getAllCompanies();
      setAllCompanies(companies);
    };

    if (selectedCompanyIbans && selectedCompanyIbans.length > 0) {
      setSelectedIban(selectedCompanyIbans[0].iban);
    }

    fetchData();
  }, [getAllCompanies, getExpenseTypes, getCurrencies, selectedCompanyIbans]);

  const invoicesExport = useCallback(
    async (iban: string) => {
      const exportedFile = await exportSelectedInvoicesForPayment(
        Number(exportCompanyId),
        iban,
        selectedRows
      );

      if (exportedFile) {
        exportTxtFile(exportedFile.fileContent, exportedFile.fileName);
      }
    },
    [exportCompanyId, exportSelectedInvoicesForPayment, selectedRows]
  );

  const onClickExport = useCallback(() => {
    closeModal();
    invoicesExport(selectedIban);
    setSelectedIban('');
  }, [closeModal, invoicesExport, selectedIban]);

  const getRowId = useCallback(
    (row: GridValidRowModel) => row.id || row.name,
    []
  );

  const getRowClassName = useCallback(
    (params: GridRowClassNameParams<GridValidRowModel>) => {
      if (params.row.stage === InvoiceStages.DRAFT) {
        return DRAFT_INVOICE_STYLE;
      }
      return '';
    },
    []
  );

  const onCellClick = useCallback(
    (params: GridCellParams) => {
      if (
        params.field !== IS_PAYED &&
        params.field !== IS_BOOKED &&
        params.field !== ACTIONS &&
        params.field !== CHECK
      ) {
        navigate(
          ROUTE_INVOICE_VERIFICATION.replace(
            ID_PARAMETER,
            params.row.id.toString()
          )
            .replace(COMPANY_PARAMETER, params.row.companyId.toString())
            .replace(STAGE_PARAMETER, params.row.stage.toString()),
          { state: { invoice: stateRoute } }
        );
      }
    },
    [navigate, stateRoute]
  );

  const onRowSelectionModelChange = useCallback(
    (newSelection: GridRowSelectionModel) => {
      setSelectedRows(newSelection as number[]);
      checkSingleCompanySelected(newSelection);
    },
    [checkSingleCompanySelected]
  );

  return (
    <Paper elevation={8} sx={noPaginationDataGridContainerStyle}>
      <DataGrid
        rows={invoicesList}
        columns={columns}
        getRowId={getRowId}
        pageSizeOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
        sx={invoicesDataGrid}
        getRowClassName={getRowClassName}
        onCellClick={onCellClick}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={onRowSelectionModelChange}
        disableColumnMenu
        slots={{
          toolbar: GridToolbar
        }}
        slotProps={{
          toolbar: {
            handleExportClick: onExport,
            handleExportForPaymentClick: onExport,
            handleExportExcelClick: invoicesList.length > 0 && onExportExcel,
            isExportDisabled: isMultiCompanySelected || hasNonExportableInvoice,
            selectedRows,
            exportButtonTitle: exportButtonTitle(),
            exportTableName: `Invoices_${new Date().getTime()}`
          }
        }}
        localeText={{
          noRowsLabel: translate('labels.noData')
        }}
      />
      {selectedCompanyIbans?.length ? (
        <Modal
          headerTitle={translate('titles.export')}
          isOpen={isOpen}
          hide={closeModal}
          maxWidth="sm"
        >
          <Typography variant="body1" mb={2}>
            {translate('messages.invoicesExportConfirmation')}
          </Typography>
          <FormControl sx={{ ...smallVerificationFieldWidth, mb: 2 }}>
            <InputLabel>{translate('labels.iban')}</InputLabel>
            <Select
              value={selectedIban}
              onChange={handleIbanChange}
              label={translate('labels.iban')}
            >
              {selectedCompanyIbans?.map((iban: CompanyIban) => (
                <MenuItem key={iban.iban} value={iban.iban}>
                  {iban.bank ? `${iban.iban} (${iban.bank})` : iban.iban}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={modalButtonsWrapper}>
            <Button variant="outlined" onClick={closeModal}>
              {translate('buttons.cancel')}
            </Button>
            <Button variant="contained" onClickCapture={onClickExport}>
              {translate('buttons.export')}
            </Button>
          </Box>
        </Modal>
      ) : (
        <Modal
          headerTitle={translate('titles.export')}
          isOpen={isOpen}
          hide={closeModal}
          maxWidth="sm"
        >
          <Typography variant="body1" mb={2}>
            {translate('messages.missingExportableIban')}
          </Typography>
          <Box sx={modalButtonsWrapper}>
            <Button variant="outlined" onClick={closeModal}>
              {translate('buttons.cancel')}
            </Button>
          </Box>
        </Modal>
      )}
      {isDeleteModalOpen && selectedIdToDelete && (
        <Modal
          headerTitle={translate('titles.delete')}
          isOpen={isDeleteModalOpen}
          hide={closeDeleteModal}
          maxWidth="sm"
        >
          <Typography variant="body1" mb={2}>
            {translate('messages.deleteConfirmation')}
          </Typography>
          <Box sx={modalButtonsWrapper}>
            <Button variant="outlined" onClick={closeDeleteModal}>
              {translate('buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={() => confirmDelete(selectedIdToDelete)}
            >
              {translate('buttons.delete')}
            </Button>
          </Box>
        </Modal>
      )}
    </Paper>
  );
};
