import React, { ReactNode, useContext } from 'react';

import { PermissionsObject } from 'utils/interfaces/Permissions';

const PermissionsContext = React.createContext<PermissionsObject>({});

interface PermissionsProviderProps {
  readonly children: ReactNode;
  readonly permissions: PermissionsObject;
}

const PermissionsProvider = ({
  children,
  permissions
}: PermissionsProviderProps) => (
  <PermissionsContext.Provider value={permissions}>
    {children}
  </PermissionsContext.Provider>
);

export const usePermissions = (): PermissionsObject => {
  const context = useContext(PermissionsContext);

  if (context === undefined) {
    throw new Error(
      'usePermissions must be used with-in a PermissionsProvider'
    );
  }

  return context;
};

export default PermissionsProvider;
