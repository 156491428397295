import { ID_ERROR } from 'utils/constants/identifiers';
import { showErrorToastMessage } from 'components/shared/Toast/Toast';
import { ROUTE_HOME } from 'utils/routes';
import { HttpStatusCodes } from 'utils/enums/HttpStatusCodes';
import { Error } from 'utils/interfaces/ErrorProps';
import { customHistory } from 'App';
import { UserController } from 'api/controllers/UserController';
import { viravaDefault } from 'config/authConfig';

export const errorResultHandler = (responseError: any, messaging: any) => {
  const { userRegister } = UserController();

  const error = responseError[ID_ERROR]?.error as Error;

  switch (responseError) {
    case HttpStatusCodes.Forbidden:
      customHistory.replace(ROUTE_HOME);
      return showErrorToastMessage(error.detail);
    case HttpStatusCodes.ExpectationFailed:
      return userRegister(messaging);
    case HttpStatusCodes.Unauthorized:
      return viravaDefault.logout();
    default:
      return showErrorToastMessage(error.detail);
  }
};
