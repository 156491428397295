import { memo } from 'react';
import { createPortal } from 'react-dom';
import { Box } from '@mui/material';

import { ModalPortalHookProps } from 'utils/interfaces/ModalProps';

import { modal } from 'styles/components/ModalStyle';

export const ModalPortal = memo(({ children }: ModalPortalHookProps) => {
  const modalElement = document.getElementById('modal');

  if (!modalElement) {
    return null;
  }

  return createPortal(<Box sx={modal}>{children}</Box>, modalElement);
});
