export const appStyle = {
  textAlign: 'center',
  width: '100%',
  height: '100vh'
};

export const displayWrapper = {
  display: 'grid',
  gridTemplateColumns: '300px 1fr',
  height: '100%',
  width: '100%',
  transition: '0.5s'
};

export const expandedSidebarGrid = {
  gridTemplateColumns: '300px 1fr'
};

export const retractedSidebarGrid = {
  gridTemplateColumns: '120px 1fr'
};

export const routesWrapper = {
  padding: '0 20px 20px 20px',
  maxWidth: '1600px',
  margin: '0 auto'
};
