export enum InvoiceSubmit {
  'Save' = 'save',
  'SaveAndNext' = 'saveAndNext'
}

export enum InvoiceVerificationMapper {
  isReceiverVerified = 'isSupplierVerified',
  isSupplierVerified = 'isInvoiceDataVerified',
  isInvoiceDataVerified = 'areProductsVerified',
  areProductsVerified = 'isPaidIsBookedVerified',
  isPaidIsBookedVerified = ''
}

export enum SectionIndex {
  SUPPLIER = 1,
  INVOICE_DATA = 2,
  PRODUCTS = 3,
  STATUS = 4
}
