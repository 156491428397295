/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginResponse } from '../models/LoginResponse';
import type { RegisterResponse } from '../models/RegisterResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * This is a user registration method
     * @returns RegisterResponse OK
     * @throws ApiError
     */
    public static register(): CancelablePromise<RegisterResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/register',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This is a method returning user's login data
     * @returns LoginResponse OK
     * @throws ApiError
     */
    public static login(): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/login',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                417: `EXPECTATION FAILED`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
