import { decodeExcelBase64 } from './decodeExcelBase64';

export const exportExcelFile = async (data: string, fileName: string) => {
  const fileBlob = new Blob([decodeExcelBase64(data)], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  const url = URL.createObjectURL(fileBlob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
