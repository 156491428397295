import React, { useMemo } from 'react';
import { Company } from 'openapi';
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { useCompanies } from 'context/CompanyContext';
import { usePermissions } from 'context/PermissionsContext';

import invoiceIcon from 'assets/images/ic_invoice.svg';

import SidebarIcon from 'components/Sidebar/SidebarIcon/SidebarIcon';
import { createInvoicesTabConditionalsManager } from 'components/Sidebar/SidebarHelper';
import { useTranslations } from 'context/TranslationContext';

import { icon, selectedIcon } from 'styles/components/SidebarStyle';

import { TabType } from 'utils/enums/SidebarTabType';
import { ROUTE_INVOICES, ROUTE_UPLOAD_INVOICES } from 'utils/routes';
import { idStringReplace } from 'utils/helpers/idStringReplace';

interface SidebarProps {
  openTabs: TabType[];
  isSidebarExpanded: boolean;
  selectedTab: TabType | null;
  selectedNestedTab: string | null;
  handleTabClick: (tab: TabType) => void;
  handleNestedTabClick: (navigationRoute: string) => void;
}

export const InvoicesTab: React.FC<SidebarProps> = ({
  isSidebarExpanded,
  selectedTab,
  openTabs,
  selectedNestedTab,
  handleTabClick,
  handleNestedTabClick
}) => {
  const permissions = usePermissions();
  const { companiesList } = useCompanies();
  const { translate } = useTranslations();

  const layoutConditionalsManager = useMemo(
    () =>
      createInvoicesTabConditionalsManager(
        isSidebarExpanded,
        selectedTab,
        openTabs,
        selectedNestedTab
      ),
    [isSidebarExpanded, selectedTab, openTabs, selectedNestedTab]
  );

  return (
    <div>
      {permissions.INVOICES.read && (
        <ListItemButton
          onClick={() => handleTabClick(TabType.Invoice)}
          sx={layoutConditionalsManager.tabStyle}
        >
          <ListItemIcon sx={icon}>
            <SidebarIcon
              iconSource={invoiceIcon}
              shouldBeSelected={
                openTabs.includes(TabType.Invoice) ||
                selectedTab === TabType.Invoice
              }
            />
          </ListItemIcon>

          <ListItemText>
            <Typography
              sx={layoutConditionalsManager.textStyle}
              variant={
                layoutConditionalsManager.textTypography as 'body1' | 'body2'
              }
            >
              {translate('pages.invoices')}
            </Typography>
          </ListItemText>

          {openTabs[0] === TabType.Invoice ? (
            <ExpandLess sx={selectedIcon} />
          ) : (
            <ExpandMore sx={icon} />
          )}
        </ListItemButton>
      )}

      {permissions.INVOICES.read && (
        <Collapse in={layoutConditionalsManager.isDropdownOpen} timeout="auto">
          <List component="div" disablePadding>
            <ListItemButton
              sx={layoutConditionalsManager.allSubTabStyle}
              onClick={() => handleNestedTabClick(ROUTE_INVOICES)}
            >
              <Box sx={layoutConditionalsManager.allSubTabBulletStyle} />

              <Typography
                variant={
                  layoutConditionalsManager.allSubTabTypography as
                    | 'body1'
                    | 'body2'
                }
              >
                {translate('buttons.all')}
              </Typography>
            </ListItemButton>

            {companiesList.map((company: Company) => (
              <ListItemButton
                sx={layoutConditionalsManager.subTabsStyle(company.id!)}
                onClick={() =>
                  handleNestedTabClick(
                    idStringReplace(
                      ROUTE_UPLOAD_INVOICES,
                      company.id!.toString()
                    )
                  )
                }
                key={company.id}
              >
                <Box
                  sx={layoutConditionalsManager.subTabsBulletStyles(
                    company.id!
                  )}
                />

                <Typography
                  variant={layoutConditionalsManager.subTabsTypography(
                    company.id!
                  )}
                >
                  {' '}
                  {company.alias}{' '}
                </Typography>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      )}
    </div>
  );
};

export default InvoicesTab;
