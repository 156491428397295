import { useCallback } from 'react';
import { Prompt, PromptPatch, PromptCreate } from 'openapi';
import { PromptService } from 'openapi/services/PromptService';

import { showSuccessToastMessage } from 'components/shared/Toast/Toast';
import { useTranslations } from 'context/TranslationContext';
import interceptRequest from 'utils/helpers/interceptRequest';

export const usePromptController = () => {
  const { translate } = useTranslations();
  const {
    patchPrompt,
    getAllPrompts,
    getPromptById,
    createPrompt,
    deletePromptById
  } = PromptService;

  const getPrompts = () => interceptRequest(getAllPrompts, {});

  const getCurrentPrompt = useCallback(
    async (id: number) => interceptRequest(getPromptById, {}, id),
    [getPromptById]
  );

  const updateCurrentPrompt = (id: number, requestBody: Prompt) =>
    interceptRequest(
      patchPrompt,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyUpdated'))
      },
      id,
      requestBody as PromptPatch
    );

  const addPrompt = (requestBody: Prompt) =>
    interceptRequest(
      createPrompt,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyCreated'))
      },
      requestBody as PromptCreate
    );

  const deletePrompt = (id: number) =>
    interceptRequest(
      deletePromptById,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyDeleted'))
      },
      id
    );

  return {
    getPrompts,
    updateCurrentPrompt,
    getCurrentPrompt,
    addPrompt,
    deletePrompt
  };
};
