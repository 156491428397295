import { useCallback } from 'react';

import { AccountInfo, Invoice, Company, CounterPartyDropDown } from 'openapi';

import { getIn, FormikErrors } from 'formik';

import { useTranslations } from 'context/TranslationContext';
import { conditions } from 'utils/helpers/invoiceHelpers';

export const useFormHelpers = () => {
  const { translate } = useTranslations();

  const getTitleHint = useCallback(
    (name: keyof Invoice, values: Invoice): string | undefined => {
      const condition = conditions[name];

      if (condition && condition(values[name] as string, values)) {
        return translate(`warnings.${name}`);
      }

      return undefined;
    },
    [translate]
  );

  const checkForError = (
    errors: FormikErrors<Invoice>,
    fieldNames: string[]
  ) => {
    return fieldNames.some((fieldName) => getIn(errors, fieldName));
  };

  const renderAccountLabel = useCallback((account: AccountInfo) => {
    if (account) {
      return `${account.value} - ${account.description}`;
    }

    return '';
  }, []);

  const getOptionLabelReceiverName = useCallback((option: Company | string) => {
    if (option === null) {
      return '';
    }
    if (typeof option === 'string') {
      return option;
    }

    return `${option.name} (${option.companyRegistrationNumber})`;
  }, []);

  const getOptionLabelSupplier = useCallback(
    (option: CounterPartyDropDown | string) => {
      if (typeof option === 'string') {
        return option;
      }

      if (option !== null) {
        if (option.counterPartyRegistrationNumber === null) {
          return option.name;
        }
        return `${option.name} (${option.counterPartyRegistrationNumber})`;
      }
      return '';
    },
    []
  );

  const getOptionLabelReceiverCRN = useCallback((option: Company | string) => {
    if (typeof option === 'string') {
      return option;
    }

    return `${option.companyRegistrationNumber} (${option.name})`;
  }, []);

  const getOptionLabelCRN = useCallback(
    (option: CounterPartyDropDown | string) => {
      if (typeof option === 'string') {
        return option;
      }
      if (option.counterPartyRegistrationNumber === null) {
        return `- (${option.name})`;
      }

      if (option !== null) {
        if (option.name === null) {
          return `${option.counterPartyRegistrationNumber}`;
        }
        return `${option.counterPartyRegistrationNumber} (${option.name})`;
      }
      return '';
    },
    []
  );

  return {
    getTitleHint,
    checkForError,
    renderAccountLabel,
    getOptionLabelReceiverName,
    getOptionLabelSupplier,
    getOptionLabelReceiverCRN,
    getOptionLabelCRN
  };
};
