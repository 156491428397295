/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type of the counter party
 */
export enum CounterPartyType {
    CLIENT = 'CLIENT',
    SUPPLIER = 'SUPPLIER',
}
