export const bankStatementVerificationDataGridStyle = {
  '& .MuiDataGrid-cell': {
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    cursor: 'pointer'
  },
  '& .MuiDataGrid-row': {
    maxHeight: 'none !important'
  },
  '& .MuiDataGrid-cellContent': {
    textOverflow: 'ellipsis !important',
    textAlign: 'left !important'
  },
  '& .draftInvoiceStyle': {
    background: '#ff000026',
    '&:hover': {
      background: '#ff000042'
    }
  },
  '& .MuiDataGrid-cell--editing': {
    width: '400px',
    maxWidth: '400px !important'
  }
};
