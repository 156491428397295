import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import {
  ROUTE_BANK_STATEMENTS,
  ROUTE_BANK_STATEMENT_VERIFICATION,
  ROUTE_INVOICES,
  ROUTE_PROMPT_SETTINGS,
  ROUTE_UPLOAD_BANK_STATEMENTS,
  ROUTE_UPLOAD_INVOICES,
  ROUTE_COMPANIES_SETTINGS,
  ROUTE_INVOICE_VERIFICATION,
  ROUTE_PROMPT_SETTINGS_ID,
  ROUTE_ADD_PROMPT,
  ROUTE_COMPANY_DETAILS,
  ROUTE_ADD_COMPANY,
  ROUTE_NOT_FOUND
} from 'utils/routes';

import { usePermissions } from 'context/PermissionsContext';

const ROUTES_TO_PERMISSIONS: Record<string, string> = {
  [ROUTE_INVOICES]: 'INVOICES',
  [ROUTE_UPLOAD_INVOICES]: 'INVOICES',
  [ROUTE_INVOICE_VERIFICATION]: 'INVOICES',
  [ROUTE_BANK_STATEMENTS]: 'BANK_STATEMENTS',
  [ROUTE_BANK_STATEMENT_VERIFICATION]: 'BANK_STATEMENTS',
  [ROUTE_UPLOAD_BANK_STATEMENTS]: 'BANK_STATEMENTS',
  [ROUTE_PROMPT_SETTINGS]: 'PROMPTS',
  [ROUTE_PROMPT_SETTINGS_ID]: 'PROMPTS',
  [ROUTE_ADD_PROMPT]: 'PROMPTS',
  [ROUTE_COMPANIES_SETTINGS]: 'COMPANIES,PROMPTS',
  [ROUTE_COMPANY_DETAILS]: 'COMPANIES,PROMPTS',
  [ROUTE_ADD_COMPANY]: 'COMPANIES,PROMPTS'
};

interface ProtectedRouteProps {
  readonly children: JSX.Element;
  readonly path: string;
}

export const ProtectedRoute = ({
  children,
  ...props
}: ProtectedRouteProps): JSX.Element => {
  const permissions = usePermissions();

  const hasPermission = useMemo(() => {
    const resources = ROUTES_TO_PERMISSIONS[props.path]?.split(',') ?? [];

    return resources.every((resource) => permissions[resource]?.read ?? true);
  }, [permissions, props.path]);

  if (!hasPermission) {
    return <Navigate to={ROUTE_NOT_FOUND} />;
  }

  return children;
};
