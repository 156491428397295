/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvoiceSection {
    RECEIVER = 'RECEIVER',
    SUPPLIER = 'SUPPLIER',
    INVOICE_DATA = 'INVOICE_DATA',
    PRODUCTS = 'PRODUCTS',
    IS_PAID_IS_BOOKED = 'IS_PAID_IS_BOOKED',
}
