import { decodeCsvBase64 } from './decodeCsvBase64';

export const exportCsvFile = async (data: string, fileName: string) => {
  const fileBlob = new Blob([decodeCsvBase64(data)], { type: 'text/csv' });

  const url = URL.createObjectURL(fileBlob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
