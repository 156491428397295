import { showErrorToastMessage } from 'components/shared/Toast/Toast';

import { viravaDefault } from 'config/authConfig';

const interceptRequest = async (
  requestFunc: Function,
  options: { onSuccess?: () => void },
  ...params: any[]
) => {
  try {
    const response = await requestFunc(...params);

    if (options.onSuccess) {
      options.onSuccess();
    }

    if (response && response.data) {
      return response.data;
    }

    return response;
  } catch (error: any) {
    if (error.status === 401) {
      if (!viravaDefault.isRefreshTokenExpired()) {
        await viravaDefault.updateToken();
        const response = await requestFunc(...params);
        if (response.data) {
          return response.data;
        }
        return response;
      }

      return viravaDefault.logout();
    }
    showErrorToastMessage(error);
    throw new Error(error);
  }
};

export default interceptRequest;
