/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BankStatementListResponse } from '../models/BankStatementListResponse';
import type { FinancialTransactionPatch } from '../models/FinancialTransactionPatch';
import type { FinancialTransactionPreviewListResponse } from '../models/FinancialTransactionPreviewListResponse';
import type { FinancialTransactionPreviewResponse } from '../models/FinancialTransactionPreviewResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BankStatementService {

    /**
     * This method returns the list of all bank statements
     * @returns BankStatementListResponse OK
     * @throws ApiError
     */
    public static getAllBankStatements(): CancelablePromise<BankStatementListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/bankStatements',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method deletes bank statements and all their transactions by id
     * @param bankStatementIds List of bank statement ids to be deleted
     * @returns void
     * @throws ApiError
     */
    public static deleteBankStatementsByIds(
        bankStatementIds: Array<number>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/bankStatements',
            query: {
                'bankStatementIds': bankStatementIds,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Patch financial transaction for bank statement
     * @param id the bank statement id
     * @param transactionId the financial transaction id
     * @param requestBody The financial transaction object
     * @returns FinancialTransactionPreviewResponse OK
     * @throws ApiError
     */
    public static patchFinancialTransaction(
        id: number,
        transactionId: number,
        requestBody: FinancialTransactionPatch,
    ): CancelablePromise<FinancialTransactionPreviewResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/bankStatements/{id}/financialTransactions/{transactionId}',
            path: {
                'id': id,
                'transactionId': transactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of bank statements by company id
     * @param id The company id
     * @returns BankStatementListResponse OK
     * @throws ApiError
     */
    public static getBankStatementsByCompanyId(
        id: number,
    ): CancelablePromise<BankStatementListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/bankStatements',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Upload multiple bank statements
     * @param id The company id
     * @param formData List of bank statement pdf files to be uploaded
     * @returns BankStatementListResponse OK
     * @throws ApiError
     */
    public static uploadBankStatements(
        id: number,
        formData: {
            files?: Array<Blob>;
        },
    ): CancelablePromise<BankStatementListResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/bankStatements',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Preview all financial transactions for single bank statement by company id before exporting to CSV file
     * @param companyId the company id
     * @param bankStatementId the bank statement id
     * @returns FinancialTransactionPreviewListResponse OK
     * @throws ApiError
     */
    public static previewBankStatementByCompanyId(
        companyId: number,
        bankStatementId: number,
    ): CancelablePromise<FinancialTransactionPreviewListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/bankStatements/{bankStatementId}/preview',
            path: {
                'companyId': companyId,
                'bankStatementId': bankStatementId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Preview all financial transactions for single bank statement before exporting to CSV file
     * @param bankStatementId the bank statement id
     * @returns FinancialTransactionPreviewListResponse OK
     * @throws ApiError
     */
    public static previewBankStatement(
        bankStatementId: number,
    ): CancelablePromise<FinancialTransactionPreviewListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/bankStatements/{bankStatementId}/preview',
            path: {
                'bankStatementId': bankStatementId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Get pdf document for a single bank statement
     * @param id The bank statement id
     * @returns binary OK
     * @throws ApiError
     */
    public static getBankStatementPdfById(
        id: number,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/bankStatements/{id}/document',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
