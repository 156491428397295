import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';

import { useTranslations } from 'context/TranslationContext';

import { dropZoneArea } from 'styles/components/DropzoneArea';

interface Props {
  onFilesSelected: (files: File[]) => void;
}

export const DropzoneArea: React.FC<Props> = ({ onFilesSelected }) => {
  const { translate } = useTranslations();

  const [isDragging, setIsDragging] = useState(false);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFilesSelected(acceptedFiles);
      setIsDragging(false);
    },
    [onFilesSelected]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    accept: { 'application/pdf': [] }
  });

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(true);
    },
    []
  );

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(false);
    },
    []
  );

  return (
    <Box
      {...getRootProps()}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      sx={{
        ...dropZoneArea,
        backgroundColor: isDragging ? '#EFEFEF' : '#FFFFFF'
      }}
    >
      <input {...getInputProps()} />
      <Typography align="center" variant="body1">
        {translate('buttons.dragAndDrop')}
      </Typography>
    </Box>
  );
};
