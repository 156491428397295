import { Buffer } from 'buffer';

import { PDF_CONTENT_TYPE } from 'utils/constants/constants';

export const base64toBlob = (data: string) => {
  const bytes = Buffer.from(data, 'base64');
  const out = new Uint8Array(bytes);

  return new Blob([out], { type: PDF_CONTENT_TYPE });
};
