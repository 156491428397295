/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Account types
 */
export enum AccountType {
    BS = 'BS',
    PL = 'PL',
}
