import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  AccountInfo,
  AccountType,
  CounterParty,
  CounterPartyCreateUpdate,
  CounterPartyType,
  ExpenseType
} from 'openapi';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';
import { useAccountController } from 'api/controllers/AccountController';

import { useTranslations } from 'context/TranslationContext';

import {
  halfWidth,
  submitButton,
  formSmallContainer
} from 'styles/components/Common';

import {
  NOT_SET,
  YES_NO_OPTIONS,
  PERCENTAGE_OPTIONS
} from 'utils/constants/constants';
import { YesNo } from 'utils/enums/YesNo';
import { mapCounterPartyValues } from 'utils/mappers/counterParty';

interface CreateUpdateCounterPartyProps {
  readonly handleSubmit: (values: CounterPartyCreateUpdate) => void;
  readonly initialValues?: CounterParty;
}

export const CreateUpdateCounterParty = ({
  initialValues,
  handleSubmit
}: CreateUpdateCounterPartyProps) => {
  const { translate } = useTranslations();
  const { id } = useParams();

  const { getAccounts } = useAccountController();
  const { getExpenseTypes } = useAdditionalFieldsController();

  const [expenseTypeOptions, setExpenseTypeOptions] = useState<ExpenseType[]>(
    []
  );
  const [bsAccountOptions, setBsAccountOptions] = useState<AccountInfo[]>([]);
  const [plAccountOptions, setPlAccountOptions] = useState<AccountInfo[]>([]);

  const [formValues, setFormValues] = useState<CounterPartyCreateUpdate>(
    mapCounterPartyValues(initialValues)
  );
  const [loading, setLoading] = useState(true);

  const fetchDropdownValues = useCallback(async () => {
    const expenseTypeOptionValues = await getExpenseTypes();
    setExpenseTypeOptions(expenseTypeOptionValues);

    const bsAccounts = await getAccounts(Number(id), AccountType.BS);
    setBsAccountOptions(bsAccounts);

    const plAccounts = await getAccounts(Number(id), AccountType.PL);
    setPlAccountOptions(plAccounts);

    setLoading(false);
  }, [getAccounts, getExpenseTypes, id]);

  useEffect(() => {
    fetchDropdownValues();
  }, [fetchDropdownValues]);

  // Form
  const validationSchema = useMemo(() => {
    const baseSchema = {
      companyName: Yup.string().required(translate('errors.counterPartyName')),
      withoutCompanyRegistrationNumber: Yup.boolean(),
      type: Yup.string().required(translate('errors.type')),
      reverseCharge: Yup.string().required(translate('errors.reverseCharge')),
      vatPercentage: Yup.string().required(translate('errors.vatPercentage')),
      vatCredit: Yup.string().required(translate('errors.vatCredit')),
      balanceSheetAccountId: Yup.string(),
      profitAndLossAccountId: Yup.string(),
      companyRegistrationNumber: Yup.string()
    };

    if (!formValues.withoutCompanyRegistrationNumber) {
      baseSchema.companyRegistrationNumber = Yup.string().required(
        translate('errors.companyRegistrationNumber')
      );
    }

    return Yup.object().shape(baseSchema);
  }, [translate, formValues.withoutCompanyRegistrationNumber]);

  const counterTypeOptions = useMemo(
    () => [
      { id: CounterPartyType.CLIENT, label: translate('labels.client') },
      { id: CounterPartyType.SUPPLIER, label: translate('labels.supplier') }
    ],
    [translate]
  );

  const renderAccountLabel = (account: AccountInfo) => {
    if (account) {
      return `${account.value} - ${account.description}`;
    }

    return '';
  };

  return (
    <Box sx={{ height: '510px' }}>
      {!loading && (
        <Formik
          onSubmit={handleSubmit}
          initialValues={formValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ setFieldValue, touched, errors, values }) => (
            <Form
              style={{ ...formSmallContainer, flexDirection: 'column' }}
              noValidate
            >
              <Box style={formSmallContainer}>
                <FormControl
                  sx={halfWidth}
                  error={!!(touched.type && errors.type)}
                >
                  <InputLabel required>
                    {translate('labels.counterPartyType')}
                  </InputLabel>
                  <Select
                    name="type"
                    value={formValues.type || ''}
                    label={translate('labels.counterPartyType')}
                    onChange={(event) => {
                      setFormValues({
                        ...values,
                        type: event.target.value as CounterPartyType
                      });
                      setFieldValue('type', event.target.value);
                    }}
                  >
                    {counterTypeOptions.map((type: any) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.type && errors.type && (
                    <FormHelperText>{errors.type}</FormHelperText>
                  )}
                </FormControl>
                <Field
                  as={TextField}
                  label={translate('labels.counterPartyName')}
                  name="companyName"
                  sx={halfWidth}
                  placeholder={translate('labels.counterPartyName')}
                  helperText={<ErrorMessage name="companyName" />}
                  error={touched.companyName && !!errors.companyName}
                  required
                />
              </Box>
              <Box style={formSmallContainer}>
                <Field
                  as={TextField}
                  label={translate('labels.companyRegistrationNumber')}
                  name="companyRegistrationNumber"
                  sx={halfWidth}
                  placeholder={translate('labels.companyRegistrationNumber')}
                  helperText={<ErrorMessage name="companyRegistrationNumber" />}
                  error={
                    touched.companyRegistrationNumber &&
                    !!errors.companyRegistrationNumber
                  }
                  disabled={values.withoutCompanyRegistrationNumber}
                  required={!values.withoutCompanyRegistrationNumber}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.withoutCompanyRegistrationNumber}
                      name="withoutCompanyRegistrationNumber"
                      color="primary"
                      onChange={(event) => {
                        setFormValues({
                          ...values,
                          withoutCompanyRegistrationNumber: event.target.checked
                        });
                        setFieldValue(
                          'withoutCompanyRegistrationNumber',
                          event.target.checked
                        );
                      }}
                    />
                  }
                  label={translate('labels.withoutCompanyRegistrationNumber')}
                />
              </Box>

              <Box style={formSmallContainer}>
                <Field
                  as={TextField}
                  label={translate('labels.vatNumber')}
                  name="vatNumber"
                  sx={halfWidth}
                  placeholder={translate('labels.vatNumber')}
                  helperText={<ErrorMessage name="vatNumber" />}
                  error={touched.vatNumber && !!errors.vatNumber}
                />
                <Field
                  as={TextField}
                  label={translate('labels.iban')}
                  name="iban"
                  sx={halfWidth}
                  placeholder={translate('labels.iban')}
                  helperText={<ErrorMessage name="iban" />}
                  error={touched.iban && !!errors.iban}
                />
              </Box>

              <Box style={formSmallContainer}>
                <Field
                  name="balanceSheetAccountId"
                  as={Autocomplete}
                  options={bsAccountOptions}
                  getOptionLabel={renderAccountLabel}
                  value={
                    bsAccountOptions.find(
                      (op) => op.id === formValues.balanceSheetAccountId
                    ) || null
                  }
                  sx={halfWidth}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label={translate('labels.balanceSheetAccount')}
                    />
                  )}
                  onChange={(_: any, value: any) => {
                    setFormValues({
                      ...values,
                      balanceSheetAccountId: value?.id as number
                    });
                    setFieldValue('balanceSheetAccountId', value?.id);
                  }}
                />

                <Field
                  name="profitAndLossAccountId"
                  as={Autocomplete}
                  options={plAccountOptions}
                  getOptionLabel={renderAccountLabel}
                  value={
                    plAccountOptions.find(
                      (op) => op.id === formValues.profitAndLossAccountId
                    ) || null
                  }
                  sx={halfWidth}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label={translate('labels.pnlAccount')}
                    />
                  )}
                  onChange={(_: any, value: any) => {
                    setFormValues({
                      ...values,
                      profitAndLossAccountId: value?.id as number
                    });
                    setFieldValue('profitAndLossAccountId', value?.id);
                  }}
                />
              </Box>

              <Box style={formSmallContainer}>
                <FormControl sx={halfWidth}>
                  <InputLabel>{translate('labels.expenseType')}</InputLabel>
                  <Select
                    name="expenseTypeId"
                    value={formValues.expenseTypeId || ''}
                    label={translate('labels.expenseType')}
                    onChange={(event) => {
                      setFormValues({
                        ...values,
                        expenseTypeId: Number(event.target.value)
                      });
                      setFieldValue(
                        'expenseTypeId',
                        Number(event.target.value)
                      );
                    }}
                  >
                    {expenseTypeOptions?.map((value: any) => (
                      <MenuItem key={value.id} value={value.id}>
                        {value.expenseType === NOT_SET ? '' : value.expenseType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={halfWidth}
                  error={!!(touched.reverseCharge && errors.reverseCharge)}
                >
                  <InputLabel required>
                    {translate('labels.reverseCharge')}
                  </InputLabel>
                  <Select
                    name="reverseCharge"
                    value={
                      formValues.reverseCharge !== undefined
                        ? formValues.reverseCharge
                        : ''
                    }
                    label={translate('labels.reverseCharge')}
                    onChange={(event) => {
                      setFormValues({
                        ...values,
                        reverseCharge: event.target.value as boolean
                      });
                      setFieldValue('reverseCharge', event.target.value);
                    }}
                  >
                    {YES_NO_OPTIONS.map((value: any) => (
                      <MenuItem key={value.id} value={value.id}>
                        {value.label === YesNo.Yes
                          ? translate('labels.yes')
                          : translate('labels.no')}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.reverseCharge && errors.reverseCharge && (
                    <FormHelperText>{errors.reverseCharge}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box style={formSmallContainer}>
                <FormControl
                  sx={halfWidth}
                  error={!!(touched.vatPercentage && errors.vatPercentage)}
                >
                  <InputLabel required>
                    {translate('labels.vatPercentage')}
                  </InputLabel>
                  <Select
                    name="vatPercentage"
                    value={
                      formValues.vatPercentage !== undefined
                        ? formValues.vatPercentage
                        : ''
                    }
                    label={translate('labels.vatPercentage')}
                    onChange={(e) => {
                      setFormValues({
                        ...values,
                        vatPercentage: e.target.value as number
                      });
                      setFieldValue('vatPercentage', e.target.value);
                    }}
                  >
                    {PERCENTAGE_OPTIONS.map((value: any) => (
                      <MenuItem key={value.id} value={value.id}>
                        {value.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.vatPercentage && errors.vatPercentage && (
                    <FormHelperText>{errors.vatPercentage}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  sx={halfWidth}
                  error={!!(touched.vatCredit && errors.vatCredit)}
                >
                  <InputLabel required>
                    {translate('labels.vatCredit')}
                  </InputLabel>
                  <Select
                    name="vatCredit"
                    value={
                      formValues.vatCredit !== undefined
                        ? formValues.vatCredit
                        : ''
                    }
                    label={translate('labels.vatCredit')}
                    onChange={(event) => {
                      setFormValues({
                        ...values,
                        vatCredit: event.target.value as boolean
                      });
                      setFieldValue('vatCredit', event.target.value);
                    }}
                  >
                    {YES_NO_OPTIONS.map((value: any) => (
                      <MenuItem key={value.id} value={value.id}>
                        {value.label === YesNo.Yes
                          ? translate('labels.yes')
                          : translate('labels.no')}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.vatCredit && errors.vatCredit && (
                    <FormHelperText>{errors.vatCredit}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button variant="contained" type="submit" sx={submitButton}>
                  {translate('buttons.save')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};
