import { useEffect } from 'react';
import { Box } from '@mui/material';
import { Company } from 'openapi';

import { useCompanyController } from 'api/controllers/CompanyController';

import { Layout } from 'components/Layout/Layout';
import { CompaniesList } from 'components/CompaniesList/CompaniesList';

import { useCompanies } from 'context/CompanyContext';

import { invoicesWrapper } from 'styles/pages/InvoicesStyle';

export const Companies: React.FC = (): JSX.Element => {
  const { getAllCompanies } = useCompanyController();
  const { companiesList, setCompaniesList } = useCompanies();

  useEffect(() => {
    const fetchData = async () => {
      const allCompanies = await getAllCompanies();
      const sortedCompanies = allCompanies.sort((a: Company, b: Company) => {
        return a.id! - b.id!;
      });
      setCompaniesList(sortedCompanies);
    };

    fetchData();
  }, []);

  return (
    <Layout>
      <Box sx={invoicesWrapper}>
        <CompaniesList companies={companiesList} isCompaniesSettingsPage />
      </Box>
    </Layout>
  );
};
