export const promptTextArea = {
  padding: '16px 8px',
  resize: 'none',
  outline: 'none',
  minHeight: '100px'
};

export const promptFormFields = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  width: '100%',
  gap: '20px'
};
