import { Company, Invoice, InvoicePatch } from 'openapi';

import { INITIAL_PRODUCT_ITEM } from 'utils/constants/invoices';
import { decimalFormater } from 'utils/helpers/invoiceHelpers';

export const mapInvoiceForPatch = (invoice: Invoice): InvoicePatch => ({
  invoiceNumber: invoice.invoiceNumber,
  invoiceDate: invoice.invoiceDate,
  dueDate: invoice.dueDate,
  vatBase: invoice.vatBase,
  vatAmount: invoice.vatAmount,
  invoiceAmount: invoice.invoiceAmount,
  productDescription: invoice.productDescription,
  expenseTypeId: invoice.expenseTypeId,
  isBooked: invoice.isBooked,
  isPaid: invoice.isPaid,
  documentType: invoice.documentType,
  products: invoice.products,
  profitAndLossAccountId: invoice.profitAndLossAccountId,
  accountingDescription: invoice.accountingDescription,
  counterPartyId: invoice.counterPartyId,
  companyId: invoice.companyId,
  currencyId: invoice.currencyId
});

export const mapInvoice = (invoice: Invoice): Invoice =>
  ({
    counterPartyName: invoice?.counterPartyName || '',
    invoiceNumber: invoice?.invoiceNumber || '',
    invoiceDate: invoice?.invoiceDate || '',
    counterPartyRegistrationNumber:
      invoice?.counterPartyRegistrationNumber || '',
    dueDate: invoice?.dueDate || '',
    counterPartyVatNumber: invoice?.counterPartyVatNumber || '',
    vatBase: decimalFormater(invoice?.vatBase as number),
    vatAmount: decimalFormater(invoice?.vatAmount as number) || '',
    invoiceAmount: decimalFormater(invoice?.invoiceAmount as number) || '',
    currencyId: invoice?.currencyId || 1,
    currency: invoice?.currency || 1,
    accountingDescription: invoice.accountingDescription,
    productDescription: invoice?.productDescription || '',
    products:
      invoice?.products && invoice.products.length > 0
        ? invoice.products
        : [{ ...INITIAL_PRODUCT_ITEM }],
    isBooked: invoice?.isBooked || false,
    expenseTypeId: invoice?.expenseTypeId || '',
    isPaid: invoice?.isPaid || false,
    documentType: invoice?.documentType || '',
    companyId: invoice?.companyId || '',
    counterPartyId: invoice?.counterPartyId || '',
    profitAndLossAccountId: invoice.profitAndLossAccountId || '',
    isReceiverVerified: invoice.isReceiverVerified || false,
    isSupplierVerified: invoice.isSupplierVerified || false,
    isInvoiceDataVerified: invoice.isInvoiceDataVerified || false,
    areProductsVerified: invoice.areProductsVerified || false,
    isPaidIsBookedVerified: invoice.isPaidIsBookedVerified || false
  } as Invoice);

export const mapInvoiceForReceiverSubmit = (
  invoice: Company
): InvoicePatch => ({
  companyId: invoice?.id
});

export const mapInvoiceForSupplierSubmit = (
  invoice: Invoice
): InvoicePatch => ({
  counterPartyId: invoice.counterPartyId,
  counterPartyName: invoice.counterPartyName,
  counterPartyVatNumber: invoice.counterPartyVatNumber,
  counterPartyRegistrationNumber: invoice.counterPartyRegistrationNumber,
  profitAndLossAccountId: invoice.profitAndLossAccountId,
  expenseTypeId: invoice.expenseTypeId,
  products: invoice.products
});

export const mapInvoiceForInvoiceSubmit = (invoice: Invoice): InvoicePatch => ({
  accountingDescription: invoice.accountingDescription,
  currencyId: invoice.currencyId,
  documentType: invoice.documentType,
  dueDate: invoice.dueDate,
  expenseTypeId: invoice.expenseTypeId,
  invoiceAmount: invoice.invoiceAmount,
  invoiceDate: invoice.invoiceDate,
  invoiceNumber: invoice.invoiceNumber,
  products: invoice.products,
  profitAndLossAccountId: invoice.profitAndLossAccountId,
  vatAmount: invoice.vatAmount,
  vatBase: invoice.vatBase
});

export const mapInvoiceForProductsSubmit = (
  invoice: Invoice
): InvoicePatch => ({
  products: invoice.products
});

export const mapInvoiceForStatusSubmit = (invoice: Invoice): InvoicePatch => ({
  isBooked: invoice.isBooked,
  isPaid: invoice.isPaid
});
