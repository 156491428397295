import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { Company, Prompt } from 'openapi';

import { usePromptController } from 'api/controllers/PromptController';
import { useCompanyController } from 'api/controllers/CompanyController';

import { Layout } from 'components/Layout/Layout';
import { CreateUpdateCompanyForm } from 'components/forms/CreateUpdateCompanyForm';

import { useTranslations } from 'context/TranslationContext';

import { ROUTE_COMPANIES_SETTINGS } from 'utils/routes';

import { mediumVerificationFieldWidth } from 'styles/pages/InvoiceVerificationStyle';

export const AddCompany: React.FC = (): JSX.Element | null => {
  const { translate } = useTranslations();
  const { getPrompts } = usePromptController();
  const { getCompanyDetailsById, deleteCompany } = useCompanyController();
  const { id } = useParams();
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState<Company | undefined>();
  const [prompts, setPrompts] = useState<Prompt[]>([]);

  const onDelete = async () => {
    await deleteCompany(Number(id));
    navigate(ROUTE_COMPANIES_SETTINGS);
  };

  useEffect(() => {
    const fetchDetails = async () => {
      const compDetails = await getCompanyDetailsById(Number(id));
      setCompanyDetails(compDetails[0]);
      const allPrompts = await getPrompts();
      setPrompts(allPrompts);
    };

    if (id) {
      fetchDetails();
    }
  }, []);

  return (
    <Layout pageTitle={translate('pages.addCompany')}>
      <Box sx={mediumVerificationFieldWidth}>
        <CreateUpdateCompanyForm
          companyDetails={companyDetails}
          prompts={prompts}
          onDelete={onDelete}
        />
      </Box>
    </Layout>
  );
};
