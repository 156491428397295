/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromptCreate } from '../models/PromptCreate';
import type { PromptListResponse } from '../models/PromptListResponse';
import type { PromptPatch } from '../models/PromptPatch';
import type { PromptResponse } from '../models/PromptResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PromptService {

    /**
     * Returns a list of all prompts
     * @returns PromptListResponse OK
     * @throws ApiError
     */
    public static getAllPrompts(): CancelablePromise<PromptListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/prompts',
            errors: {
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Create a new prompt
     * @param requestBody The prompt object
     * @returns PromptResponse OK
     * @throws ApiError
     */
    public static createPrompt(
        requestBody: PromptCreate,
    ): CancelablePromise<PromptResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/prompts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Returns the openAPI prompt by id
     * @param id The prompt id
     * @returns PromptResponse OK
     * @throws ApiError
     */
    public static getPromptById(
        id: number,
    ): CancelablePromise<PromptResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/prompts/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Modify the api prompt
     * @param id The prompt id
     * @param requestBody
     * @returns PromptResponse OK
     * @throws ApiError
     */
    public static patchPrompt(
        id: number,
        requestBody: PromptPatch,
    ): CancelablePromise<PromptResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/prompts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * delete the openAPI prompt by id
     * @param id The prompt id
     * @returns void
     * @throws ApiError
     */
    public static deletePromptById(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/prompts/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
