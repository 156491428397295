import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
  SelectChangeEvent,
  FormControlLabel,
  Switch,
  Tooltip,
  AutocompleteRenderInputParams
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Field,
  Form,
  Formik,
  ErrorMessage,
  FormikErrors,
  FieldArray,
  ArrayHelpers,
  FormikState,
  FormikTouched
} from 'formik';
import * as Yup from 'yup';

import {
  ExpenseType,
  DocumentType,
  Invoice,
  Company,
  CounterPartyDropDown,
  AccountInfo,
  InvoiceProduct,
  CurrencyDropDown,
  InvoiceStages,
  InvoiceSection
} from 'openapi';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';
import { useCompanyController } from 'api/controllers/CompanyController';
import { useInvoiceController } from 'api/controllers/InvoiceController';

import { usePermissions } from 'context/PermissionsContext';
import { useTranslations } from 'context/TranslationContext';

import { ActionButtons } from 'components/forms/ActionButtons';
import { DecimalField } from 'components/shared/DecimalField/DecimalField';
import { DateMaskField } from 'components/shared/fields/DateField/DateMaskField';

import {
  INITIAL_PRODUCT_ITEM,
  INITIAL_TOUCHED_FIELDS,
  VAT_NUMBER_PREFIX_BULGARIA,
  INITIAL_TOUCHED_RECEIVER_FIELDS,
  INITIAL_TOUCHED_SUPPLIER_FIELDS,
  INVOICE_VERIFICATION_KEYS_IN_ORDER
} from 'utils/constants/invoices';
import { InvoiceVerificationMapper, SectionIndex } from 'utils/enums/Invoice';
import { InvoiceSectionKey } from 'utils/interfaces/InvoiceProps';
import { ROUTE_HOME } from 'utils/routes';
import {
  mapInvoice,
  mapInvoiceForReceiverSubmit,
  mapInvoiceForSupplierSubmit,
  mapInvoiceForInvoiceSubmit,
  mapInvoiceForProductsSubmit,
  mapInvoiceForStatusSubmit
} from 'utils/mappers/invoice';

import {
  verificationFormFields,
  verificationFormItem,
  verificationsButtonsWrapper,
  verificationFieldsWrapper,
  smallVerificationFieldWidth,
  extraSmallVerificationFieldWidth,
  warningFieldStyle,
  verificationFormWrapper,
  accordionHeader,
  verificationFormItemFields,
  accordionBackgound,
  activeAccordionBackgound,
  verificationFormFieldsWrapper,
  CheckCircleIconStyle
} from 'styles/pages/InvoiceVerificationStyle';
import {
  arrayFieldFormWrapper,
  arrayFieldWrapper
} from 'styles/components/Common';

import { useFormHelpers } from 'hooks/useFormHelpers';

interface VerificationInvoiceFormProps {
  currentInvoice: Invoice;
}

type InvoiceVerificationKey = keyof typeof InvoiceVerificationMapper;

export const VerificationInvoiceForm = React.memo(
  ({ currentInvoice }: VerificationInvoiceFormProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { companyId } = useParams();

    const permissions = usePermissions();

    const { translate } = useTranslations();

    const {
      getExpenseTypes,
      getDocumentTypes,
      getCounterPartyValues,
      getAllPlAccounts,
      getCurrencies
    } = useAdditionalFieldsController();
    const { getAllCompanies } = useCompanyController();
    const { updateInvoice, verifyInvoiceSection } = useInvoiceController();

    const {
      getTitleHint,
      renderAccountLabel,
      getOptionLabelReceiverName,
      getOptionLabelSupplier,
      getOptionLabelReceiverCRN,
      getOptionLabelCRN
    } = useFormHelpers();

    // States and variables
    const [formValues, setFormValues] = useState<Invoice>(
      mapInvoice(currentInvoice)
    );

    const [initialFormValues, setInitialFormValues] = useState<Invoice>(
      mapInvoice(currentInvoice)
    );

    const [allCompanies, setAllCompanies] = useState<Company[]>([]);
    const [expenseTypeOptions, setExpenseTypeOptions] = useState<ExpenseType[]>(
      []
    );
    const [documentTypeOptions, setDocumentTypeOptions] = useState<
      DocumentType[]
    >([]);
    const [counterPartyOptions, setCounterPartyOptions] = useState<
      CounterPartyDropDown[]
    >([]);

    const [plAccountOptions, setPlAccountOptions] = useState<AccountInfo[]>([]);
    const [currencies, setCurrencies] = useState<CurrencyDropDown[]>([]);
    const [editSection, setEditSection] = useState<string>('');
    const [expandedSection, setExpandedSection] = useState<string[]>([]);
    const [currentStage, setCurrentStage] = useState<string | undefined>(
      currentInvoice.stage
    );

    // Effects
    useEffect(() => {
      const fetchData = async () => {
        const allCompaniesValues = await getAllCompanies();
        setAllCompanies(allCompaniesValues);

        const expenseTypeOptionValues = await getExpenseTypes();
        setExpenseTypeOptions(expenseTypeOptionValues);

        const documentTypeOptionValues = await getDocumentTypes();
        setDocumentTypeOptions(documentTypeOptionValues);

        const counterPartyOptionValues =
          currentInvoice.companyId &&
          (await getCounterPartyValues(Number(currentInvoice.companyId)));
        setCounterPartyOptions(counterPartyOptionValues);

        const plAccounts = await getAllPlAccounts(Number(companyId));
        setPlAccountOptions(plAccounts);

        const allCurrencies = await getCurrencies();
        setCurrencies(allCurrencies);
      };

      setCurrentStage(currentInvoice.stage);

      fetchData();
    }, [
      companyId,
      getAllPlAccounts,
      getCounterPartyValues,
      getDocumentTypes,
      getExpenseTypes,
      getCurrencies,
      getAllCompanies,
      currentInvoice.stage,
      currentInvoice.companyId
    ]);

    useEffect(() => {
      setFormValues(mapInvoice(currentInvoice));
      setInitialFormValues(mapInvoice(currentInvoice));
    }, [currentInvoice]);

    useEffect(() => {
      setFormValues({
        ...formValues,
        counterPartyVatNumber:
          (counterPartyOptions &&
            counterPartyOptions.find(
              (op) => op.id === formValues.counterPartyId
            )?.vatNumber) ||
          formValues.counterPartyVatNumber ||
          ''
      });
    }, [counterPartyOptions, formValues.counterPartyId]);

    const indexOfFirstFalse = INVOICE_VERIFICATION_KEYS_IN_ORDER.findIndex(
      (prop) => !(formValues as any)[prop]
    );

    // Handlers
    const selectedCounterParty = useMemo(
      () =>
        counterPartyOptions &&
        counterPartyOptions.find(
          (option) => option.id === formValues.counterPartyId
        ),
      [counterPartyOptions, formValues.counterPartyId]
    );

    // Validation

    const validationSchemaSupplier = useMemo(
      () =>
        Yup.object().shape({
          counterPartyName: Yup.string().test(
            'has-counter-party-id',
            translate('errors.counterPartyName'),
            () => {
              return (
                !!formValues.counterPartyId || !!formValues.counterPartyName
              );
            }
          ),
          counterPartyRegistrationNumber: Yup.string().test(
            'has-counter-party-id',
            translate('errors.counterPartyRegistrationNumber'),
            () => {
              return (
                !!formValues.counterPartyId ||
                !!formValues.counterPartyRegistrationNumber
              );
            }
          ),
          counterPartyVatNumber: Yup.string().test(
            'has-counter-party-id',
            translate('errors.counterPartyVatNumber'),
            () => {
              return (
                !!formValues.counterPartyId ||
                !!formValues.counterPartyVatNumber
              );
            }
          )
        }),
      [
        formValues.counterPartyId,
        formValues.counterPartyName,
        formValues.counterPartyRegistrationNumber,
        formValues.counterPartyVatNumber,
        translate
      ]
    );

    const validationSchemaInvoice = useMemo(
      () =>
        Yup.object().shape({
          invoiceNumber: Yup.string()
            .required(translate('errors.invoiceNumber'))
            .when('counterPartyVatNumber', (counterPartyVatNumber, schema) => {
              const vatNumberStr = String(counterPartyVatNumber || '');
              return vatNumberStr?.startsWith(VAT_NUMBER_PREFIX_BULGARIA)
                ? schema
                    .required(translate('errors.invoiceNumber'))
                    .matches(/^\d{10}$/, translate('errors.invoiceNumberBG'))
                : schema.required(translate('errors.invoiceNumber'));
            }),
          invoiceDate: Yup.string().required(translate('errors.invoiceDate')),
          dueDate: Yup.string().required(translate('errors.dueDate')),
          vatBase: Yup.number().required(translate('errors.vatBase')),
          vatAmount: Yup.number(),
          invoiceAmount: Yup.number()
            .required(translate('errors.invoiceAmount'))
            .test(
              'is-equal',
              translate('errors.invoiceAmountEqual'),
              // eslint-disable-next-line func-names
              function (value) {
                // eslint-disable-next-line react/no-this-in-sfc
                const { vatBase } = this.parent;
                // eslint-disable-next-line react/no-this-in-sfc
                const vatAmount = this.parent.vatAmount || 0;
                const sum = Number((vatBase + vatAmount).toFixed(2));
                return sum === value;
              }
            )
        }),
      [translate]
    );

    // Render helpers

    const renderInputSupplier = useCallback(
      (
        params: Record<string, string>,
        errors: FormikErrors<Invoice>,
        touched: FormikTouched<Invoice>
      ) => (
        <TextField
          {...params}
          label={translate('labels.name')}
          placeholder={translate('labels.name')}
          error={
            (touched?.counterPartyName || touched?.counterPartyId) &&
            !!errors?.counterPartyName
          }
          helperText={<ErrorMessage name="counterPartyName" />}
          required
        />
      ),
      [translate]
    );

    const renderInputCRNSupplier = useCallback(
      (
        params: {
          inputProps: React.InputHTMLAttributes<HTMLInputElement>;
        } & any,
        values: Invoice,
        errors: FormikErrors<Invoice>,
        touched: FormikTouched<Invoice>
      ) => {
        return (
          <TextField
            {...params}
            label={translate('labels.counterPartyRegistrationNumber')}
            error={
              (touched.counterPartyRegistrationNumber ||
                touched.counterPartyId) &&
              !!errors.counterPartyRegistrationNumber
            }
            helperText={<ErrorMessage name="counterPartyRegistrationNumber" />}
            title={getTitleHint('counterPartyId', values)}
            required
            inputProps={{
              ...params.inputProps,
              onInput: (e: React.FormEvent<HTMLInputElement>) => {
                const target = e.target as HTMLInputElement;
                target.value = target.value.replace(/\D/g, '');
              }
            }}
          />
        );
      },
      [getTitleHint, translate]
    );

    const onRenderInputAccountId = useCallback(
      (params: any) => (
        <TextField
          {...params}
          label={translate('labels.pnlAccount')}
          InputProps={{
            ...params.InputProps,
            title: params.inputProps.value
          }}
        />
      ),
      [translate]
    );

    const updateInvoiceData = useCallback(
      async (invoiceSection: InvoiceSectionKey, requestBody: Invoice) => {
        await updateInvoice(
          currentInvoice.id as number,
          invoiceSection,
          requestBody
        );
      },
      [currentInvoice.id, updateInvoice]
    );

    const onClickVerifySection = useCallback(
      async (
        section: InvoiceSectionKey,
        verification: InvoiceVerificationKey
      ) => {
        if (section === InvoiceSection.RECEIVER) {
          setFormValues({
            ...formValues
          });
        }
        if (
          section === InvoiceSection.INVOICE_DATA &&
          currentInvoice.expenseTypeId === null
        ) {
          const requestBody = {
            invoiceNumber: formValues.invoiceNumber,
            counterPartyId: formValues.counterPartyId,
            companyId: formValues.companyId,
            expenseTypeId: 1
          };

          await updateInvoiceData(section, requestBody);
        } else if (section === InvoiceSection.IS_PAID_IS_BOOKED) {
          setCurrentStage(InvoiceStages.FINALIZED);
        }
        try {
          await verifyInvoiceSection(currentInvoice.id as number, section);

          setFormValues({
            ...formValues,
            [verification]: true
          });

          if (verification in InvoiceVerificationMapper) {
            setExpandedSection([InvoiceVerificationMapper[verification]]);
          } else {
            console.warn(`Unknown verification key: ${verification}`);
          }
        } catch (error) {
          console.error(`Failed to verify invoice section: ${error}`);
        }
      },
      [
        currentInvoice.expenseTypeId,
        currentInvoice.id,
        formValues,
        updateInvoiceData,
        verifyInvoiceSection
      ]
    );

    const getFirstKey = useCallback(() => {
      return INVOICE_VERIFICATION_KEYS_IN_ORDER.find(
        (key) => currentInvoice[key as keyof Invoice] === false
      ) as string;
    }, [currentInvoice]);

    useEffect(() => {
      if (currentInvoice) {
        setExpandedSection([getFirstKey()]);
      }
    }, [currentInvoice, getFirstKey]);

    const handleSave = useCallback(
      (values: Invoice, section: InvoiceSectionKey) => {
        let requestBody;

        setEditSection('');
        let newValues = {};

        switch (section) {
          case InvoiceSection.RECEIVER:
            {
              const company = allCompanies.find(
                (companyObj) => companyObj.id === values.companyId
              );
              newValues = {
                isReceiverVerified: false,
                isSupplierVerified: false,
                isInvoiceDataVerified: false,
                areProductsVerified: false,
                isPaidIsBookedVerified: false
              };
              requestBody = mapInvoiceForReceiverSubmit(company as Company);
            }
            break;
          case InvoiceSection.SUPPLIER:
            newValues = {
              isSupplierVerified: false,
              isInvoiceDataVerified: false,
              areProductsVerified: false,
              isPaidIsBookedVerified: false
            };
            requestBody = mapInvoiceForSupplierSubmit(values);
            break;
          case InvoiceSection.INVOICE_DATA:
            newValues = {
              isInvoiceDataVerified: false,
              areProductsVerified: false,
              isPaidIsBookedVerified: false
            };
            requestBody = mapInvoiceForInvoiceSubmit(values);
            break;
          case InvoiceSection.PRODUCTS:
            newValues = {
              areProductsVerified: false,
              isPaidIsBookedVerified: false
            };
            requestBody = mapInvoiceForProductsSubmit(values);
            break;
          case InvoiceSection.IS_PAID_IS_BOOKED:
            requestBody = mapInvoiceForStatusSubmit(values);
            break;
          default:
            requestBody = {};
            break;
        }

        setFormValues({
          ...values,
          ...newValues
        });
        updateInvoiceData(section, requestBody);
      },
      [allCompanies, updateInvoiceData]
    );

    const onChangeSupplier = (
      _: React.ChangeEvent<{}> | null,
      value: CounterPartyDropDown,
      values: Invoice,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => Promise<void | FormikErrors<Invoice>>
    ) => {
      if (typeof value === 'string' || !value) {
        return;
      }

      const newProducts = values.products?.map((product) => {
        return {
          ...product,
          accountId: value.profitAndLossAccount?.id
        };
      });

      if (value) {
        setFormValues({
          ...values,
          counterPartyId: value?.id,
          counterPartyName: value?.name || values.counterPartyName,
          counterPartyVatNumber:
            value?.vatNumber || values.counterPartyVatNumber,
          profitAndLossAccountId: value.profitAndLossAccount?.id,
          expenseTypeId: value.expenseTypeId || values.expenseTypeId,
          products: newProducts
        });

        setFieldValue(
          'counterPartyRegistrationNumber',
          value?.counterPartyRegistrationNumber
        );
        setFieldValue(
          'counterPartyName',
          value?.name || values.counterPartyName
        );
        setFieldValue(
          'counterPartyVatNumber',
          value?.vatNumber || values.counterPartyVatNumber
        );

        setFieldValue('profitAndLossAccountId', value.profitAndLossAccount?.id);
        setFieldValue(
          'expenseTypeId',
          value.expenseTypeId || values.expenseTypeId
        );
        setFieldValue('products', newProducts);
      }
    };

    const onChangeCRNSupplier = (
      _: React.ChangeEvent<{}> | null,
      value: CounterPartyDropDown,
      values: Invoice,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => Promise<void | FormikErrors<Invoice>>
    ) => {
      if (typeof value === 'string' || !value) {
        return;
      }

      const newProducts = values.products?.map((product) => {
        return {
          ...product,
          accountId: value.profitAndLossAccount?.id
        };
      });

      setFormValues({
        ...values,
        counterPartyId: value?.id,
        counterPartyName: value?.name || values.counterPartyName,
        counterPartyVatNumber: value?.vatNumber || values.counterPartyVatNumber,
        profitAndLossAccountId: value.profitAndLossAccount?.id,
        expenseTypeId: value.expenseTypeId || values.expenseTypeId,
        products: newProducts
      });

      setFieldValue(
        'counterPartyRegistrationNumber',
        value?.counterPartyRegistrationNumber
      );
      setFieldValue('counterPartyName', value?.name || values.counterPartyName);
      setFieldValue(
        'counterPartyVatNumber',
        value?.vatNumber || values.counterPartyVatNumber
      );

      setFieldValue(
        'counterPartyVatNumber',
        value?.vatNumber || values.counterPartyVatNumber
      );

      setFieldValue('profitAndLossAccountId', value.profitAndLossAccount?.id);
      setFieldValue(
        'expenseTypeId',
        value.expenseTypeId || values.expenseTypeId
      );
      setFieldValue('products', newProducts);
    };

    const onChangeExpenseType = useCallback(
      (
        event: SelectChangeEvent<number>,
        values: Invoice,
        setFieldValue: (
          field: string,
          value: any,
          shouldValidate?: boolean | undefined
        ) => Promise<void | FormikErrors<Invoice>>
      ) => {
        setFormValues({
          ...values,
          expenseTypeId: event.target.value as unknown as number
        });
        setFieldValue('expenseTypeId', event.target.value);
      },
      []
    );

    const onChangeCompany = useCallback(
      async (
        event: SelectChangeEvent<string>,
        values: Invoice,
        setFieldValue: (
          field: string,
          value: any,
          shouldValidate?: boolean | undefined
        ) => Promise<void | FormikErrors<Invoice>>
      ) => {
        const counterPartyOptionValues = await getCounterPartyValues(
          Number(event.target.value)
        );

        let counterPartyRegistrationNumberToBeSet: Invoice = {};

        if (initialFormValues.counterPartyRegistrationNumber) {
          counterPartyRegistrationNumberToBeSet = initialFormValues;
        } else {
          const previousCounterParties =
            initialFormValues.companyId &&
            (await getCounterPartyValues(Number(initialFormValues.companyId)));

          counterPartyRegistrationNumberToBeSet =
            previousCounterParties &&
            previousCounterParties.find(
              (op: any) => op.id === formValues.counterPartyId
            );
        }

        setCounterPartyOptions(counterPartyOptionValues);

        setFormValues({
          ...values,
          counterPartyRegistrationNumber:
            counterPartyRegistrationNumberToBeSet.counterPartyRegistrationNumber,
          companyId: Number(event.target.value)
        });
        setFieldValue('companyId', event.target.value);
        setFieldValue(
          'counterPartyRegistrationNumber',
          counterPartyRegistrationNumberToBeSet.counterPartyRegistrationNumber
        );
      },
      [formValues.counterPartyId, getCounterPartyValues, initialFormValues]
    );

    const onChangeCurrency = useCallback(
      (
        event: SelectChangeEvent<string>,
        values: Invoice,
        setFieldValue: (
          field: string,
          value: any,
          shouldValidate?: boolean | undefined
        ) => Promise<void | FormikErrors<Invoice>>
      ) => {
        setFormValues({
          ...values,
          currency: event.target.value,
          currencyId: Number(event.target.value)
        });
        setFieldValue('currencyId', event.target.value);
      },
      []
    );

    const onChangeAccountId = useCallback(
      (
        _: React.ChangeEvent<{}> | null,
        value: CounterPartyDropDown,
        values: Invoice,
        setFieldValue: (
          field: string,
          value: any,
          shouldValidate?: boolean | undefined
        ) => Promise<void | FormikErrors<Invoice>>,
        product: InvoiceProduct,
        productIndex: number
      ) => {
        const newProduct = values.products?.map((currentProduct) =>
          currentProduct === product
            ? {
                ...currentProduct,
                accountId: value ? value.id : undefined
              }
            : currentProduct
        );

        setFormValues({
          ...values,
          products: newProduct
        });

        setFieldValue(
          `products.${productIndex}.accountId`,
          value ? value.id : undefined
        );
      },
      []
    );

    const onChangeProfitAndLossAccountId = useCallback(
      (
        _: React.ChangeEvent<{}> | null,
        value: CounterPartyDropDown,
        values: Invoice,
        setFieldValue: (
          field: string,
          value: any,
          shouldValidate?: boolean | undefined
        ) => Promise<void | FormikErrors<Invoice>>
      ) => {
        let formProfitAndLossAccountId: number | undefined;
        let newProducts: InvoiceProduct[] | undefined;

        if (value) {
          formProfitAndLossAccountId = value.id;

          newProducts = values.products?.map((product) => {
            return {
              ...product,
              accountId: value.id
            };
          });
        } else {
          formProfitAndLossAccountId = undefined;

          // When value is null (i.e., user has cleared the field), you might also want to clear the accountIds of all products
          newProducts = values.products?.map((product) => {
            return { ...product, accountId: undefined };
          });
        }

        setFormValues({
          ...values,
          profitAndLossAccountId: formProfitAndLossAccountId,
          products: newProducts
        });

        setFieldValue('profitAndLossAccountId', formProfitAndLossAccountId);
        setFieldValue('products', newProducts);
      },
      []
    );

    const onChangeStatus = useCallback(
      (
        property: string,
        event: React.ChangeEvent<HTMLInputElement>,
        values: Invoice
      ) => {
        setFormValues({
          ...values,
          [property]: event.target.checked
        });
      },
      []
    );

    const onChangeDocumentType = useCallback(
      (
        event: SelectChangeEvent<number>,
        values: Invoice,
        setFieldValue: (
          field: string,
          value: any,
          shouldValidate?: boolean | undefined
        ) => Promise<void | FormikErrors<Invoice>>
      ) => {
        setFormValues({
          ...values,
          documentType: event.target.value as number
        });
        setFieldValue('documentType', event.target.value);
      },
      []
    );

    const onInputChangeSupplier = (
      event: React.ChangeEvent<{}> | null,
      value: number,
      values: CounterPartyDropDown,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => Promise<void | FormikErrors<Invoice>>
    ) => {
      if (event === null) {
        return;
      }
      let newValues = {};

      if (typeof value !== 'string') {
        newValues = {
          ...values,
          counterPartyId: value
        };
      } else {
        newValues = {
          ...values,
          counterPartyName: value,
          counterPartyId: undefined
        };
      }
      setFormValues({
        ...values,
        ...newValues
      });
      setFieldValue('counterPartyName', value);
    };

    const onInputChangeCRNSupplier = (
      event: React.ChangeEvent<{}> | null,
      value: number,
      values: CounterPartyDropDown,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => Promise<void | FormikErrors<Invoice>>
    ) => {
      if (event === null) {
        return;
      }

      if (typeof value !== 'string') {
        setFormValues({
          ...values,
          counterPartyId: value
        });
        setFieldValue('counterPartyRegistrationNumber', value);
      } else {
        setFormValues({
          ...values,
          counterPartyRegistrationNumber: value,
          counterPartyId: undefined
        });
        setFieldValue('counterPartyRegistrationNumber', value);
      }
    };

    const onClickRemoveProduct = useCallback(
      (arrayHelpers: ArrayHelpers, index: number) =>
        editSection === 'products' && arrayHelpers.remove(index),
      [editSection]
    );

    const onClickAddProduct = useCallback(
      (arrayHelpers: ArrayHelpers) =>
        editSection === 'products' &&
        arrayHelpers.push({ ...INITIAL_PRODUCT_ITEM }),
      [editSection]
    );

    const onClickEditSection = useCallback(
      (
        section: string,
        setFieldValue?: (
          field: string,
          value: any,
          shouldValidate?: boolean | undefined
        ) => Promise<void | FormikErrors<Invoice>>
      ) => {
        setEditSection(section);

        let newValues = {};
        if (setFieldValue) {
          switch (editSection) {
            case 'receiver':
              newValues = {
                companyId: initialFormValues.companyId,
                isReceiverVerified: formValues.isReceiverVerified,
                isSupplierVerified: formValues.isSupplierVerified,
                isInvoiceDataVerified: formValues.isInvoiceDataVerified,
                areProductsVerified: formValues.areProductsVerified,
                isPaidIsBookedVerified: formValues.isPaidIsBookedVerified
              };

              setFieldValue('name', initialFormValues.id);
              setFieldValue(
                'companyRegistrationNumber',
                initialFormValues.counterPartyRegistrationNumber
              );
              break;
            case 'supplier':
              if (initialFormValues.counterPartyId) {
                newValues = {
                  counterPartyId: initialFormValues.counterPartyId,
                  profitAndLossAccountId:
                    initialFormValues.profitAndLossAccountId,
                  expenseTypeId: initialFormValues.expenseTypeId,
                  isReceiverVerified: formValues.isReceiverVerified,
                  isSupplierVerified: formValues.isSupplierVerified,
                  isInvoiceDataVerified: formValues.isInvoiceDataVerified,
                  areProductsVerified: formValues.areProductsVerified,
                  isPaidIsBookedVerified: formValues.isPaidIsBookedVerified
                };
                setFieldValue(
                  'counterPartyVatNumber',

                  (counterPartyOptions &&
                    counterPartyOptions.find(
                      (op) => op.id === formValues.counterPartyId
                    )?.vatNumber) ||
                    formValues.counterPartyVatNumber ||
                    ''
                );
              } else {
                newValues = {
                  counterPartyName: initialFormValues.counterPartyName,
                  counterPartyRegistrationNumber:
                    initialFormValues.counterPartyRegistrationNumber,
                  counterPartyVatNumber:
                    initialFormValues.counterPartyVatNumber,
                  profitAndLossAccountId:
                    initialFormValues.profitAndLossAccountId,
                  expenseTypeId: initialFormValues.expenseTypeId,
                  isReceiverVerified: formValues.isReceiverVerified,
                  isSupplierVerified: formValues.isSupplierVerified,
                  isInvoiceDataVerified: formValues.isInvoiceDataVerified,
                  areProductsVerified: formValues.areProductsVerified,
                  isPaidIsBookedVerified: formValues.isPaidIsBookedVerified
                };
                setFieldValue(
                  'counterPartyVatNumber',
                  initialFormValues.counterPartyVatNumber
                );
                setFieldValue(
                  'counterPartyRegistrationNumber',
                  initialFormValues.counterPartyRegistrationNumber
                );
              }

              setFieldValue(
                'counterPartyName',
                initialFormValues.counterPartyName
              );
              setFieldValue(
                'counterPartyRegistrationNumber',
                initialFormValues.counterPartyRegistrationNumber
              );
              setFieldValue(
                'profitAndLossAccountId',
                initialFormValues.profitAndLossAccountId
              );
              setFieldValue('expenseTypeId', initialFormValues.expenseTypeId);
              setFieldValue(
                'counterPartyRegistrationNumber',
                initialFormValues.counterPartyRegistrationNumber
              );
              break;
            case 'invoice':
              newValues = {
                invoiceNumber: initialFormValues.invoiceNumber,
                invoiceDate: initialFormValues.invoiceDate,
                dueDate: initialFormValues.dueDate,
                vatBase: initialFormValues.vatBase,
                vatAmount: initialFormValues.vatAmount,
                invoiceAmount: initialFormValues.invoiceAmount,
                currencyId: initialFormValues.currencyId,
                profitAndLossAccountId:
                  initialFormValues.profitAndLossAccountId,
                expenseTypeId: initialFormValues.expenseTypeId,
                documentType: initialFormValues.documentType,
                accountingDescription: initialFormValues.accountingDescription,
                isReceiverVerified: formValues.isReceiverVerified,
                isSupplierVerified: formValues.isSupplierVerified,
                isInvoiceDataVerified: formValues.isInvoiceDataVerified,
                areProductsVerified: formValues.areProductsVerified,
                isPaidIsBookedVerified: formValues.isPaidIsBookedVerified
              };
              setFieldValue('invoiceNumber', initialFormValues.invoiceNumber);
              setFieldValue('invoiceDate', initialFormValues.invoiceDate);
              setFieldValue('dueDate', initialFormValues.dueDate);
              setFieldValue('vatBase', initialFormValues.vatBase);
              setFieldValue('vatAmount', initialFormValues.vatAmount);
              setFieldValue('invoiceAmount', initialFormValues.invoiceAmount);
              setFieldValue('currencyId', initialFormValues.currencyId);
              setFieldValue(
                'profitAndLossAccountId',
                initialFormValues.profitAndLossAccountId
              );
              setFieldValue('documentType', initialFormValues.documentType);
              setFieldValue(
                'accountingDescription',
                initialFormValues.accountingDescription
              );
              break;
            case 'products':
              newValues = {
                products: initialFormValues.products,
                isReceiverVerified: formValues.isReceiverVerified,
                isSupplierVerified: formValues.isSupplierVerified,
                isInvoiceDataVerified: formValues.isInvoiceDataVerified,
                areProductsVerified: formValues.areProductsVerified,
                isPaidIsBookedVerified: formValues.isPaidIsBookedVerified
              };
              setFieldValue('products', initialFormValues.products);
              break;
            case 'status':
              newValues = {
                isBooked: initialFormValues.isBooked,
                isPaid: initialFormValues.isPaid,
                isReceiverVerified: formValues.isReceiverVerified,
                isSupplierVerified: formValues.isSupplierVerified,
                isInvoiceDataVerified: formValues.isInvoiceDataVerified,
                areProductsVerified: formValues.areProductsVerified,
                isPaidIsBookedVerified: formValues.isPaidIsBookedVerified
              };
              setFieldValue('isBooked', initialFormValues.isBooked);
              setFieldValue('isPaid', initialFormValues.isPaid);
              break;
            default:
              newValues = {
                isReceiverVerified: formValues.isReceiverVerified,
                isSupplierVerified: formValues.isSupplierVerified,
                isInvoiceDataVerified: formValues.isInvoiceDataVerified,
                areProductsVerified: formValues.areProductsVerified,
                isPaidIsBookedVerified: formValues.isPaidIsBookedVerified
              };
              break;
          }

          setFormValues({
            ...formValues,
            ...newValues
          });
        }
      },
      [
        editSection,
        formValues,
        initialFormValues.companyId,
        initialFormValues.id,
        initialFormValues.counterPartyRegistrationNumber,
        initialFormValues.counterPartyId,
        initialFormValues.counterPartyName,
        initialFormValues.invoiceNumber,
        initialFormValues.invoiceDate,
        initialFormValues.dueDate,
        initialFormValues.vatBase,
        initialFormValues.vatAmount,
        initialFormValues.invoiceAmount,
        initialFormValues.currencyId,
        initialFormValues.profitAndLossAccountId,
        initialFormValues.expenseTypeId,
        initialFormValues.documentType,
        initialFormValues.accountingDescription,
        initialFormValues.products,
        initialFormValues.isBooked,
        initialFormValues.isPaid,
        initialFormValues.counterPartyVatNumber,
        counterPartyOptions
      ]
    );

    const onRenderProducts = useCallback(
      (
        arrayHelpers: ArrayHelpers,
        values: Invoice,
        setFieldValue: (
          field: string,
          value: any,
          shouldValidate?: boolean | undefined
        ) => Promise<void | FormikErrors<Invoice>>,
        handleSubmit: (
          e?: React.FormEvent<HTMLFormElement> | undefined
        ) => void,
        resetForm: (
          nextState?: Partial<FormikState<Invoice>> | undefined
        ) => void
      ) => {
        return (
          <Box sx={arrayFieldWrapper}>
            {values.products &&
              values.products.length > 0 &&
              values.products.map((product, index) => (
                <Box
                  key={product.id || index}
                  sx={{
                    ...arrayFieldFormWrapper,
                    ...verificationFormItemFields
                  }}
                >
                  <Box sx={{ ...verificationFormFields, width: '100%' }}>
                    <Box sx={verificationFieldsWrapper}>
                      <Field
                        as={TextField}
                        label={translate('labels.product')}
                        name={`products.${index}.description`}
                        title={values?.products?.[index].description || ''}
                        placeholder={translate('labels.product')}
                        sx={smallVerificationFieldWidth}
                        disabled={editSection !== 'products'}
                      />
                      {plAccountOptions?.length > 0 && (
                        <Field
                          name={`products.${index}.accountId`}
                          as={Autocomplete}
                          options={plAccountOptions}
                          getOptionLabel={renderAccountLabel}
                          disabled={
                            !permissions.PL_ACCOUNTS_DROP_DOWN.update ||
                            editSection !== 'products'
                          }
                          value={
                            plAccountOptions.find(
                              (op) => op.id === product.accountId
                            ) ||
                            selectedCounterParty?.profitAndLossAccount ||
                            null
                          }
                          sx={smallVerificationFieldWidth}
                          renderInput={onRenderInputAccountId}
                          onChange={(
                            event: React.ChangeEvent<{}> | null,
                            value: CounterPartyDropDown
                          ) =>
                            onChangeAccountId(
                              event,
                              value,
                              values,
                              setFieldValue,
                              product,
                              index
                            )
                          }
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                        />
                      )}
                      <DecimalField
                        label={translate('labels.total')}
                        name={`products.${index}.total`}
                        placeholder={translate('labels.total')}
                        sx={extraSmallVerificationFieldWidth}
                        disabled={editSection !== 'products'}
                      />
                    </Box>
                  </Box>
                  {values.products && index !== values.products.length - 1 ? (
                    <RemoveCircleOutlineIcon
                      color="primary"
                      sx={{
                        cursor: 'pointer',
                        alignSelf: 'center'
                      }}
                      onClick={() => onClickRemoveProduct(arrayHelpers, index)}
                    />
                  ) : (
                    <AddCircleOutlineIcon
                      color="primary"
                      sx={{
                        cursor: 'pointer',
                        alignSelf: 'center'
                      }}
                      onClick={() => onClickAddProduct(arrayHelpers)}
                    />
                  )}
                </Box>
              ))}
            {currentStage !== InvoiceStages.FINALIZED && (
              <ActionButtons
                editSection={editSection}
                values={values}
                section="products"
                onClickEditSection={onClickEditSection}
                onClickSave={() =>
                  handleSave(values, InvoiceSection.PRODUCTS) as
                    | ((values: Invoice, section: string) => void)
                    | undefined
                }
                handleSubmit={handleSubmit}
                resetForm={resetForm}
                setFieldValue={setFieldValue}
                isEditButtonDisabled={editSection !== ''}
                isVerifyButtonDisabled={
                  indexOfFirstFalse < SectionIndex.PRODUCTS
                }
              />
            )}
          </Box>
        );
      },
      [
        currentStage,
        editSection,
        handleSave,
        indexOfFirstFalse,
        onChangeAccountId,
        onClickAddProduct,
        onClickEditSection,
        onClickRemoveProduct,
        onRenderInputAccountId,
        permissions.PL_ACCOUNTS_DROP_DOWN.update,
        plAccountOptions,
        renderAccountLabel,
        selectedCounterParty?.profitAndLossAccount,
        translate
      ]
    );

    const navigateHome = useCallback(() => {
      if (location.state) {
        navigate(-1);
      } else {
        navigate(ROUTE_HOME);
      }
    }, [navigate, location]);

    const handleChange = useCallback(
      (panel: string) => () => {
        if (expandedSection.includes(panel)) {
          setExpandedSection((prevExpanded) =>
            prevExpanded.filter((sec) => sec !== panel)
          );
        } else {
          setExpandedSection((prevExpanded) => [...prevExpanded, panel]);
        }
      },
      [expandedSection]
    );

    return (
      <Box sx={verificationFormWrapper}>
        <>
          <Accordion
            expanded={expandedSection.includes('isReceiverVerified')}
            onChange={handleChange('isReceiverVerified')}
            sx={
              editSection === 'receiver'
                ? activeAccordionBackgound
                : accordionBackgound
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={accordionHeader}
            >
              {translate('labels.receiver')}
              {formValues.isReceiverVerified && (
                <Tooltip title={currentInvoice.stage}>
                  <CheckCircleIcon sx={CheckCircleIconStyle} />
                </Tooltip>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Formik
                key={currentInvoice.id}
                onSubmit={() =>
                  onClickVerifySection(
                    InvoiceSection.RECEIVER,
                    'isReceiverVerified'
                  )
                }
                initialValues={formValues}
                initialTouched={INITIAL_TOUCHED_RECEIVER_FIELDS}
                validateOnMount
                enableReinitialize
              >
                {({ handleSubmit, values, setFieldValue, resetForm }) => (
                  <Form>
                    <Box sx={verificationFormFields}>
                      <Box
                        sx={{
                          ...verificationFormFieldsWrapper,
                          minHeight: '80px'
                        }}
                      >
                        <Field
                          key="name"
                          name="name"
                          as={Autocomplete}
                          options={allCompanies}
                          getOptionLabel={getOptionLabelReceiverName}
                          freeSolo
                          sx={verificationFormItem}
                          value={
                            allCompanies.find(
                              (op) => op.id === values.companyId
                            ) || null
                          }
                          renderInput={renderInputSupplier}
                          disabled
                        />
                        {allCompanies?.length > 0 && (
                          <FormControl sx={verificationFormItem}>
                            <InputLabel>
                              {translate('labels.companyRegistrationNumber')}
                            </InputLabel>
                            <Select
                              name="companyRegistrationNumber"
                              value={
                                (
                                  allCompanies &&
                                  allCompanies.find(
                                    (op) => op.id === values.companyId
                                  )?.id
                                )?.toString() || ''
                              }
                              label={translate(
                                'labels.companyRegistrationNumber'
                              )}
                              onChange={(event: SelectChangeEvent<string>) =>
                                onChangeCompany(event, values, setFieldValue)
                              }
                              disabled={editSection !== 'receiver'}
                            >
                              {allCompanies &&
                                allCompanies.map((company: Company) => (
                                  <MenuItem key={company.id} value={company.id}>
                                    {getOptionLabelReceiverCRN(company)}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )}
                      </Box>
                      {currentStage !== InvoiceStages.FINALIZED && (
                        <ActionButtons
                          editSection={editSection}
                          values={values}
                          section="receiver"
                          onClickEditSection={onClickEditSection}
                          onClickSave={() =>
                            handleSave(values, InvoiceSection.RECEIVER) as
                              | ((values: Invoice, section: string) => void)
                              | undefined
                          }
                          handleSubmit={handleSubmit}
                          resetForm={resetForm}
                          setFieldValue={setFieldValue}
                          isEditButtonDisabled={editSection !== ''}
                        />
                      )}
                    </Box>
                  </Form>
                )}
              </Formik>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandedSection.includes('isSupplierVerified')}
            onChange={handleChange('isSupplierVerified')}
            sx={
              editSection === 'supplier'
                ? activeAccordionBackgound
                : accordionBackgound
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={accordionHeader}
            >
              {translate('labels.supplier')}
              {formValues.isSupplierVerified && (
                <Tooltip title={currentInvoice.stage}>
                  <CheckCircleIcon sx={CheckCircleIconStyle} />
                </Tooltip>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Formik
                key={currentInvoice.id}
                onSubmit={() =>
                  onClickVerifySection(
                    InvoiceSection.SUPPLIER,
                    'isSupplierVerified'
                  )
                }
                initialValues={formValues}
                initialTouched={INITIAL_TOUCHED_SUPPLIER_FIELDS}
                validationSchema={validationSchemaSupplier}
                validateOnMount
                enableReinitialize
              >
                {({
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  resetForm
                }) => (
                  <Form>
                    <Box sx={verificationFormFields}>
                      <Box
                        sx={{
                          ...verificationFormFieldsWrapper,
                          minHeight: '100px'
                        }}
                      >
                        <Field
                          key="counterPartyName"
                          name="counterPartyName"
                          as={Autocomplete}
                          options={counterPartyOptions || []}
                          getOptionLabel={getOptionLabelSupplier}
                          freeSolo
                          sx={verificationFormItem}
                          disabled={editSection !== 'supplier'}
                          value={getOptionLabelSupplier(
                            (counterPartyOptions &&
                              (counterPartyOptions.find(
                                (op) => op.id === values.counterPartyId
                              ) as CounterPartyDropDown)) ||
                              values.counterPartyName
                          )}
                          renderInput={(params: Record<string, string>) =>
                            renderInputSupplier(params, errors, touched)
                          }
                          onChange={(
                            event: React.ChangeEvent<{}> | null,
                            value: CounterPartyDropDown
                          ) =>
                            onChangeSupplier(
                              event,
                              value,
                              values,
                              setFieldValue
                            )
                          }
                          onInputChange={(
                            event: React.ChangeEvent<{}> | null,
                            value: number
                          ) =>
                            onInputChangeSupplier(
                              event,
                              value,
                              values,
                              setFieldValue
                            )
                          }
                        />

                        <Field
                          key="counterPartyRegistrationNumber"
                          name="counterPartyRegistrationNumber"
                          as={Autocomplete}
                          options={counterPartyOptions || []}
                          getOptionLabel={getOptionLabelCRN}
                          freeSolo
                          sx={verificationFormItem}
                          disabled={editSection !== 'supplier'}
                          value={getOptionLabelCRN(
                            (counterPartyOptions &&
                              (counterPartyOptions.find(
                                (op) => op.id === values.counterPartyId
                              ) as CounterPartyDropDown)) ||
                              values.counterPartyRegistrationNumber ||
                              ''
                          )}
                          renderInput={(
                            params: AutocompleteRenderInputParams
                          ) =>
                            renderInputCRNSupplier(
                              {
                                ...params,
                                inputProps: {
                                  ...params.inputProps,
                                  onInput: (e: any) => {
                                    e.currentTarget.value =
                                      e.currentTarget.value.replace(
                                        /[^0-9]/g,
                                        ''
                                      );
                                  }
                                }
                              },
                              values,
                              errors,
                              touched
                            )
                          }
                          onChange={(
                            event: React.ChangeEvent<{}> | null,
                            value: CounterPartyDropDown
                          ) =>
                            onChangeCRNSupplier(
                              event,
                              value,
                              values,
                              setFieldValue
                            )
                          }
                          onInputChange={(
                            event: React.ChangeEvent<{}> | null,
                            value: number
                          ) =>
                            onInputChangeCRNSupplier(
                              event,
                              value,
                              values,
                              setFieldValue
                            )
                          }
                        />
                        <Field
                          as={TextField}
                          label={translate('labels.vatNumber')}
                          name="counterPartyVatNumber"
                          placeholder={translate('labels.vatNumber')}
                          sx={verificationFormItem}
                          disabled={editSection !== 'supplier'}
                          title={getTitleHint('counterPartyVatNumber', values)}
                          error={
                            touched.counterPartyVatNumber &&
                            !!errors.counterPartyVatNumber
                          }
                          helperText={
                            <ErrorMessage name="counterPartyVatNumber" />
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue(
                              'counterPartyVatNumber',
                              event.target.value
                            );

                            setFormValues({
                              ...values,
                              counterPartyVatNumber: event.target.value
                            });
                          }}
                        />
                      </Box>
                      {currentStage !== InvoiceStages.FINALIZED && (
                        <ActionButtons
                          editSection={editSection}
                          values={values}
                          section="supplier"
                          onClickEditSection={onClickEditSection}
                          onClickSave={() =>
                            handleSave(values, InvoiceSection.SUPPLIER) as
                              | ((values: Invoice, section: string) => void)
                              | undefined
                          }
                          handleSubmit={handleSubmit}
                          resetForm={resetForm}
                          setFieldValue={setFieldValue}
                          isEditButtonDisabled={editSection !== ''}
                          isVerifyButtonDisabled={
                            indexOfFirstFalse < SectionIndex.SUPPLIER
                          }
                        />
                      )}
                    </Box>
                  </Form>
                )}
              </Formik>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expandedSection.includes('isInvoiceDataVerified')}
            onChange={handleChange('isInvoiceDataVerified')}
            sx={
              editSection === 'invoice'
                ? activeAccordionBackgound
                : accordionBackgound
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={accordionHeader}
            >
              {translate('labels.invoice')}
              {formValues.isInvoiceDataVerified && (
                <Tooltip title={currentInvoice.stage}>
                  <CheckCircleIcon sx={CheckCircleIconStyle} />
                </Tooltip>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Formik
                key={currentInvoice.id}
                onSubmit={() =>
                  onClickVerifySection(
                    InvoiceSection.INVOICE_DATA,
                    'isInvoiceDataVerified'
                  )
                }
                initialValues={formValues}
                initialTouched={INITIAL_TOUCHED_FIELDS}
                validationSchema={validationSchemaInvoice}
                validateOnMount
                enableReinitialize
              >
                {({
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  resetForm
                }) => (
                  <Form>
                    <Box sx={verificationFormFields}>
                      <Box sx={verificationFormFieldsWrapper}>
                        <Field
                          as={TextField}
                          label={translate('labels.invoiceNumber')}
                          name="invoiceNumber"
                          placeholder={translate('labels.invoiceNumber')}
                          sx={verificationFormItem}
                          error={
                            touched.invoiceNumber && !!errors.invoiceNumber
                          }
                          helperText={<ErrorMessage name="invoiceNumber" />}
                          disabled={editSection !== 'invoice'}
                          required
                        />
                        <DateMaskField
                          name="invoiceDate"
                          label={translate('labels.invoiceDate')}
                          touched={touched}
                          errors={errors}
                          sx={verificationFormItem}
                          disabled={editSection !== 'invoice'}
                        />
                        <DateMaskField
                          name="dueDate"
                          label={translate('labels.dueDate')}
                          touched={touched}
                          errors={errors}
                          sx={verificationFormItem}
                          disabled={editSection !== 'invoice'}
                        />
                      </Box>
                      <Box sx={verificationFormFieldsWrapper}>
                        <DecimalField
                          label={translate('labels.vatBase')}
                          name="vatBase"
                          placeholder={translate('labels.vatBase')}
                          sx={verificationFormItem}
                          required
                          disabled={editSection !== 'invoice'}
                        />

                        <DecimalField
                          label={translate('labels.vatAmount')}
                          name="vatAmount"
                          placeholder={translate('labels.vatAmount')}
                          sx={{
                            ...verificationFormItem,
                            ...((values.vatAmount as unknown as string)
                              .length <= 0 && warningFieldStyle)
                          }}
                          disabled={editSection !== 'invoice'}
                        />
                        <DecimalField
                          label={translate('labels.invoiceAmount')}
                          name="invoiceAmount"
                          placeholder={translate('labels.invoiceAmount')}
                          sx={verificationFormItem}
                          required
                          disabled={editSection !== 'invoice'}
                        />

                        {currencies?.length > 0 && (
                          <FormControl sx={verificationFormItem}>
                            <InputLabel>
                              {translate('labels.currency')}
                            </InputLabel>
                            <Select
                              name="currencyId"
                              value={formValues.currency || ''}
                              label={translate('labels.currency')}
                              onChange={(event: SelectChangeEvent<string>) =>
                                onChangeCurrency(event, values, setFieldValue)
                              }
                              disabled={editSection !== 'invoice'}
                            >
                              {currencies &&
                                currencies.map((currency: CurrencyDropDown) => (
                                  <MenuItem
                                    key={currency.id}
                                    value={currency.id}
                                  >
                                    {currency.isoCode}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )}
                      </Box>
                      <Box sx={verificationFormFieldsWrapper}>
                        {plAccountOptions?.length > 0 && (
                          <Field
                            name="profitAndLossAccountId"
                            as={Autocomplete}
                            options={plAccountOptions}
                            getOptionLabel={renderAccountLabel}
                            disabled={
                              !permissions.PL_ACCOUNTS_DROP_DOWN.update ||
                              editSection !== 'invoice'
                            }
                            value={
                              plAccountOptions.find(
                                (op) =>
                                  op.id === formValues.profitAndLossAccountId
                              ) ||
                              selectedCounterParty?.profitAndLossAccount ||
                              null
                            }
                            sx={verificationFormItem}
                            renderInput={onRenderInputAccountId}
                            onChange={(
                              event: React.ChangeEvent<{}> | null,
                              value: CounterPartyDropDown
                            ) =>
                              onChangeProfitAndLossAccountId(
                                event,
                                value,
                                values,
                                setFieldValue
                              )
                            }
                            isOptionEqualToValue={(option: any, value: any) =>
                              option.id === value.id
                            }
                          />
                        )}
                        {expenseTypeOptions?.length > 0 && (
                          <FormControl sx={verificationFormItem}>
                            <InputLabel>
                              {translate('labels.expenseType')}
                            </InputLabel>
                            <Select
                              name="expenseTypeId"
                              value={
                                formValues.expenseTypeId ||
                                selectedCounterParty?.expenseTypeId ||
                                ''
                              }
                              label={translate('labels.expenseType')}
                              onChange={(event: SelectChangeEvent<number>) =>
                                onChangeExpenseType(
                                  event,
                                  values,
                                  setFieldValue
                                )
                              }
                              disabled={
                                !permissions.EXPENSE_TYPES.update ||
                                editSection !== 'invoice'
                              }
                            >
                              {expenseTypeOptions &&
                                expenseTypeOptions.map(
                                  (expenseType: ExpenseType) => (
                                    <MenuItem
                                      key={expenseType.id}
                                      value={expenseType.id as number}
                                    >
                                      {expenseType.expenseType}
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                          </FormControl>
                        )}
                      </Box>
                      <Box sx={verificationFormFieldsWrapper}>
                        {documentTypeOptions?.length > 0 && (
                          <FormControl sx={verificationFormItem}>
                            <InputLabel>
                              {translate('labels.documentType')}
                            </InputLabel>
                            <Select
                              name="documentType"
                              value={
                                formValues.documentType ||
                                documentTypeOptions[0].id
                              }
                              label={translate('labels.documentType')}
                              onChange={(event) =>
                                onChangeDocumentType(
                                  event,
                                  values,
                                  setFieldValue
                                )
                              }
                              disabled={editSection !== 'invoice'}
                            >
                              {documentTypeOptions &&
                                documentTypeOptions.map(
                                  (type: DocumentType) => (
                                    <MenuItem key={type.id} value={type.id}>
                                      {type.type}
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                          </FormControl>
                        )}
                        <Field
                          as={TextField}
                          label={translate('labels.accountingDescription')}
                          name="accountingDescription"
                          placeholder={translate(
                            'labels.accountingDescription'
                          )}
                          sx={verificationFormItem}
                          value={values.accountingDescription || ''}
                          disabled={editSection !== 'invoice'}
                        />
                      </Box>
                      {currentStage !== InvoiceStages.FINALIZED && (
                        <ActionButtons
                          editSection={editSection}
                          values={values}
                          section="invoice"
                          onClickEditSection={onClickEditSection}
                          onClickSave={() =>
                            handleSave(values, InvoiceSection.INVOICE_DATA) as
                              | ((values: Invoice, section: string) => void)
                              | undefined
                          }
                          handleSubmit={handleSubmit}
                          resetForm={resetForm}
                          setFieldValue={setFieldValue}
                          isEditButtonDisabled={editSection !== ''}
                          isVerifyButtonDisabled={
                            indexOfFirstFalse < SectionIndex.INVOICE_DATA
                          }
                        />
                      )}
                    </Box>
                  </Form>
                )}
              </Formik>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandedSection.includes('areProductsVerified')}
            onChange={handleChange('areProductsVerified')}
            sx={
              editSection === 'products'
                ? activeAccordionBackgound
                : accordionBackgound
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={accordionHeader}
            >
              {translate('labels.products')}
              {formValues.areProductsVerified && (
                <Tooltip title={currentInvoice.stage}>
                  <CheckCircleIcon sx={CheckCircleIconStyle} />
                </Tooltip>
              )}
            </AccordionSummary>

            <AccordionDetails>
              <Formik
                key={currentInvoice.id}
                onSubmit={() =>
                  onClickVerifySection(
                    InvoiceSection.PRODUCTS,
                    'areProductsVerified'
                  )
                }
                initialValues={formValues}
                initialTouched={INITIAL_TOUCHED_FIELDS}
                validateOnMount
                enableReinitialize
              >
                {({ handleSubmit, values, setFieldValue, resetForm }) => (
                  <Form>
                    <FieldArray
                      name="products"
                      render={(arrayHelpers) =>
                        onRenderProducts(
                          arrayHelpers,
                          values,
                          setFieldValue,
                          handleSubmit,
                          resetForm
                        )
                      }
                    />
                  </Form>
                )}
              </Formik>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandedSection.includes('isPaidIsBookedVerified')}
            onChange={handleChange('isPaidIsBookedVerified')}
            sx={
              editSection === 'status'
                ? activeAccordionBackgound
                : accordionBackgound
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={accordionHeader}
            >
              {translate('labels.status')}
              {formValues.isPaidIsBookedVerified && (
                <Tooltip title={currentInvoice.stage}>
                  <CheckCircleIcon sx={CheckCircleIconStyle} />
                </Tooltip>
              )}
            </AccordionSummary>
            <AccordionDetails sx={verificationFormFields}>
              <Formik
                key={currentInvoice.id}
                onSubmit={() =>
                  onClickVerifySection(
                    InvoiceSection.IS_PAID_IS_BOOKED,
                    'isPaidIsBookedVerified'
                  )
                }
                initialValues={formValues}
                initialTouched={INITIAL_TOUCHED_FIELDS}
                validateOnMount
                enableReinitialize
              >
                {({ handleSubmit, values, resetForm, setFieldValue }) => (
                  <Box>
                    <Form>
                      <Box sx={verificationFormFieldsWrapper}>
                        <FormControlLabel
                          control={
                            <Switch checked={formValues.isBooked || false} />
                          }
                          disabled={editSection !== 'status'}
                          onChange={(event) =>
                            onChangeStatus(
                              'isBooked',
                              event as React.ChangeEvent<HTMLInputElement>,
                              values
                            )
                          }
                          label={translate('labels.bookingStatus')}
                          sx={verificationFormItem}
                        />
                        <FormControlLabel
                          control={
                            <Switch checked={formValues.isPaid || false} />
                          }
                          disabled={editSection !== 'status'}
                          onChange={(event) =>
                            onChangeStatus(
                              'isPaid',
                              event as React.ChangeEvent<HTMLInputElement>,
                              values
                            )
                          }
                          label={translate('labels.PMTStatus')}
                          sx={verificationFormItem}
                        />
                      </Box>
                    </Form>
                    {currentStage !== InvoiceStages.FINALIZED && (
                      <ActionButtons
                        editSection={editSection}
                        values={values}
                        section="status"
                        onClickEditSection={onClickEditSection}
                        onClickSave={() =>
                          handleSave(
                            values,
                            InvoiceSection.IS_PAID_IS_BOOKED
                          ) as
                            | ((values: Invoice, section: string) => void)
                            | undefined
                        }
                        handleSubmit={handleSubmit}
                        resetForm={resetForm}
                        setFieldValue={setFieldValue}
                        isEditButtonDisabled={editSection !== ''}
                        isVerifyButtonDisabled={
                          indexOfFirstFalse < SectionIndex.STATUS ||
                          !permissions.BOOKING_STATUSES.update
                        }
                      />
                    )}
                  </Box>
                )}
              </Formik>
            </AccordionDetails>
          </Accordion>
          <Box sx={verificationsButtonsWrapper}>
            <Button variant="contained" color="error" onClick={navigateHome}>
              {translate('buttons.close')}
            </Button>
          </Box>
        </>
      </Box>
    );
  }
);
