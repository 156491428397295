import { useCallback, useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Paper } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { CounterParty, CounterPartyCreateUpdate } from 'openapi';

import { useCounterPartyController } from 'api/controllers/CounterPartyController';

import { COLUMNS_DEFAULT_OPTIONS } from 'utils/constants/constants';

import { useDataGridHelpers } from 'hooks/useDataGridHelpers';
import { useModal } from 'hooks/useModal';

import { useTranslations } from 'context/TranslationContext';

import { noPaginationDataGridContainerStyle } from 'styles/components/DataGridStyle';

import { Modal } from 'components/shared/Modal/Modal';
import { GridToolbar } from 'components/shared/GridToolbar/GridToolbar';
import { CreateUpdateCounterParty } from './CreateUpdateCounterParty';

export const CounterParties = () => {
  const location = useLocation();
  const { id } = useParams();
  const { translate } = useTranslations();

  const { isOpen, closeModal, openModal } = useModal();

  const {
    getCounterParties,
    createNewCounterParty,
    editCounterParty,
    deleteCounterParties
  } = useCounterPartyController();
  const {
    getCounterPartyTypeValue,
    getPercentageValue,
    getAccountInfoValue,
    getYesNoValue,
    getExpenseTypeValue,
    getCompanyRegistrationNumberValue
  } = useDataGridHelpers();

  // States and variables
  const [counterParties, setCounterParties] = useState<CounterParty[]>([]);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  // Service calls
  const fetchCounterParties = useCallback(async () => {
    const data = await getCounterParties(Number(id));
    setCounterParties(data);
  }, [getCounterParties, id]);

  useEffect(() => {
    fetchCounterParties();
  }, [fetchCounterParties]);

  const [counterPartyForEdit, setCounterPartyForEdit] = useState<
    CounterParty | undefined
  >();

  const handleEditClick = (counterParty: CounterParty) => {
    const { bic, ...reducedCounterParty } = counterParty;

    setCounterPartyForEdit(reducedCounterParty);
    openModal();
  };

  const { state } = location;

  const counterPartyId = state ? state?.counterPartyId : -1;

  useEffect(() => {
    const forEdit = counterParties.find(
      (counterParty) => counterParty.id === counterPartyId
    );

    if (forEdit) {
      // Simulating click on edit button from the Datagrid
      // when counterPartyId is present in the location state
      // (when user is redirected from the InvoiceVerification page)
      handleEditClick(forEdit);
    }
  }, [counterPartyId, counterParties.length]);

  // Handlers
  const handleDeleteClick = async () => {
    await deleteCounterParties(Number(id), selectedRows as number[]);
    fetchCounterParties();
  };

  const handleCloseModal = () => {
    setCounterPartyForEdit(undefined);
    closeModal();
  };

  const handleSubmitForm = async (values: CounterPartyCreateUpdate) => {
    const body = {
      ...values,
      iban: values.iban ? values.iban : undefined,
      companyRegistrationNumber: values.withoutCompanyRegistrationNumber
        ? ''
        : values.companyRegistrationNumber
    };

    try {
      counterPartyForEdit
        ? await editCounterParty(
            Number(id),
            Number(counterPartyForEdit.id),
            body
          )
        : await createNewCounterParty(Number(id), body);

      handleCloseModal();
      fetchCounterParties();
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const columns: GridColDef[] = [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'type',
      headerName: translate('labels.counterPartyType'),
      valueGetter: getCounterPartyTypeValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'companyName',
      headerName: translate('labels.counterPartyName')
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'companyRegistrationNumber',
      headerName: translate('labels.companyRegistrationNumber'),
      minWidth: 160,
      valueGetter: getCompanyRegistrationNumberValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'vatNumber',
      headerName: translate('labels.vatNumber')
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'iban',
      headerName: translate('labels.iban')
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'bic',
      headerName: translate('labels.bic')
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'balanceSheetAccount',
      headerName: translate('labels.balanceSheetAccount'),
      valueGetter: getAccountInfoValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'profitAndLossAccount',
      headerName: translate('labels.pnlAccount'),
      valueGetter: getAccountInfoValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'expenseType',
      headerName: translate('labels.expenseType'),
      valueGetter: getExpenseTypeValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'reverseCharge',
      headerName: translate('labels.reverseCharge'),
      valueGetter: getYesNoValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'vatPercentage',
      headerName: translate('labels.vatPercentage'),
      valueGetter: getPercentageValue
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'vatCredit',
      headerName: translate('labels.vatCredit'),
      valueGetter: getYesNoValue
    },
    {
      field: 'actions',
      headerName: translate('labels.actions'),
      type: 'actions',
      getActions: ({ row }: { row: CounterParty }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={translate('labels.edit')}
            onClick={() => handleEditClick(row)}
            color="inherit"
          />
        ];
      }
    }
  ];

  // Render values
  const modalTitle = useMemo(
    () =>
      counterPartyForEdit
        ? `${translate('labels.editCounterParty')}: ${
            counterPartyForEdit.companyName
          }`
        : translate('labels.addCounterParty'),
    [translate, counterPartyForEdit]
  );

  return (
    <Paper elevation={8} sx={noPaginationDataGridContainerStyle}>
      <Modal headerTitle={modalTitle} isOpen={isOpen} hide={handleCloseModal}>
        <CreateUpdateCounterParty
          initialValues={counterPartyForEdit}
          handleSubmit={handleSubmitForm}
        />
      </Modal>
      <DataGrid
        rows={counterParties}
        columns={columns}
        getRowId={(row) => row.id || 0}
        checkboxSelection
        onRowSelectionModelChange={setSelectedRows}
        slots={{
          toolbar: GridToolbar
        }}
        slotProps={{
          toolbar: {
            handleAddClick: openModal,
            handleDeleteClick,
            selectedRows
          }
        }}
        localeText={{
          noRowsLabel: translate('labels.noData')
        }}
      />
    </Paper>
  );
};
