import { useCallback } from 'react';

import { Invoice, InvoiceService, ExportService, InvoicePatch } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { showSuccessToastMessage } from 'components/shared/Toast/Toast';

import interceptRequest from 'utils/helpers/interceptRequest';
import { InvoiceSectionKey } from 'utils/interfaces/InvoiceProps';
import { mapInvoiceForPatch } from 'utils/mappers/invoice';

export const useInvoiceController = () => {
  const {
    uploadInvoice,
    getInvoices,
    getInvoicePdfById,
    patchInvoice,
    getInvoicesByCompanyId,
    deleteInvoicesByIds,
    verifyInvoiceSectionById
  } = InvoiceService;

  const { exportInvoicesForPayment, exportInvoices } = ExportService;

  const { translate } = useTranslations();

  const addInvoice = (id: number, formData: { files: Array<Blob> }) =>
    interceptRequest(
      uploadInvoice,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyUploaded'))
      },
      id,
      formData
    );

  const getAllInvoices = useCallback(
    (stage?: string) => interceptRequest(getInvoices, {}, stage),
    [getInvoices]
  );

  const getInvoiceFile = useCallback(
    (invoiceId: number) => interceptRequest(getInvoicePdfById, {}, invoiceId),
    [getInvoicePdfById]
  );

  const updateInvoiceFile = useCallback(
    (id: number, requestBody: Invoice) => {
      const body = mapInvoiceForPatch(requestBody);

      return interceptRequest(
        patchInvoice,
        {
          onSuccess: () =>
            showSuccessToastMessage(translate('messages.successfullyUpdated'))
        },
        id,
        body
      );
    },
    [patchInvoice, translate]
  );

  const getAllInvoicesByCompanyId = (id: number) =>
    interceptRequest(getInvoicesByCompanyId, {}, id);

  const getAllInvoicesByCompanyIdAndStage = useCallback(
    (companyId: number, stage: string) =>
      interceptRequest(getInvoicesByCompanyId, {}, companyId, stage),
    [getInvoicesByCompanyId]
  );

  const deleteInvoices = (requestBody: Array<number>) =>
    interceptRequest(
      deleteInvoicesByIds,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyDeleted'))
      },
      requestBody
    );

  const exportSelectedInvoicesForPayment = (
    id: number,
    iban: string,
    invoiceIds: Array<number>
  ) => interceptRequest(exportInvoicesForPayment, {}, id, iban, invoiceIds);

  const exportInvoicesInExcel = () => interceptRequest(exportInvoices, {});

  const updateInvoice = useCallback(
    (
      id: number,
      invoiceSection: InvoiceSectionKey,
      requestBody: InvoicePatch
    ) => interceptRequest(patchInvoice, {}, id, invoiceSection, requestBody),
    [patchInvoice]
  );

  const verifyInvoiceSection = useCallback(
    (id: number, invoiceSection: string) =>
      interceptRequest(verifyInvoiceSectionById, {}, id, invoiceSection),
    [verifyInvoiceSectionById]
  );

  return {
    addInvoice,
    getAllInvoices,
    getInvoiceFile,
    updateInvoiceFile,
    getAllInvoicesByCompanyId,
    deleteInvoices,
    getAllInvoicesByCompanyIdAndStage,
    exportSelectedInvoicesForPayment,
    exportInvoicesInExcel,
    updateInvoice,
    verifyInvoiceSection
  };
};
