import {
  BankStatementService,
  ExportService,
  FinancialTransactionPatch
} from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { showSuccessToastMessage } from 'components/shared/Toast/Toast';
import interceptRequest from 'utils/helpers/interceptRequest';

export const useBankStatementController = () => {
  const {
    uploadBankStatements,
    getBankStatementsByCompanyId,
    previewBankStatement,
    getBankStatementPdfById,
    patchFinancialTransaction,
    deleteBankStatementsByIds,
    getAllBankStatements
  } = BankStatementService;

  const { exportBankStatement } = ExportService;

  const { translate } = useTranslations();

  const addBankStatements = (id: number, formData: { files: Array<Blob> }) =>
    interceptRequest(
      uploadBankStatements,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyUploaded'))
      },
      id,
      formData
    );

  const getBankStatements = () => interceptRequest(getAllBankStatements, {});

  const getCompanyBankStatements = (id: number) =>
    interceptRequest(getBankStatementsByCompanyId, {}, id);

  const previewBankStatements = (bankStatementId: number) =>
    interceptRequest(previewBankStatement, {}, bankStatementId);

  const exportBankStatements = (companyId: number, bankStatementId: number) =>
    interceptRequest(exportBankStatement, {}, companyId, bankStatementId);

  const getBankStatementFile = (bankStatementId: number) =>
    interceptRequest(getBankStatementPdfById, {}, bankStatementId);

  const updateFinancialTransaction = (
    id: number,
    transactionId: number,
    requestBody: FinancialTransactionPatch
  ) =>
    interceptRequest(
      patchFinancialTransaction,
      {},
      id,
      transactionId,
      requestBody
    );

  const deleteBankStatements = (bankStatementsId: number[]) =>
    interceptRequest(
      deleteBankStatementsByIds,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyDeleted'))
      },
      bankStatementsId
    );

  return {
    addBankStatements,
    getCompanyBankStatements,
    previewBankStatements,
    exportBankStatements,
    getBankStatementFile,
    updateFinancialTransaction,
    deleteBankStatements,
    getAllBankStatements,
    getBankStatements
  };
};
