import { useEffect } from 'react';
import { useNotification } from 'context/NotificationContext';
import { Messaging, onMessage } from 'firebase/messaging';

interface MessageHandlerProps {
  messaging: Messaging;
}

export const MessageHandler: React.FC<MessageHandlerProps> = ({
  messaging
}) => {
  const { setNotification, notification } = useNotification();

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload: any) => {
      if (payload && payload.data) {
        setNotification({
          title: payload.data.title,
          body: payload.data.body
        });
      } else {
        console.error('Invalid payload:', payload);
      }
    });

    navigator.serviceWorker.addEventListener('message', (event) => {
      const messageFromSW = event.data;
      setNotification({
        title: messageFromSW.title,
        body: messageFromSW.body
      });
    });

    return () => unsubscribe();
  }, [messaging, notification]);

  return null;
};
