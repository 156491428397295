import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Prompt } from 'openapi';

import { usePromptController } from 'api/controllers/PromptController';

import { Layout } from 'components/Layout/Layout';
import { CreateUpdatePromptForm } from 'components/forms/CreateUpdatePromptForm';

import { useTranslations } from 'context/TranslationContext';

import { ROUTE_PROMPT_SETTINGS } from 'utils/routes';

import { mediumVerificationFieldWidth } from 'styles/pages/InvoiceVerificationStyle';

export const PromptSettings: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { translate } = useTranslations();
  const { getCurrentPrompt, updateCurrentPrompt, deletePrompt } =
    usePromptController();

  const [currentPrompt, setCurrentPrompt] = useState<Prompt>({});

  const onSubmit = async (values: Prompt) => {
    await updateCurrentPrompt(Number(id), values);
    navigate(ROUTE_PROMPT_SETTINGS);
  };

  const onDelete = async () => {
    await deletePrompt(Number(id));
    navigate(ROUTE_PROMPT_SETTINGS);
  };

  useEffect(() => {
    const fetchPrompt = async () => {
      const currentPromptValue = await getCurrentPrompt(Number(id));
      setCurrentPrompt(currentPromptValue);
    };

    if (id) {
      fetchPrompt();
    }
  }, [getCurrentPrompt, id]);

  const initialValues = {
    promptName: currentPrompt.promptName || '',
    userPrompt: currentPrompt.userPrompt || ''
  };

  return (
    <Layout pageTitle={translate('pages.promptSettings')}>
      {currentPrompt.userPrompt && (
        <Box sx={mediumVerificationFieldWidth}>
          <CreateUpdatePromptForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            onDelete={onDelete}
          />
        </Box>
      )}
    </Layout>
  );
};
