export const formSmallContainer = {
  display: 'flex',
  gap: '16px'
};

export const halfWidth = {
  width: '50%'
};

export const submitButton = {
  width: '45%',
  padding: '18px 0'
};

export const fullFieldWidth = {
  width: '100%'
};

export const arrayFieldWrapper = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 3
};

export const arrayFieldFormWrapper = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '100%'
};
