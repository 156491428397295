import { VAT_NUMBER_PREFIX_BULGARIA } from 'utils/constants/invoices';

export const isVatNumberEqualCRN = (
  vatNumber: string,
  companyRegNumber?: string
) => {
  if (
    vatNumber &&
    companyRegNumber &&
    vatNumber.startsWith(VAT_NUMBER_PREFIX_BULGARIA)
  ) {
    return !(vatNumber === `BG${companyRegNumber}`);
  }

  return false;
};

export const conditions: Record<
  string,
  (value: string, values: any) => boolean
> = {
  companyRegistrationNumber: (
    value: string | undefined,
    values: any
  ): boolean =>
    (value && value.length <= 0) ||
    isVatNumberEqualCRN(values.vatNumber, value),
  dueDate: (value: string) => value.length <= 0,
  vatNumber: (value: string, values: any): boolean =>
    value.length <= 0 ||
    isVatNumberEqualCRN(value, values.companyRegistrationNumber)
};

export const decimalFormater = (value: number | null): number | string => {
  return value !== null ? Number(value).toFixed(2) : '0.00';
};
