import { InvoiceStages } from 'openapi/models/InvoiceStages';

type SeverityColorsType = {
  [key in InvoiceStages]?: {
    color: string;
    backgroundColor: string;
  };
};

export const INITIAL_TOUCHED_RECEIVER_FIELDS = {
  companyRegistrationNumber: true
};

export const INITIAL_TOUCHED_SUPPLIER_FIELDS = {
  counterPartyName: true,
  counterPartyRegistrationNumber: true,
  counterPartyVatNumber: true
};

export const INITIAL_TOUCHED_FIELDS = {
  supplier: true,
  companyRegistrationNumber: true,
  invoiceNumber: true,
  invoiceDate: true,
  dueDate: true,
  vatBase: true,
  invoiceAmount: true
};

export const INITIAL_PRODUCT_ITEM = {
  description: '',
  unitPrice: '',
  quantity: '',
  total: ''
};

export const VAT_NUMBER_PREFIX_BULGARIA = 'BG';

export const INVOICE_VERIFICATION_KEYS_IN_ORDER = [
  'isReceiverVerified',
  'isSupplierVerified',
  'isInvoiceDataVerified',
  'areProductsVerified',
  'isPaidIsBookedVerified'
];
export const SEVERITY_COLORS: SeverityColorsType = {
  [InvoiceStages.DRAFT]: {
    color: 'rgb(255,56,56)',
    backgroundColor: 'rgba(255,56,56,0.1)'
  },
  [InvoiceStages.VALIDATED]: {
    color: 'rgb(86,240,0)',
    backgroundColor: 'rgba(86,240,0,0.1)'
  },
  [InvoiceStages.FINALIZED]: {
    color: 'rgb(45,204,255)',
    backgroundColor: 'rgba(45,204,255,0.1)'
  }
};
