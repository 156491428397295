const lightTheme = {
  palette: {
    background: {
      default: '#FFFFFF'
    },
    primary: {
      main: '#078dee',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#FFFFFF'
    },
    error: {
      main: '#F44336'
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Regular',
    h1: {
      fontSize: 60,
      fontWeight: 600,
      lineHeight: '72px',
      marginBottom: 16
    },
    h2: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '32px',
      marginBottom: 8
    },
    h3: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '32px'
    },
    button: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '24px',
      fontFamily: 'Bold'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px auto',
          fontSize: '14px',
          lineHeight: '24px',
          fontWeight: '500'
        },
        containedPrimary: {
          boxShadow: '0px 2px 1px #00000026'
        },
        containedSecondary: {
          boxShadow: '0px 1px 1px #00000026',
          color: '#FFFFFF',
          background: '#D5D5D5',
          '&:hover': {
            backgroundColor: '#D2D2D2'
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: 16,
          marginBottom: 16
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          margin: 24
        }
      }
    }
  }
};

export default lightTheme;
